import { IonSelect, IonSelectOption } from "@ionic/react"
import { Product } from "../../hooks/useNewOrder"
import { BasicProduct } from "../../hooks/useSalesman"
import { useEffect, useRef, useState } from "react"

interface GruppoMerceologicoSelectI {
    products: Product[] | BasicProduct[],
    onGruppoMerceologicoSelected: (gruppoMerceologico: string) => void;
}

const GruppoMerceologicoSelect: React.FC<GruppoMerceologicoSelectI> = ({ products, onGruppoMerceologicoSelected }) => {

    const [gruppiMerceologici, setGruppiMerceologici] = useState<string[]>([]);
    useEffect(() => {
        setGruppiMerceologici(Array.from(new Set(products.map((product) => product.gruppoMerceologico))));
    }, [products])

    return (
        <IonSelect
            color='medium'
            style={{ width: '100%' }}
            placeholder='GRUPPO MERCEOLOGICO'
            interface="popover"
            mode="ios"
            onIonChange={({ detail }) => onGruppoMerceologicoSelected(detail.value as string)}
        >
            <IonSelectOption color='medium' value=''>TUTTI</IonSelectOption>
            {gruppiMerceologici.map((gruppoMerceologico, index) => (
                <IonSelectOption color='medium' key={index} value={gruppoMerceologico}>{gruppoMerceologico}</IonSelectOption>
            ))}
        </IonSelect>
    )
}

export default GruppoMerceologicoSelect;