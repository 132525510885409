import { IonText, IonIcon, IonGrid, IonRow, IonCol, IonInput, IonItem, IonContent, IonModal } from "@ionic/react";
import * as IonIcons from "ionicons/icons";
import { useState } from "react";
import { useHistory } from "react-router";

import { Customer } from "../../../hooks/useNewOrder";
import CustomersTable from "../../../components/Table/CustomersTable";


export interface CustomerState {
    customer: Customer
}
const SchedeCliente: React.FC = () => {
    const history = useHistory();
    const [globalFilter, setGlobalFilter] = useState("");
    // const [customer, setCustomer] = useState<Customer>()

    /**
     * Function
     */
    const _tableRowClicked = (customer: Customer) => {
        // setCustomer(customer);
        history.push('schede-cliente/detail', { customer })
    }

    return (
        <>
            <IonContent>
                <IonGrid class='full-flex-column'>
                    <IonRow className="">
                        <IonCol>
                            <IonText>
                                <h3>ELENCO CLIENTI</h3>
                            </IonText>
                        </IonCol>
                    </IonRow>


                    <IonRow className="">
                        <IonCol>
                            <IonText color="primary">
                                <h1>SELEZIONA CLIENTE</h1>
                            </IonText>
                        </IonCol>
                    </IonRow>


                    <IonRow>
                        <IonCol>
                            <IonText>
                                <h3>RICERCA CLIENTE</h3>
                            </IonText>
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-margin-bottom">
                        <IonCol sizeMd="8" sizeXs="12">
                            <IonItem>
                                <IonInput
                                    value={globalFilter ?? ''}
                                    onIonInput={({ detail: { value } }) => setGlobalFilter(value as string)}
                                    placeholder="CERCA PER P.IVA, DENOMINAZIONE, INDIRIZZO, TELEFONO, CODICE CLIENTE"
                                />
                                <IonIcon icon={IonIcons.searchOutline} slot="end"></IonIcon>
                            </IonItem>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>
                            <IonText >
                                <h3 className="">TUTTI I CLIENTI</h3>
                            </IonText>
                        </IonCol>
                    </IonRow>

                    <IonRow class="flex-fill">
                        <CustomersTable globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} onRowClicked={_tableRowClicked} ></CustomersTable>
                    </IonRow>
                </IonGrid>
            </IonContent >
        </>
    );
};

export default SchedeCliente;