import { Redirect, Route, Switch, useHistory, useRouteMatch } from "react-router";

import DataConsegna from "./DataConsegna";
import IndirizzoConsegna from "./IndirizzoConsegna";
import OrdineInviato from "./OrdineInviato";
import RiepilogoNote from "./RiepilogoNote";
import SelezionaCliente from "./SelezionaCliente";
import SelezionaProdotti from "./SelezionaProdotti";
import { useSelector } from "react-redux";
import { OrderState } from "../../../redux/order/order";
import { useEffect } from "react";
import { withCondition } from "../../../routes/withCondition";
import { ReduxState } from "../../../redux/store";
import { Customer, Product } from "../../../hooks/useNewOrder";

const BASE_PATH = '/ordini/nuovo-ordine';

const steps = [{
  path: `${BASE_PATH}/seleziona-cliente`,
  component: SelezionaCliente,
  orderCondition: (order: OrderState) => true,
}, {
  path: `${BASE_PATH}/seleziona-prodotti`,
  component: SelezionaProdotti,
  orderCondition: (order: OrderState) => order.customer !== null,
}, {
  path: `${BASE_PATH}/indirizzo-consegna`,
  component: IndirizzoConsegna,
  orderCondition: (order: OrderState) => order.products !== null,
}, {
  path: `${BASE_PATH}/data-consegna`,
  component: DataConsegna,
  orderCondition: (order: OrderState) => order.deliveryAddress !== null,
}, {
  path: `${BASE_PATH}/riepilogo-note`,
  component: RiepilogoNote,
  orderCondition: (order: OrderState) => order.deliveryDate !== null,
}, {
  path: `${BASE_PATH}/ordine-inviato`,
  component: OrdineInviato,
  orderCondition: (order: OrderState) => true,
}];

export const useCheckStepOrRedirect = () => {
  const history = useHistory();
  const checkStepOrRedirect = ({ orderState }: { orderState: OrderState }) => {
    const currentStepIndex = steps.findIndex(step => location.href.includes(step.path));
    if (currentStepIndex === -1) return;
    const currentStep = steps[currentStepIndex];
    const ok = currentStep.orderCondition(orderState);
    if (!ok) {
      const previousStepPath = steps[currentStepIndex - 1].path;
      history.replace(previousStepPath);
      return;
    }
  };
  return checkStepOrRedirect;
}

const NuovoOrdine: React.FC = () => {
  const { path } = useRouteMatch();

  // Flusso con controllo cliente -> prodotti -> indirizzo -> data
  const routes = steps.map((step, i) => {
    return (<Route
      path={`${step.path}`}
      component={step.component}
    />);
  });
  return (
    <Switch>
      {routes}
      <Redirect from={path} to={`${steps[0].path}`} exact />
    </Switch>
  );
};

export default NuovoOrdine;