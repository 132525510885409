import { getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import CustomTable from "../../../components/Table/CustomTable";
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { closeOutline } from "ionicons/icons";

const ModalDetailTable: React.FC<any> = ({
    onDismiss,
    detailColumnDefinition,
    detailData,
    title
}) => {
    const table = useReactTable({
        data: detailData, // Define data source
        columns: detailColumnDefinition, // Define all column props
        // state: {
        //     columnVisibility,
        //     sorting,
        // },
        // meta: {
        //     updateData: async (rowIndex, columnId, value) => {
        //         // Skip page index reset until after next rerender
        //         skipAutoResetPageIndex();
        //         // Update the data state
        //         const newData = customers.map((row, index) => {
        //             if (index === rowIndex) {
        //                 return {
        //                     ...customers[rowIndex]!,
        //                     [columnId]: value,
        //                 };
        //             }
        //             return row;
        //         })
        //         // Get new fav array
        //         const newFav = newData.filter(customer => customer.favorite).map(customer => customer.customerId);
        //         // Call async api to update
        //         const newFavFromApi = await saveFavorites(newFav);
        //         if (newFav.toString() === newFavFromApi.toString()) {
        //             setCustomers(newData);
        //         } else {
        //             setCustomers(mergeCustomerFavorites(customers, newFavFromApi));
        //         }

        //     },
        // },
        // onSortingChange: setSorting,
        // onGlobalFilterChange: setGlobalFilter, // Used to enable global filter
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(), // Used to enable pagination
        getFilteredRowModel: getFilteredRowModel(), // Used to enable filtering
        getSortedRowModel: getSortedRowModel(), // Enable sorting
        //  debugTable: true, // TODO: remove in production
    });
    return (
        <IonPage >
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{title}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton color="black" onClick={onDismiss}>
                            <IonIcon icon={closeOutline}></IonIcon>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent>

                <CustomTable table={table}></CustomTable>
            </IonContent>
        </IonPage>
    )
}

export default ModalDetailTable