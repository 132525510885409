import { createAction, createReducer } from '@reduxjs/toolkit'

// Customer step
export const disableMenu = createAction<boolean>('menu/disable');


export interface MenuState {
    disabled: boolean;
}

const initState: MenuState = {
    disabled: false,
};

const reducer = createReducer(initState, (builder) => {
    builder.addCase(disableMenu, (state, action) => {
        state.disabled = action.payload;
    });
})

export default reducer;