import { useEnv } from "../Context/EnvContext";
import { BACKEND_PATH, BackendApi, useApi } from "./useApi";

export type DraftOrder = {
    id?: string | null;
    customerId: string;
    businessName: string; /* (description client) */
    address: string;
    city: string;
    shipToId?: number; /* (from delivery address) */
    note?: string;
    amount?: number; /* (total rows price .xx) */
    weight?: number; /* (total weight .xxx) */
    deliveryDate?: number; /* (seconds) */
    salesmanFullName?: string;
    salesmanId?: string;
    productRows?: DraftOrderRow[];
    creationDate?: number;
    modificationDate?: number;
    // field for company support
    companyCode?: string
    companyFacility?: string
    companyDivision?: string
}

export type DraftOrderRow = {
    amount: number; /* (total price with irca logic .xx) */
    weight: number; /* (weight .xxx) */
    productId: string; /*  (itemId) */
    fullPrice: number; /* (base price x quantity .xx) */
    unitOfMeasure: string; /* (um) */
    quantity: number;
    discount: number; /* (0/20) */
    warehouse: string;
}

export type useDraftOrdersI = {
    getOrdersDraft: (numberOfDraft?: number) => Promise<BackendApi<DraftOrder[]>>;
    saveOrderDraft: (order: DraftOrder) => Promise<BackendApi<{ id: string }>>;
    sendOrderDraft: (orderId: string) => Promise<BackendApi<DraftOrder>>;
    deleteOrderDraft: (orderId: string) => Promise<BackendApi>;
}


export const useOrdersDraft = (): useDraftOrdersI => {
    const { callApi } = useApi();
    const { getEnv } = useEnv();

    const getOrdersDraft = async (numberOfDraft?: number): Promise<BackendApi<DraftOrder[]>> => {
        // Get base gateway url
        const gatewayBase = getEnv('GATEWAY_BASE_URL');

        const url = new URL(`${gatewayBase}/${BACKEND_PATH}/orders/draft`);
        if (numberOfDraft) {
            url.searchParams.append('limit', numberOfDraft.toString());
        }
        return await callApi({
            url: url.toString(),
            method: 'GET',
        });
    }

    const saveOrderDraft = async (order: DraftOrder): Promise<BackendApi<{ id: string }>> => {
        if (!order) {
            throw new Error('Missing order!');
        }
        // Get base gateway url
        const gatewayBase = getEnv('GATEWAY_BASE_URL');
        const { id } = order;
        let url = new URL(`${gatewayBase}/${BACKEND_PATH}/orders${id ? '/' + id : ''}`);
        // delete the id prop for the api call to the backend
        delete order?.id;
        return await callApi({
            url: url.toString(),
            method: id ? 'PUT' : 'POST',
            body: JSON.stringify(order)
        });
    }

    const sendOrderDraft = async (orderId: string): Promise<BackendApi<DraftOrder>> => {
        if (!orderId) {
            throw new Error('Missing orderId!');
        }
        // Get base gateway url
        const gatewayBase = getEnv('GATEWAY_BASE_URL');

        const url = new URL(`${gatewayBase}/${BACKEND_PATH}/orders/${orderId}/send`);
        return await callApi({
            url: url.toString(),
            method: 'GET',
        });
    }

    const deleteOrderDraft = async (orderId: string): Promise<BackendApi> => {
        if (!orderId) {
            throw new Error('Missing orderId!');
        }
        // Get base gateway url
        const gatewayBase = getEnv('GATEWAY_BASE_URL');

        const url = new URL(`${gatewayBase}/${BACKEND_PATH}/orders/${orderId}`);

        return await callApi({
            url: url.toString(),
            method: 'DELETE',
        });
    }


    return { getOrdersDraft, saveOrderDraft, sendOrderDraft, deleteOrderDraft }

}