import { IonCol, IonContent, IonGrid, IonIcon, IonInput, IonItem, IonRow, IonText } from "@ionic/react";
import CustomersTable from "../../../components/Table/CustomersTable";
import { useHistory } from "react-router";
import { useState } from "react";
import { Customer } from "../../../hooks/useNewOrder";
import { searchOutline } from "ionicons/icons";

const ListiniPersonalizzati: React.FC = () => {
  const history = useHistory();
  const [globalFilter, setGlobalFilter] = useState("");
  // const [customer, setCustomer] = useState<Customer>()

  /**
   * Function
   */
  const _tableRowClicked = (customer: Customer) => {
    // setCustomer(customer);
    history.push('listini-personalizzati/detail', { customer })
  }

  return (
    <>
      <IonContent>
        <IonGrid class='full-flex-column'>
          <IonRow>
            <IonCol>
              <IonText>
                <h3>LISTINI PERSONALIZZATI</h3>
              </IonText>
            </IonCol>
          </IonRow>


          <IonRow >
            <IonCol>
              <IonText color="primary">
                <h1>SELEZIONA CLIENTE</h1>
              </IonText>
            </IonCol>
          </IonRow>


          <IonRow>
            <IonCol>
              <IonText>
                <h3>RICERCA CLIENTE</h3>
              </IonText>
            </IonCol>
          </IonRow>

          <IonRow className="ion-margin-bottom">
            <IonCol sizeMd="8" sizeXs="12">
              <IonItem>
                <IonInput
                  value={globalFilter ?? ''}
                  onIonInput={({ detail: { value } }) => setGlobalFilter(value as string)}
                  placeholder="CERCA PER P.IVA, DENOMINAZIONE, INDIRIZZO, TELEFONO, CODICE CLIENTE"
                />
                <IonIcon icon={searchOutline} slot="end"></IonIcon>
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonText >
                <h3 className="">TUTTI I CLIENTI</h3>
              </IonText>
            </IonCol>
          </IonRow>

          <IonRow class="flex-fill">
            <CustomersTable globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} onRowClicked={_tableRowClicked} ></CustomersTable>
          </IonRow>
        </IonGrid>
      </IonContent >
    </>
  );
};

export default ListiniPersonalizzati;