import { IonRow, IonCol, IonContent, IonGrid, IonText, IonSearchbar, IonList, IonSelect, IonSelectOption, IonItem, IonFooter, IonToolbar, IonButton, IonLabel, IonButtons, IonIcon, IonInput, IonPopover } from '@ionic/react';
import React, { useEffect, useId, useMemo, useRef, useState } from 'react';

import NuovoOrdineTabs from "./NuovoOrdineTabs";
import { useDispatch } from 'react-redux';
import { OrderState, addProducts, removeProducts } from '../../../redux/order/order';
import { Customer, Product, useNewOrder } from '../../../hooks/useNewOrder';
import { useSelector } from "react-redux";
import CustomTable, { useSkipper } from '../../../components/Table/CustomTable';
import { ColumnDef, ColumnSort, RowData, VisibilityState, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { ellipse, searchOutline } from 'ionicons/icons';
import { calculateFinalPrice, calculateTotalRowsPrice, convertBaseCurrency, convertNumberToLocale, mergeProducts } from '../../../utils/newOrderHelpers';
import { useHistory } from 'react-router';
import NavigationFooter from '../../../components/Navigation-Footer/NavigationFooter';
import SaveDraft from './SaveDraft';
import { useMobileMediaQuery } from '../../../hooks/useAppMediaQuery';
import InputTable from '../../../components/InputTable/InputTable';
import GruppoMerceologicoSelect from '../../../components/GruppoMerceologicoSelect/GruppoMerceologicoSelect';
import { DraftOrderState } from '../../../redux/draftOrder/draftOrder';
import formatNumber from '../../../utils/helpers';
import { ReduxState } from '../../../redux/store';
import { useCheckStepOrRedirect } from '.';

declare module "@tanstack/react-table" {
    interface TableMeta<TData extends RowData> {
        updateData: (
            rowIndex: number,
            columnId: string,
            value: unknown
        ) => Promise<void>;
    }
}

const SelezionaProdotti: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { getCustomerProducts, convertDraftProducts } = useNewOrder();
    const initData = useRef(false);
    const [allProducts, setAllProducts] = useState<Product[]>([]);
    const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
    const [gruppoMerceologicoSelected, setGruppoMerceologicoSelected] = useState<string>();
    const [totalRow, setTotalRow] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const { draftOrder }: DraftOrderState = useSelector((state: ReduxState) => state.draftOrder);
    const orderState = useSelector((state: ReduxState) => state.order);
    const { customer, products } = orderState;
    const [globalFilter, setGlobalFilter] = useState("");
    const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();
    const isMobile = useMobileMediaQuery();
    const [sorting, setSorting] = useState<ColumnSort[]>([{ id: 'orderHistory', desc: true }]);

    useCheckStepOrRedirect()({ orderState });

    // Define column visibility
    const [columnVisibility] = useState<VisibilityState>({
        statusId: false,
    })

    // Define columns for the table
    const columns = useMemo<ColumnDef<Product, any>[]>(
        () => [
            {
                accessorKey: 'articoloDes',
                header: 'Denominazione',
                meta: {
                    mobileHeader: true,
                    mobileHeaderPosition: 1
                }
            },
            {
                accessorKey: 'supplierDes',
                header: 'Fornitore',
            },
            {
                id: 'gruppoMerceologicoId',
                accessorKey: 'gruppoMerceologico',
                header: 'Gruppo Merceologico',
                enableGlobalFilter: false,
            },
            {
                accessorKey: 'listino',
                header: 'Listino',
                enableSorting: false,
                enableGlobalFilter: false,
            },
            {
                id: 'availabilityId',
                header: 'Disp.',
                accessorKey: 'disponibilita',
                cell: ({ getValue, fromMobileHeader }) => {
                    // Get value disponibilità
                    const disponibilita = getValue();
                    const id = useId();
                    return (
                        <IonRow>
                            <IonIcon id={id} size='large' icon={ellipse} color={Boolean(disponibilita) ? 'success' : 'danger'} ></IonIcon >
                            {fromMobileHeader ? null : (
                                <IonPopover trigger={id} size='auto' translucent alignment='center'>
                                    <IonContent class='ion-padding'>
                                        <IonText>Unità: {disponibilita}</IonText>
                                    </IonContent>
                                </IonPopover>
                            )}
                        </IonRow>
                    )
                },
                enableSorting: false,
                enableGlobalFilter: false,
                meta: {
                    mobileHeader: true,
                    mobileHeaderPosition: 0
                }
            },
            {
                accessorFn: (row) => {
                    const { basePrice, contractPrice, assortmentPrice, baseCurrency } = row;
                    // Apply logic from irca to show the correct price
                    return `${convertBaseCurrency(baseCurrency)} ${convertNumberToLocale(calculateFinalPrice({ basePrice, contractPrice, assortmentPrice }))}`;
                },
                header: 'Prezzo',
                enableGlobalFilter: false,
            },
            {
                header: "Q.Tà",
                accessorKey: "quantity",
                cell: ({ row, column: { id }, getValue, table }) => {
                    const { qtaMax, status, qtaMinima, decimals } = row.original
                    const initialValue = getValue() ?? 0;

                    function _onChange(value: any) {
                        if (value !== initialValue) {
                            table.options.meta?.updateData(
                                row.index,
                                id,
                                value
                            );
                        }
                    }

                    return (
                        <InputTable disabled={status === '50'} min={qtaMinima} max={qtaMax > 0 ? qtaMax : undefined} value={initialValue} onChange={_onChange} decimals={decimals} />
                    );
                },
                meta: {
                    minWidth: "120px"
                },
                sortingFn: (rowA, rowB) => {
                    return Number(rowA.original.quantity ?? 0) - Number(rowB.original.quantity ?? 0)
                },
            },
            {
                header: "Sconto base",
                accessorKey: "sconto",
                cell: ({ row, column: { id }, getValue, table }) => {
                    const discountValue = getValue();
                    const { status, contractPrice } = row.original

                    function _onChange(value: any) {
                        if (value !== discountValue) {
                            table.options.meta?.updateData(
                                row.index,
                                id,
                                value
                            );
                        }
                    }

                    return (
                        <InputTable disabled={status === '50' || contractPrice > 0} min={0} max={20} value={discountValue} onChange={_onChange} />
                    );
                },
                enableSorting: false,
            },
            {
                accessorKey: 'um',
                header: 'Um',
                enableSorting: false,
            },
            {
                accessorKey: 'pzConf',
                header: 'Pz conf',
                enableSorting: false,
            },
            {
                header: 'Storico',
                accessorKey: 'orderHistory',
                cell: ({ row }) => (
                    <>{row.original.orderHistory} ( {formatNumber(row.original.orderedQuantity, 0)})</>
                ),
                sortingFn: (rowA, rowB) => {
                    return Number(rowA.original.orderedQuantity ?? 0) - Number(rowB.original.orderedQuantity ?? 0)
                },
            }, {
                id: 'statusId',
                accessorKey: 'status',
                enableHiding: true
            },
        ],
        []
    );

    const table = useReactTable({
        data: allProducts, // Define data source
        columns, // Define all column props
        state: {
            columnVisibility,
            globalFilter,
            sorting
        },
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(), // Used to enable pagination
        getFilteredRowModel: getFilteredRowModel(), // Used to enable filtering
        getSortedRowModel: getSortedRowModel(), // Enable sorting
        onGlobalFilterChange: setGlobalFilter, // Used to enable global filter
        onSortingChange: setSorting,
        meta: {
            updateData: async (rowIndex, columnId, value) => {
                // check if columnId is sorted
                const isSorted = sorting.find(column => column.id === columnId);
                if (!isSorted) {
                    // Skip page index reset until after next rerender
                    skipAutoResetPageIndex();
                }
                // Update the data state
                setAllProducts((old) =>
                    old.map((row, index) => {
                        if (index === rowIndex) {
                            return {
                                ...old[rowIndex]!,
                                [columnId]: value,
                            };
                        }
                        return row;
                    })
                );
            },
        },
        autoResetPageIndex
        ////  debugTable: true, // TODO: remove in production
    });

    /**
       * Use Effect 
       */
    useEffect(() => {
        if (initData.current) {
            return;
        }
        initData.current = true;
        (async () => {
            if (!customer) {
                return;
            }
            const allProducts = await getCustomerProducts(customer.customerId);
            let stateProducts: Product[] = [];
            // convert draft product
            if (draftOrder?.productRows) {
                stateProducts = convertDraftProducts({
                    baseProducts: allProducts,
                    productRows: draftOrder?.productRows
                });
                setAllProducts(mergeProducts({
                    allProducts,
                    stateProducts,
                })
                );
            } else if (products) {
                setAllProducts(mergeProducts({
                    allProducts,
                    stateProducts: products,
                })
                );
            } else {
                setAllProducts(allProducts);
            }

        })()
    }, []);

    useEffect(() => {
        if (!allProducts || allProducts.length < 1) {
            return;
        }
        // Calculate che current selected row
        setSelectedProducts(getSelectedRow(allProducts));
    }, [allProducts])

    // update total with selected products
    useEffect(() => {
        setTotalRow(selectedProducts.length);
        setTotalPrice(calculateTotalRowsPrice(selectedProducts));
        // dispatch
        if (selectedProducts.length > 0) {
            dispatch(addProducts(selectedProducts));
        } else {
            dispatch(removeProducts());
        }
    }, [selectedProducts])

    // filter the table by gruppoMerceologicoSelected
    useEffect(() => {
        const column = table.getColumn('gruppoMerceologicoId');
        column?.setFilterValue(gruppoMerceologicoSelected);
    }, [gruppoMerceologicoSelected])


    const getSelectedRow = (allRow: Product[]) => {
        if (!allRow) return []
        return allRow.filter((row) => row.quantity && row.quantity > 0)
    }

    const onClickIndietro = () => {
        // dispatch(removeProducts());
        history.replace('seleziona-cliente');
    };

    const onClickContinua = () => {
        setTimeout(() => {
            // Dispatch event addProducts
            history.replace('indirizzo-consegna');
        }, 0);
    };

    return (
        <>
            <IonContent>
                <IonGrid class='full-flex-column'>
                    {isMobile && (
                        <IonRow class='ion-justify-content-end'>
                            <SaveDraft />
                        </IonRow>
                    )}
                    <IonRow className="ion-align-items-center">
                        <IonCol>
                            <h4><strong>NUOVO ORDINE</strong></h4>
                            <IonText color="primary">
                                <h1>SELEZIONA PRODOTTI</h1>
                            </IonText>
                        </IonCol>
                        {!isMobile && (<SaveDraft />)}
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <NuovoOrdineTabs />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <h3>
                                <IonText>
                                    {customer?.description}&nbsp;
                                </IonText>
                                <IonText color='primary'>
                                    ({customer?.customerId})
                                </IonText>
                            </h3>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol size="12" sizeMd='8'>
                            <IonItem>
                                <IonInput
                                    value={globalFilter ?? ''}
                                    onIonInput={({ detail: { value } }) => setGlobalFilter(value as string)}
                                    placeholder="RICERCA DENOMINAZIONE, FORNITORE"
                                />
                                <IonIcon icon={searchOutline} slot='end'></IonIcon>
                            </IonItem>
                        </IonCol>

                        <IonCol size="12" sizeMd='4'>
                            <IonItem>
                                <GruppoMerceologicoSelect products={allProducts} onGruppoMerceologicoSelected={setGruppoMerceologicoSelected}></GruppoMerceologicoSelect>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow class='flex-fill'>
                        <CustomTable table={table} totalLabel='Prodotti totali: '></CustomTable>
                    </IonRow>
                </IonGrid>
            </IonContent >

            <NavigationFooter
                disableContinue={selectedProducts?.length === 0}
                onBack={onClickIndietro}
                onContinue={onClickContinua}
            >
                {totalRow > 0 && (
                    <div>
                        {totalRow > 0 && (
                            <IonText>TOTALE RIGHE:<b> {totalRow}</b></IonText>
                        )}
                        <br />
                        {totalPrice > 0 && (
                            <IonText>TOTALE IMPONIBILE:<b> € {convertNumberToLocale(totalPrice)}</b></IonText>
                        )}
                    </div>
                )}

            </NavigationFooter>
        </>
    );
};

export default SelezionaProdotti;