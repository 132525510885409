import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonInput, IonItem, IonRow, IonSearchbar, IonText, useIonAlert } from "@ionic/react";
import { useEffect, useMemo, useRef, useState } from "react";
import { DraftOrder, useOrdersDraft } from "../../../hooks/useDraftOrders";
import CustomTable, { dateFilterFn } from "../../../components/Table/CustomTable";
import { ColumnDef, ColumnSort, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { convertBaseCurrency, convertNumberToLocale } from "../../../utils/newOrderHelpers";
import { downloadOutline, pencil, searchCircleOutline, searchOutline, trashBin } from "ionicons/icons";
import { Address, Customer, useNewOrder } from "../../../hooks/useNewOrder";
import { useDispatch } from "react-redux";

import { useHistory } from "react-router";
import DatePicker from "../../../components/DatePicker";
import { useMobileMediaQuery } from "../../../hooks/useAppMediaQuery";
import { addDraftOrder } from "../../../redux/draftOrder/draftOrder";
import { loadOrder } from "../../../redux/order/order";

const OrdiniDraft: React.FC = () => {
  const firstInit = useRef(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const [ordersDraft, setOrdersDraft] = useState<DraftOrder[]>([]);
  const { getOrdersDraft, deleteOrderDraft } = useOrdersDraft();

  const [showAlert, dismissAlert] = useIonAlert();
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<ColumnSort[]>([{ id: 'id', desc: true }]);
  const [fromDate, setFromDate] = useState<string | null>(null);
  const [toDate, setToDate] = useState<string | null>(null);
  const isMobile = useMobileMediaQuery();

  // Define columns for the table
  const columns = useMemo<ColumnDef<DraftOrder, any>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'N. Ordine',
        enableGlobalFilter: false,
        meta: {
          mobileHeader: true
        }
      },
      {
        id: 'modificationDateId',
        header: 'Modificato Il',
        enableGlobalFilter: false,
        accessorFn: (row) => {
          if (!row.modificationDate) {
            return null;
          }
          const date = new Date(row.modificationDate * 1000);
          date.setHours(0, 0, 0, 0);
          return date;
        },
        cell: ({ getValue }) => {
          const value = getValue();
          const date = value ? value.toLocaleDateString('it-IT') : '---'; // TODO: add correct locale from app
          return date;

        },
        filterFn: dateFilterFn
      },
      {
        accessorKey: 'customerId',
        header: 'Cliente',
        enableGlobalFilter: false,
      },
      {
        header: 'Destinatario',
        accessorFn: (row) => {
          return `${row.businessName} ${row.city} ${row.address}`
        },
        enableSorting: false,
      },
      {
        header: 'Pezzi',
        accessorFn: (row) => {
          return row.productRows?.length || '---';
        },
        enableGlobalFilter: false,
      },
      {
        header: 'Imponibile',
        accessorFn: (row) => {
          return row.amount ? `${convertBaseCurrency('EUR')} ${convertNumberToLocale(row.amount)}` : '---'
        },
        enableGlobalFilter: false,
      },
      {
        id: 'actionsId',
        cell: ({ row }) => {
          return (
            <IonRow>
              <IonButton class={`${isMobile ? 'small-circle-icon' : 'medium-circle-icon'} ion-margin-end`} color='primary' onClick={() => _edit(row.original)}>
                <IonIcon slot='icon-only' icon={pencil}></IonIcon>
              </IonButton>
              <IonButton class={isMobile ? 'small-circle-icon' : 'medium-circle-icon'} color='medium' onClick={() => _delete(row.original)}>
                <IonIcon slot='icon-only' icon={trashBin}></IonIcon>
              </IonButton>
            </IonRow>
          )
        },
        enableGlobalFilter: false,
      }
    ],
    []
  );

  const table = useReactTable({
    data: ordersDraft, // Define data source
    columns, // Define all column props
    state: {
      globalFilter,
      sorting
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter, // Used to enable global filter
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(), // Used to enable pagination
    getFilteredRowModel: getFilteredRowModel(), // Used to enable filtering
    getSortedRowModel: getSortedRowModel(), // Enable sorting
    //  debugTable: true, // TODO: remove in production
  })


  useEffect(() => {
    if (!firstInit.current) {
      return;
    }
    firstInit.current = false;
    (async () => {
      await _getData();
    })()
  }, []);

  useEffect(() => {
    const dateColumn = table.getColumn('modificationDateId');
    dateColumn?.setFilterValue({
      fromDate: fromDate ? new Date(fromDate) : null,
      toDate: toDate ? new Date(toDate) : null
    });
  }, [fromDate, toDate])

  const _getData = async () => {
    const { data, status } = await getOrdersDraft() ?? {};
    if (!data) {
      return;
    }
    if (!status) {
      // TODO: show generic alert error
      console.error(status, data);
      return;
    }
    setOrdersDraft(data ?? []);
  }

  const _download = async (draftOrderSelected: DraftOrder) => {
    // TODO: add this
  }

  const _edit = async (draftOrderSelected: DraftOrder) => {
    dispatch(loadOrder({
      customer: { customerId: draftOrderSelected.customerId } as Customer,
      deliveryAddress: { shiptoId: draftOrderSelected.shipToId as number } as Address,
      deliveryDate: new Date((draftOrderSelected.deliveryDate ?? 0) * 1000).toISOString(),
      id: draftOrderSelected.id ?? null,
      note: draftOrderSelected.note ?? null,
      products: null,
    }));
    dispatch(addDraftOrder(draftOrderSelected));
    history.push('/ordini/nuovo-ordine');
  }

  const _delete = async (draftOrderSelected: DraftOrder) => {
    const { id } = draftOrderSelected;
    if (!id) {
      // TODO: add error alert
      console.error('Missing id from draft order');
      return
    }
    await showAlert({
      header: 'Attenzione', // TODO: add translation
      message: 'Vuoi davvero cancellare questo ordine?', // TODO: add translation
      buttons: [
        {
          text: 'Cancella',
          role: 'cancel',
        }, {
          text: 'Conferma',
          role: 'ok',
          handler: async () => {
            dismissAlert(); // dismiss alert
            // Delete and reload data
            const { status, message } = await deleteOrderDraft(id);
            if (!status) {
              console.error(message);
              return;
            }
            await _getData();
          }
        }
      ]
    })

  }


  return (
    <IonContent>
      <IonGrid class='full-flex-column'>

        <IonRow className="ion-margin-bottom">
          <IonCol>
            <IonText color="primary">
              <h1>ORDINI DRAFT</h1>
            </IonText>
          </IonCol>
        </IonRow>
        <IonRow className="ion-margin-bottom">
          <IonCol size="12" sizeMd="8">
            <IonItem>
              <IonInput
                value={globalFilter ?? ''}
                onIonInput={({ detail: { value } }) => setGlobalFilter(value as string)}
                placeholder="RICERCA DESTINATARIO"
              />
              <IonIcon icon={searchOutline}></IonIcon>
            </IonItem>
          </IonCol>
          <IonCol size="12" sizeMd="2">
            <DatePicker placeholder="Da Data" onChange={setFromDate}></DatePicker>
          </IonCol>
          <IonCol size="12" sizeMd="2">
            <DatePicker placeholder="A Data" onChange={setToDate}></DatePicker>
          </IonCol>

        </IonRow>
        <IonRow class="flex-fill">
          <CustomTable table={table} totalLabel="Ordini totali: "></CustomTable>
        </IonRow>

      </IonGrid>
    </IonContent>
  );
};

export default OrdiniDraft;