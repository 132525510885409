import { IonApp, IonContent, IonRouterOutlet, IonSplitPane, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route, Switch } from 'react-router-dom';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/irca-theme.css';

/* MAIN COMPONENTS */
import { AuthProvider, useAuth } from './Context/AuthenticationContext';
import { NetworkProvider } from './Context/NetworkContext';
import { EnvProvider } from './Context/EnvContext';
import { CompanyProvider } from './Context/CompanyContext';
import Header from "./components/Header/Header";
import Menu from "./components/Menu";
/* PAGES */
import HomePage from './pages/Homepage/HomePage';
import Login from './pages/Login/Login';
import Ordini from "./pages/Ordini";
import Admin from "./pages/Materiali";
import Settings from "./pages/settings";
import AgentProfile from "./pages/Profile/AgentProfile";
import DesignSystem from "./pages/design/DesignSystem";
import Company from "./pages/Company/Company";
import { withLoggedIn } from './routes/withLoggedIn';
import { useSelector } from 'react-redux';
import { MenuState } from './redux/menu/menu';
import { SQLiteHook, useSQLite } from 'react-sqlite-hook';
import Database from './utils/database';
import { Capacitor } from '@capacitor/core';
import { ReduxState } from './redux/store';

setupIonicReact({
  innerHTMLTemplatesEnabled: true
});

// Singleton SQLite Hook
export let sqlite: SQLiteHook;
export let offlineDatabase: Database;
export let mainDatabase: Database;

const App: React.FC = () => {
  sqlite = useSQLite();
  if (Capacitor.isNativePlatform()) {
    offlineDatabase = new Database({
      dbName: 'OFFLINE',
      downloadable: true,
    });
    mainDatabase = new Database({
      dbName: 'MAIN',
      // encryptedKey: 'paolone' // TODO: check this
    })

  }
  return (
    <IonApp>
      <EnvProvider>
        <NetworkProvider>
          <AuthProvider>
            <CompanyProvider>
              <IonContent>
                <AppRoutes />
              </IonContent>
            </CompanyProvider>
          </AuthProvider>
        </NetworkProvider>
      </EnvProvider>
    </IonApp >
  );
};

const AppRoutes: React.FC = () => {
  const { isLoggedIn } = useAuth();
  const { disabled }: MenuState = useSelector((state: ReduxState) => state.menu);

  return (
    <IonReactRouter>
      <Header />
      <IonSplitPane when="(min-width: 1800px)" disabled={!isLoggedIn || disabled} contentId="main" style={{ '--side-max-width': '317px' }}>
        <Menu disabled={!isLoggedIn || disabled} />
        <IonRouterOutlet id="main">
          <div className="ion-page">
            <Switch>
              <Redirect path="/" to="/homepage" exact />
              <Route exact path='/login' component={Login}></Route>
              <Route exact path="/homepage" component={withLoggedIn(HomePage)} />
              <Route exact path="/design-system" component={withLoggedIn(DesignSystem)} />
              <Route path="/ordini" component={withLoggedIn(Ordini)} />
              <Route path="/materiali" component={withLoggedIn(Admin)} />
              <Route path="/settings" component={withLoggedIn(Settings)} />
              <Route path="/profile" exact={true} component={withLoggedIn(AgentProfile)} />
              <Route path="/company" exact={true} component={withLoggedIn(Company, false)} />
            </Switch>
          </div>
        </IonRouterOutlet>
      </IonSplitPane>
    </IonReactRouter>
  );
};

export default App;
