import { createAction, createReducer } from "@reduxjs/toolkit";
import { DraftOrder } from "../../hooks/useDraftOrders";


export const addDraftOrder = createAction<DraftOrder>('order/addDraftOrder');
export const removeDraftOrder = createAction('order/removeDraftOrder');


export interface DraftOrderState {
    draftOrder: DraftOrder | null;
}

const initState: DraftOrderState = {
    draftOrder: null
}

const reducer = createReducer(initState, (builder) => {
    builder.addCase(addDraftOrder, (state, action) => {
        state.draftOrder = action.payload; action
    });
    builder.addCase(removeDraftOrder, (state) => {
        state.draftOrder = null;
    });
});

export default reducer;