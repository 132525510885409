import { useMediaQuery } from "react-responsive"

const useMobileMediaQuery = () => useMediaQuery({
    maxWidth: '600px'
})

const useTabletMediaQuery = () => useMediaQuery({
    minWidth: '600px',
    maxWidth: '1281px'
})

export {
    useMobileMediaQuery,
    useTabletMediaQuery
}