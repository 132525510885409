import type React from 'react';

import { useAuth } from '../Context/AuthenticationContext';

import { withCondition } from './withCondition';
import { useCompany } from '../Context/CompanyContext';

/** A higher-order wrapper, binding the "user logged in" condition and redirect */
export const withLoggedIn = (Component: React.FunctionComponent, checkCompany: boolean = true) => withCondition(
  checkCompany ? withCondition(Component, Boolean(useCompany().company), '/company') : Component,
  useAuth().isLoggedIn,
  '/login'
);


/** The inverse, showing a page only if a user is logged OUT */
export const withLoggedOut = (Component: React.FunctionComponent) =>
  withCondition(Component, !useAuth().isLoggedIn, '/');
