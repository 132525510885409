import { useAuth } from "../Context/AuthenticationContext"
import { useCompany } from "../Context/CompanyContext";
import { useEnv } from "../Context/EnvContext"
import { OrderState } from "../redux/order/order";
import { API_M3_PATH, useApi } from "./useApi"
import { DraftOrder } from "./useDraftOrders";



export type UseNewOrderI = {
    getSalesmanCustomers: (onlyEnableToOrder?: boolean) => Promise<Customer[]>;
    getCustomerProducts: (customerId: string) => Promise<Product[]>;
    getCustomerShippingAddresses: (customerId: string) => Promise<Address[]>;
    convertDraftCustomer: (customerId: string) => Promise<Customer>;
    convertDraftProducts: ({ productRows, baseProducts }: { productRows: any[], baseProducts: Product[] }) => Product[];
    convertDeliveryAddress: ({ shipToId, addresses }: { shipToId: number, addresses: Address[] }) => Address;
    convertDeliveryDate: (deliveryDate: number) => string | null;
    getCompanyData: () => Promise<CompanyData>;
}

export type Customer = {
    abi: string
    address: string
    cab: string
    city: string
    customerId: string
    desPayment: string
    description: string
    email: string
    flagAlcol: string
    phone1: string
    phone2: string
    provincia: string
    salesman: string
    taxIdCode: string
    tipoOrdine: string
    vatNumber: string
    zipCode: string,
    paymentType: string,
    shipToAddress: string,
    shipToCity: string,
    shipTo?: string[],
}

export interface CustomerWithFavorite extends Customer {
    favorite: boolean
}

export type Product = {
    itemId: string;
    articoloDes: string;
    gruppoMerceologico: string;
    listino: string;
    pzConf: number;
    um: string;
    timeStamp: string;
    flagAlcol: string;
    alias: string;
    status: "20" | "50";
    disponibilita: number;
    supplierPref: string;
    supplierDes: string;
    company: string;
    contractPrice: number;
    assortmentPrice: number;
    basePrice: number;
    baseCurrency: "EUR";
    sconto: number;
    warehouse: string;
    quantity?: number; //used by the table
    convRate: number;
    orderHistory: number;
    orderedQuantity: number;
    qtaMax: number;
    qtaMinima: number;
    decimals: number;
}

export type Address = {
    description: string
    shiptoId: number
    address: string
    city: string
    countryNewId: string
    salesman: string
    cap: string
    provinca: string
}

export type CompanyData = {
    prefAfo: string;
    division: string;
    longDes: string;
    shortDes: string;
    facility: string;
    warehouse: string;
}


export const useNewOrder = (): UseNewOrderI => {
    const { callApi } = useApi();
    const { getEnv } = useEnv();
    const { loginData } = useAuth();
    const { company } = useCompany();

    const getSalesmanCustomers = async (onlyEnableToOrder?: boolean): Promise<Customer[]> => {
        if (!loginData) {
            throw new Error('Missing loginData!');
        }
        // Get base gateway url
        const gatewayBase = getEnv('GATEWAY_BASE_URL');
        // Prepare url
        const url = new URL(`${gatewayBase}/${API_M3_PATH}/salesmen/${loginData.jwt.erpId}/customers`);
        url.searchParams.append('managedSalesmen', loginData.jwt.managedSalesmen.join(','));
        if (onlyEnableToOrder) {
            url.searchParams.append('onlyEnableToOrder', Boolean(onlyEnableToOrder).toString());
        }
        // Call api
        const customers = await callApi({
            url: url.toString(),
            method: 'GET'
        });
        const customerReduced = [];
        for (const customer of customers) {
            const shipTo = `${customer.shipToAddress || ''} ${customer.shipToCity || ''}`;
            const indexFind = customerReduced.findIndex((c) => c.customerId === customer.customerId);
            if (indexFind < 0) {
                customerReduced.push({ ...customer, shipTo: [shipTo] });
            } else {
                customerReduced[indexFind].shipTo.push(shipTo);
            }
        }
        return customerReduced;
    }

    const getCustomerProducts = async (customerId: string): Promise<Product[]> => {
        if (!loginData) {
            throw new Error('Missing loginData!');
        }
        // Get base gateway url
        const gatewayBase = getEnv('GATEWAY_BASE_URL');
        // Prepare url
        const url = new URL(`${gatewayBase}/${API_M3_PATH}/customers/${customerId}/products`);
        url.searchParams.append('salesmanId', loginData.jwt.erpId)
        // Call api
        return await callApi({
            url: url.toString(),
            method: 'GET'
        });
    }

    const getCustomerShippingAddresses = async (customerId: string): Promise<Address[]> => {
        if (!loginData) {
            throw new Error('Missing loginData!');
        }
        // Get base gateway url
        const gatewayBase = getEnv('GATEWAY_BASE_URL');
        // Prepare url
        const url = new URL(`${gatewayBase}/${API_M3_PATH}/customers/${customerId}/ship-to`);
        // Call api
        return await callApi({
            url: url.toString(),
            method: 'GET'
        });
    }

    const getCompanyData = async () => {
        if (!company) {
            throw new Error('Missing company');
        }
        // Get base gateway url
        const gatewayBase = getEnv('GATEWAY_BASE_URL');
        // Prepare url
        const url = new URL(`${gatewayBase}/${API_M3_PATH}/companies/${company}`);
        // Call api
        return await callApi({
            url: url.toString(),
            method: 'GET'
        });
    }

    const convertDraftCustomer = async (customerId: string) => {
        if (!customerId) {
            throw new Error('Missing customerId');
        }
        // Convert customer
        let convertedCustomer: Customer | null;
        const customers = await getSalesmanCustomers();
        convertedCustomer = customers.find((customer) => customer.customerId === customerId) ?? null;
        if (!convertedCustomer) {
            throw new Error(`Can´t found the customer ${customerId}`);
        }
        return convertedCustomer;
    }

    const convertDraftProducts = ({ productRows, baseProducts }: { productRows: any[], baseProducts: Product[] }) => {
        // Convert Products
        let convertedProducts: Product[] = [];
        if (productRows) {
            for (const row of productRows) {
                const productFound = baseProducts.find((product) => product.itemId === row.productId);
                if (!productFound) {
                    // TODO: alert user?
                    console.error('Item not found');
                    continue;
                }
                if (productFound) {
                    productFound.quantity = row.quantity;
                    productFound.sconto = row.discount;
                }
                convertedProducts.push(productFound);
            }
        }
        return convertedProducts;
    }

    const convertDeliveryAddress = ({ shipToId, addresses }: { shipToId: number, addresses: Address[] }) => {
        let convertedAddress: Address | null = null;
        if (shipToId !== undefined || shipToId !== null) {
            convertedAddress = addresses.find((address) => address.shiptoId === shipToId) ?? null
        }
        if (!convertedAddress) {
            throw new Error(`Can´t found the Address Id ${shipToId}`);
        }
        return convertedAddress;
    }

    const convertDeliveryDate = (deliveryDate: number) => {
        let convertedDate: string | null = null;
        if (deliveryDate) {
            convertedDate = new Date(deliveryDate * 1000).toISOString();
        }
        return convertedDate;
    }


    return {
        getSalesmanCustomers,
        getCustomerProducts,
        getCustomerShippingAddresses,
        convertDraftCustomer,
        convertDraftProducts,
        convertDeliveryAddress,
        convertDeliveryDate,
        getCompanyData,
    };
}