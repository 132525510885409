import Helper from "./helper.js";

export default class Customers {
    constructor({
        knex,
        onlyQuery = false,
    }) {
        this._knex = knex;
        this._onlyQuery = onlyQuery;
    };

    async _handleQueryBuilder(queryBuilder) {
        if (this._onlyQuery) {
            return queryBuilder.toString();
        }
        return Helper.clean(await queryBuilder);
    }
    // Route
    async getProducts({
        salesmanId,
        customerId,
        company,
    }) {
        if (!customerId) {
            throw new Error('Missing customerId param!');
        }
        if (!salesmanId) {
            throw new Error('Missing salesmanId param!');
        }
        const dateNow = Helper.getNowDate();

        const queryBuilder = this._knex
            .select([
                'vwdp.*',
                'vwdcc.PRICE AS CONTRACT_PRICE',
                'vcpl.SALES_PRICE AS BASE_PRICE',
                'vwds.SCONTO AS SCONTO',
                'vwdc.WAREHOUSE',
                'vwds2.CONTA AS ORDER_HISTORY',
                'vwds2.QTA_ORDINATA AS ORDERED_QUANTITY',
            ]);
        queryBuilder.from('v_CUSTOMER_PRICE_LIST AS vcpl')
            .innerJoin('v_web_DED_PRODUCTS AS vwdp', function () {
                this.on('vwdp.ITEM_ID', '=', ' vcpl.ITEM_ID');
                if (company) {
                    this.onIn('vwdp.COMPANY', [company]);
                }
            })
            .leftJoin('v_web_DED_CUSTOMER_CONTRACTS AS vwdcc', function () {
                this.on('vwdcc.ITEM_ID', '=', ' vcpl.ITEM_ID');
                this.onIn('vwdcc.CUSTOMER_ID', [customerId]);
                this.onBetween('vwdcc.FROM_DATE', ['0', dateNow]);
                this.onBetween('vwdcc.TO_DATE', [dateNow, '99991231']);
                if (company) {
                    this.onIn('vwdcc.COMPANY', [company]);
                }
            })
            .leftJoin('v_web_DED_ASSORTMENT_PRICELIST AS vwdap', function () {
                this.on('vwdap.ITEM_ID', '=', ' vcpl.ITEM_ID');
                this.onIn('vwdap.CUSTOMER_ID', [customerId]);
            })
            .leftJoin('v_web_DED_SCONTI AS vwds', function () {
                this.on('vwds.ITEM_ID', '=', 'vcpl.ITEM_ID');
                this.onIn('vwds.CUSTOMER_ID', [customerId]);
            })
            .leftJoin('v_web_DED_COMPANY AS vwdc', function () {
                this.on('vwdp.COMPANY', '=', 'vwdc.PREF_AFO');
            })
            .leftJoin('v_web_DED_STORICO AS vwds2', function () {
                this.on('vwds2.ARTICOLO', '=', 'vcpl.ITEM_ID');
                this.onIn('vwds2.CUSTOMER_ID', [customerId]);
                this.onIn('vwds2.SALESMAN_ID', [salesmanId]);
            })
            .where('vcpl.CUSTOMER_ID', '=', customerId);

        return this._handleQueryBuilder(queryBuilder);
    }

    async getShipTo({
        customerId,
    }) {
        if (!customerId) {
            throw new Error('Missing customerId param!');
        }

        return this._handleQueryBuilder(
            this._knex
                .select('*')
                .from('v_web_DED_SHIPTO AS vwds')
                .where('vwds.CUSTOMER_ID', '=', customerId)
        );
    }

    async getInvoices({
        customerId,
        company,
    }) {
        if (!customerId) {
            throw new Error('Missing customerId param!');
        }

        const queryBuilder = this._knex
            .select('*')
            .from('v_web_DED_REVENUES_HEAD AS vwdrh')
            .where('vwdrh.CUSTOMER_ID', '=', customerId);

        if (company) {
            queryBuilder.andWhere('vwdrh.COMPANY', '=', company);
        }
        return this._handleQueryBuilder(queryBuilder);
    }

    async getInvoice({
        customerId,
        invoiceId,
        company,
    }) {
        if (!customerId) {
            throw new Error('Missing customerId param!');
        }
        if (!invoiceId) {
            throw new Error('Missing invoiceId param!');
        }

        const queryBuilder = this._knex
            .select('*')
            .from('v_web_DED_REVENUES_ROW AS vwdrr')
            .where('vwdrr.INVOICE_NR ', '=', invoiceId)
            .andWhere('vwdrr.CUSTOMER_ID', '=', customerId);

        if (company) {
            queryBuilder.andWhere('vwdrr.COMPANY', '=', company);
        }
        return this._handleQueryBuilder(queryBuilder);
    }

    async getTransportDocuments({
        customerId,
        company,
    }) {
        if (!customerId) {
            throw new Error('Missing customerId param!');
        }
        const queryBuilder = this._knex
            .select([
                'vwddh.COMPANY',
                'vwddh.CODICECLIENTE AS CODICE_CLIENTE',
                'vwddh.DATADDT AS DATA_DDT',
                'vwddh.DDT',
                'vwds.DESCRIPTION',
                'vwds.ADDRESS',
                'vwds.CITY',
                'vwds.COUNTRY_NEW_ID',
                'vwds.CAP',
                'vwds.PROVINCA AS PROVINCIA',
            ])
            .from('v_web_DED_DELIVERY_HEAD AS vwddh')
            .innerJoin('v_web_DED_SHIPTO AS vwds', function () {
                this.on('vwddh.SHIPTO', '=', 'vwds.SHIPTO_ID');
                this.on('vwddh.CODICECLIENTE', '=', 'vwds.CUSTOMER_ID');
            })
            .where('vwddh.CODICECLIENTE', '=', customerId);

        if (company) {
            queryBuilder.andWhere('vwddh.COMPANY', '=', company);
        }
        return this._handleQueryBuilder(queryBuilder);
    }

    async getTransportDocument({
        customerId,
        ddtId,
        company,
    }) {
        if (!customerId) {
            throw new Error('Missing customerId param!');
        }

        const queryBuilder = this._knex
            .select('*')
            .from('v_web_DED_DELIVERY_ROW AS vwddr')
            .where('vwddr.CODICECLIENTE', '=', customerId)
            .andWhere('vwddr.DDT', '=', ddtId);

        if (company) {
            queryBuilder.andWhere('vwddr.COMPANY', '=', company);
        }
        return this._handleQueryBuilder(queryBuilder);
    }

    async getOrders({
        customerId,
        company,
    }) {
        if (!customerId) {
            throw new Error('Missing customerId param!');
        }

        const queryBuilder = this._knex
            .select('*')
            .from('v_web_DED_ORDINI_HEAD AS vwdoh')
            .where('vwdoh.CUSTOMER_ID', '=', customerId);

        if (company) {
            queryBuilder.andWhere('vwdoh.COMPANY', '=', company);
        }
        return this._handleQueryBuilder(queryBuilder);
    }

    async getOrder({
        customerId,
        orderId,
        company,
    }) {
        if (!customerId) {
            throw new Error('Missing customerId param!');
        }
        if (!orderId) {
            throw new Error('Missing oderId param!');
        }

        const queryBuilder = this._knex
            .select([
                'vwdor.*',
                'vwdp.ARTICOLO_DES',
                'vwdoh.CUSTOMER_ID',
            ])
            .from('v_web_DED_ORDINI_ROW AS vwdor')
            .innerJoin('v_web_DED_ORDINI_HEAD AS vwdoh', function () {
                this.on('vwdor.NUMEROORDINE', '=', 'vwdoh.NUMEROORDINE');
            })
            .innerJoin('v_web_DED_PRODUCTS AS vwdp', function () {
                this.on('vwdor.ARTICOLO', '=', 'vwdp.ITEM_ID');
            })

            .where('vwdor.NUMEROORDINE', '=', orderId)
            .andWhere('vwdoh.CUSTOMER_ID', '=', customerId)
            .orderBy('vwdor.NUMERORIGA');  // TODO: ORDER BY CAST(vwdor.NUMERORIGA AS INT) 

        if (company) {
            queryBuilder.andWhere('vwdor.COMPANY', '=', company);
            queryBuilder.andWhere('vwdoh.COMPANY', '=', company);
            queryBuilder.andWhere('vwdp.COMPANY', '=', company);
        }

        return this._handleQueryBuilder(queryBuilder);
    }

    async getPartite({
        customerId,
        company,
    }) {
        if (!customerId) {
            throw new Error('Missing customerId param!');
        }

        const queryBuilder = this._knex
            .select('*')
            .from('v_web_DED_PARTITE_2023 AS vwdp2')
            .where('vwdp2.CUSTOMER_ID', '=', customerId)
            .orderBy('vwdp2.DATA_FATTURA', 'desc');

        if (company) {
            queryBuilder.andWhere('vwdp2.COMPANY', '=', company);
        }
        return this._handleQueryBuilder(queryBuilder);
    }
}