import { useAuth } from "../Context/AuthenticationContext";
import { useEnv } from "../Context/EnvContext";
import { BACKEND_PATH, useApi } from "./useApi";

interface useMaterialiCommercialiI {
    getMaterialiCommerciali: () => Promise<MaterialeCommerciale[]>
}

export interface MaterialeCommerciale {
    id: number
    path: string
    title: string
    description: string
    validityFrom: number
    validityTo: number
    filename: string
}

export const useMaterialiCommerciali = (): useMaterialiCommercialiI => {
    const { callApi } = useApi();
    const { getEnv } = useEnv();

    const getMaterialiCommerciali = async () => {
        // Get base gateway url
        const gatewayBase = getEnv('GATEWAY_BASE_URL');
        // Prepare url
        const url = new URL(`${gatewayBase}/${BACKEND_PATH}/communication-materials`);
        const { data } = await callApi({
            url: url.toString(),
            method: 'GET'
        });
        if (!data) {
            throw new Error('missing data');
        }
        return data;
    }

    return { getMaterialiCommerciali }
}