import { IonButton, IonIcon } from "@ionic/react"
import { heart, heartOutline } from "ionicons/icons";
import { MouseEvent } from "react";
import { useNetwork } from "../../Context/NetworkContext";
import { useMobileMediaQuery } from "../../hooks/useAppMediaQuery";

import "./FavoriteButton.css"

interface FavoriteButtonI {
    checked: boolean
    onClick: (newValue: boolean) => void;
}

const FavoriteButton: React.FC<FavoriteButtonI> = ({ checked, onClick }) => {
    const { isOnline } = useNetwork();
    const isMobile = useMobileMediaQuery();
    const _onClick = (event: MouseEvent<HTMLIonButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
        onClick(!checked);
    }
    const circleClass = isMobile ? 'small-circle-icon' : 'medium-circle-icon';
    return (
        <IonButton
            class={`favorite-button ${circleClass}`}
            color={checked ? 'secondary' : 'light'}
            onClick={_onClick}
            disabled={!isOnline}
        >
            <IonIcon slot='icon-only' icon={checked ? heart : heartOutline}></IonIcon>
        </IonButton>
    )
}

export default FavoriteButton;