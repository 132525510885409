import React from 'react';

import StepTab from '../../../components/StepTab/StepTab';


const NuovoOrdineTabs: React.FC = () => {
  return (
    <StepTab
      tabs={[
        {
          path: "/ordini/nuovo-ordine/seleziona-cliente",
          title: "1",
          subTitle: "Seleziona Cliente",
        },
        {
          path: "/ordini/nuovo-ordine/seleziona-prodotti",
          title: "2",
          subTitle: "Seleziona Prodotti",
        },
        {
          path: "/ordini/nuovo-ordine/indirizzo-consegna",
          title: "3",
          subTitle: "Indirizzo di consegna",
        },
        {
          path: "/ordini/nuovo-ordine/data-consegna",
          title: "4",
          subTitle: "Data di consegna",
        },
        {
          path: "/ordini/nuovo-ordine/riepilogo-note",
          title: "5",
          subTitle: "Riepilogo e aggiunta note",
        }
      ]}
    />
  );
};

export default NuovoOrdineTabs;