import { IonContent, IonGrid, IonLabel, IonRow, IonText, IonTextarea, IonItem, IonFooter, IonCol, IonButton, IonToolbar, IonButtons, IonIcon } from '@ionic/react';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import NuovoOrdineTabs from "./NuovoOrdineTabs";
import { OrderState, addNote, clearOrder, removeNote } from '../../../redux/order/order';
import { useDispatch, useSelector } from 'react-redux';
import { calculateFinalPrice, calculateTotalRowPrice, calculateTotalRowsPrice, completeAddress, convertBaseCurrency, convertNumberToLocale, convertToDraftOrder } from '../../../utils/newOrderHelpers';
import { ColumnDef, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { Product, useNewOrder } from '../../../hooks/useNewOrder';
import { arrowDown, arrowUp, ellipse } from 'ionicons/icons';
import CustomTable from '../../../components/Table/CustomTable';
import { useOrdersDraft } from '../../../hooks/useDraftOrders';
import NavigationFooter from '../../../components/Navigation-Footer/NavigationFooter';
import SaveDraft from './SaveDraft';
import { useMobileMediaQuery } from '../../../hooks/useAppMediaQuery';
import { useNetwork } from '../../../Context/NetworkContext';
import { ReduxState } from '../../../redux/store';
import { useCheckStepOrRedirect } from '.';

// Row of table compact view
const minRowTable = 3;

const RiepilogoNote: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isOnline } = useNetwork();
  const { getCompanyData } = useNewOrder();
  const { saveOrderDraft, sendOrderDraft } = useOrdersDraft();
  const orderState: OrderState = useSelector((state: ReduxState) => state.order);
  const { id, products, customer, deliveryAddress, deliveryDate, note } = orderState;
  const [currentProducts, setCurrentProducts] = useState<Product[]>(products?.slice(0, minRowTable) ?? [])
  const [showAllProduct, setShowAllProduct] = useState(true);
  const isMobile = useMobileMediaQuery();
  const rowsTotalPrice = convertNumberToLocale(Number(calculateTotalRowsPrice(products)));

  useCheckStepOrRedirect()({ orderState });

  // Logic show all or minRowTable products
  const _toggleProduct = () => {
    if (currentProducts.length > minRowTable) {
      setShowAllProduct(true)
      setCurrentProducts(products?.slice(0, minRowTable) ?? []);
    } else {
      setShowAllProduct(false);
      setCurrentProducts(products ?? []);
    }
  }

  // Define columns for the table
  const columns = useMemo<ColumnDef<Product, any>[]>(
    () => [
      {
        id: 'productNumberId',
        header: 'N.Prodotto',
        cell: ({ row }) => {
          return row.index + 1;
        },
        meta: {
          mobileHeader: true
        }
      },
      {
        accessorKey: 'articoloDes',
        header: 'Nome prodotto',
      },
      {
        header: 'Prezzo Pubblico',
        accessorFn: (row) => `${convertBaseCurrency(row.baseCurrency)} ${convertNumberToLocale(row.basePrice)}`
      },
      {
        id: 'availabilityId',
        accessorKey: 'disponibilita',
        cell: ({ getValue }) => {
          // Get value disponibilità
          const disponibilita = getValue();
          return (
            <IonIcon size='large' icon={ellipse} color={Boolean(disponibilita) ? 'success' : 'danger'} ></IonIcon >
          )
        },
        header: 'Disponibilità',
        enableSorting: false,
        enableGlobalFilter: false,
      },
      {
        accessorKey: 'listino',
        header: 'Listino',
        enableSorting: false,
        enableGlobalFilter: false,
      },
      {
        header: "Sconto Netto",
        accessorKey: "sconto",
        enableSorting: false,
      },
      {
        header: "Quantità",
        accessorKey: "quantity",
        enableSorting: false,
      },
      {
        accessorKey: 'pzConf',
        header: 'Pz conf',
        enableSorting: false,
      },
      {
        accessorKey: 'um',
        header: 'Um'
      },
      {
        accessorFn: (row) => {
          const { basePrice, contractPrice, assortmentPrice, baseCurrency } = row;
          // Apply logic from irca to show the correct price
          return `${convertBaseCurrency(baseCurrency)} ${convertNumberToLocale(calculateFinalPrice({ basePrice, contractPrice, assortmentPrice }))}`;
        },
        header: 'Prezzo di cessione',
        enableGlobalFilter: false,
      },

      {
        header: "Totale",
        accessorFn: (row) => {
          return `${convertBaseCurrency(row.baseCurrency)} ${convertNumberToLocale(calculateTotalRowPrice(row))}`;
        },
        // enableSorting: false,  // TODO: enable to help user find the item they have selected
      },


    ],
    []
  );

  const table = useReactTable({
    data: currentProducts, // Define data source
    columns, // Define all column props
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), // Enable sorting
    ////  debugTable: true, // TODO: remove in production
  });

  const onClickIndietro = () => {
    // dispatch(removeNote());
    history.replace('data-consegna');
  };

  const onClickContinua = async () => {
    // Creating complete draft order
    const convertedOrder = convertToDraftOrder({ orderState, checkForSend: true })

    // Add company data to the draft before the send
    const companyData = await getCompanyData();
    convertedOrder.companyCode = companyData.prefAfo;
    convertedOrder.companyDivision = companyData.division;
    convertedOrder.companyFacility = companyData.facility;

    const { data } = await saveOrderDraft(convertedOrder);
    if (!data) {
      throw Error('saveOrderDraft failed');
    }
    // Using id for send the draft
    const result = await sendOrderDraft(data.id);
    // check status
    if (!result.status) {
      throw new Error('Generic error');
    }
    // go to ordineInviato page
    history.replace('ordine-inviato');
  }



  return (
    <>
      <IonContent>
        <IonGrid>
          {isMobile && (
            <IonRow class='ion-justify-content-end'>
              <SaveDraft />
            </IonRow>
          )}
          <IonRow>
            <IonCol>
              <h4><strong>NUOVO ORDINE</strong></h4>
              <IonText color="primary">
                <h1>RIEPILOGO E AGGIUNTA NOTE</h1>
              </IonText>
            </IonCol>
            {!isMobile && (<SaveDraft />)}
          </IonRow>

          <div>
            <NuovoOrdineTabs />
          </div>
        </IonGrid>
        <IonGrid>
          {id && (
            <IonRow >
              <IonCol size="auto">
                <IonText><b>Ordine</b></IonText>
              </IonCol>
              <IonCol>
                <IonText color="medium">{id}</IonText>
              </IonCol>
            </IonRow>
          )}
          <IonRow>
            <h1>
              <IonText>
                {customer?.description}&nbsp;
              </IonText>
              <IonText color='primary'>
                ({customer?.customerId})
              </IonText>
            </h1>
          </IonRow>
          <IonRow class="ion-margin-bottom">
            <IonText>{completeAddress(deliveryAddress)}</IonText>
          </IonRow>
          <IonRow class="ion-margin-bottom">
            <IonCol size="auto">
              <IonText><b>P.IVA:</b></IonText>
            </IonCol>
            <IonCol size="auto">
              <IonText color="medium">{customer?.vatNumber || '---'}</IonText>
            </IonCol>
            <IonCol size="auto">
              <IonText><b>C.F:</b></IonText>
            </IonCol>
            <IonCol size="auto">
              <IonText color="medium">{customer?.taxIdCode || '---'}</IonText>
            </IonCol>
            <IonCol size="auto">
              <IonText><b>ABI:</b></IonText>
            </IonCol>
            <IonCol size="auto">
              <IonText color="medium">{customer?.abi || '---'}</IonText>
            </IonCol>
            <IonCol size="auto">
              <IonText><b>CAB:</b></IonText>
            </IonCol>
            <IonCol size="auto">
              <IonText color="medium">{customer?.cab || '---'}</IonText>
            </IonCol>
          </IonRow>
          <IonRow class="ion-margin-bottom">
            <IonCol size="auto">
              <IonText><b>Modalità di pagamento:</b></IonText>
            </IonCol>
            <IonCol size="auto">
              <IonText color="medium">{customer?.paymentType}</IonText>
            </IonCol>
          </IonRow>
          <IonRow class="ion-margin-bottom">
            <IonCol size="auto">
              <IonText><b>Data di consegna:</b></IonText>
            </IonCol>
            <IonCol size="auto">
              {/* TODO: get correct locale from the app */}
              <IonText color="medium">{new Date(deliveryDate ?? '')?.toLocaleDateString('it-IT', {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              })}</IonText>
            </IonCol>
          </IonRow>
          <IonRow class="ion-margin-bottom">
            <IonCol size="auto">
              <IonText>NUMERO DI PRODOTTI:</IonText>
            </IonCol>
            <IonCol>
              <IonText><b>{products?.length}</b></IonText>
            </IonCol>
          </IonRow>

          <IonRow>
            <CustomTable table={table} showPageButtons={false} showTotalLabel={false}></CustomTable>
          </IonRow>
          {(products?.length ?? 0) >= 4 && (
            <IonRow class='ion-justify-content-center'>
              <IonButton fill='clear' onClick={_toggleProduct} color='primary'>

                {showAllProduct ? 'Vedi tutti i prodotti' : 'Chiudi vista Prodotti'}
                <IonIcon slot='end' icon={showAllProduct ? arrowDown : arrowUp}></IonIcon>

              </IonButton>
            </IonRow>
          )}
        </IonGrid>
        <IonGrid class='ion-margin-bottom'>
          <IonLabel>NOTE</IonLabel>
          <IonItem>
            <IonTextarea rows={4} value={note} onIonInput={({ detail }) => dispatch(addNote(detail.value as string))} debounce={500}></IonTextarea>
          </IonItem>
        </IonGrid>

      </IonContent>

      <NavigationFooter
        onBack={onClickIndietro}
        onContinue={onClickContinua}
        continueLabel='Invia Ordine'
        disableContinue={!isOnline}
      >
        <div slot="start">
          <IonText>TOTALE RIGHE:<b>{products?.length}</b></IonText> {/* TODO: add translation */}
          <br />
          <IonText>TOTALE IMPONIBILE:<b> € {rowsTotalPrice}</b></IonText>
        </div>
      </NavigationFooter>
    </>
  );
};

export default RiepilogoNote;