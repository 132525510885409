import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonProgressBar, IonRow, IonText } from "@ionic/react"
import { checkmarkCircle, closeCircle, refreshOutline } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";

import "./Offline.css";
import { offlineDatabase } from "../../App";
import { useApi } from "../../hooks/useApi";
import { useOrdersDraft } from "../../hooks/useDraftOrders";
import { useNetwork } from "../../Context/NetworkContext";
import { useAuth } from "../../Context/AuthenticationContext";

enum STATUS {
    IDLE, DOWNLOAD, ERROR, DONE
}

const Offline: React.FC = () => {
    const init = useRef(false);
    const [status, setStatus] = useState<STATUS>(STATUS.IDLE);
    const [currentTotalDownload, setCurrentTotalDownload] = useState(0);
    const [lastUpdate, setLastUpdate] = useState<Date | null>();
    const [offlineDrafts, setOfflineDrafts] = useState<any[]>();
    const { offlineGateway } = useApi()
    const { getOrdersDraft, saveOrderDraft } = useOrdersDraft();
    const { isOnline } = useNetwork();
    const { loginData } = useAuth()

    useEffect(() => {
        if (init.current) {
            return
        }
        init.current = true;
        offlineDatabase.getLastUpdateDbDate().then(setLastUpdate);
    }, []);

    useEffect(() => {
        (async () => {
            const drafts = await offlineGateway.offlineBackend.getOfflineDraftOrdersInfo();
            setOfflineDrafts(drafts);
        })();
    }, [lastUpdate]);

    const _startDownload = async () => {
        try {
            if (!isOnline) {
                throw new Error('Must be online!');
            }

            const salesmanId = loginData?.jwt.erpId
            if (!salesmanId) {
                throw new Error('Must be logged in!');
            }

            setStatus(STATUS.DOWNLOAD);

            await offlineGateway.offlineBackend.initDb();

            const offlineDrafts = await offlineGateway.offlineBackend.getOfflineDraftOrdersInfo();
            const toBeSynced = offlineDrafts.filter(d => !d.sync);

            for (const draft of toBeSynced) {
                const data = { ...JSON.parse(draft.json) };
                if (draft.new) {
                    data.id = undefined;
                }
                const result = await saveOrderDraft(data);
                if (!result.status) {
                    throw new Error(result.message);
                }
                if (result.data?.id !== draft.id) {
                    // TODO: Update local id for new orders
                    debugger;
                }
            }

            // Download drafts
            const drafts = await getOrdersDraft();
            if (!drafts.status || !drafts.data) throw new Error(`Error while downloading draft orders`);
            await offlineGateway.offlineBackend.saveDraftOrders(drafts.data);

            await offlineDatabase.download({
                progressCallback: ({ current, total }) => {
                    if (!total) {
                        return;
                    }
                    setCurrentTotalDownload(current / total);
                },
                salesmanId,
            });

            setCurrentTotalDownload(0);
            setStatus(STATUS.DONE);

        } catch (error) {
            console.error(error);
            setStatus(STATUS.ERROR)
        }
    }
    const _done = async () => {
        setLastUpdate(await offlineDatabase.getLastUpdateDbDate());
        setStatus(STATUS.IDLE);
    }
    const _renderByStatus = () => {
        const newOrders = offlineDrafts?.filter(d => d.new);
        const unsyncedOrders = offlineDrafts?.filter(d => !d.sync);
        console.log({ newOrders, unsyncedOrders });
        switch (status) {
            case STATUS.IDLE:
                return (
                    <>
                        <IonCol class="ion-text-center">
                            <IonText>
                                <h3><strong>DATA ULTIMO SYNC</strong></h3>
                                <p className="ion-margin-top">{lastUpdate?.toLocaleString('it-IT') ?? 'Database mai sincronizzato'}</p>
                                <p className="ion-margin-top">
                                    Draft: {offlineDrafts?.length ?? 0} <br />
                                    Nuovi: {newOrders?.length ?? 0} <br />
                                    Da aggiornare: {unsyncedOrders?.length ?? 0} <br />
                                </p>
                            </IonText>
                            <IonButton shape="round" onClick={_startDownload}>
                                <IonIcon slot="start" icon={refreshOutline}></IonIcon>
                                Effettua un nuovo SYNC
                            </IonButton>
                        </IonCol>
                    </>
                );
            case STATUS.DOWNLOAD:
                return (
                    <>
                        <IonText>
                            <h3><strong>NUOVO SYNC IN CORSO</strong></h3>
                            <p className="ion-margin-top">Attendere fino al completo caricamento dei dati</p>

                        </IonText>
                        <div style={{ padding: '64px 0' }}>
                            <p>{(currentTotalDownload * 100).toFixed(0)} %</p>
                            <IonProgressBar value={currentTotalDownload}></IonProgressBar>
                        </div>
                    </>
                )
            case STATUS.ERROR:
                return (
                    <>
                        <IonIcon color='danger' icon={closeCircle} class="big-icon"></IonIcon>
                        <IonText>
                            <h3><strong>QUALCOSA È ANDATO STORTO</strong></h3>
                            <p className="ion-margin-top">Il SYNC dei dati non è andato a buon fine ti suggeriamo di riprovare</p>
                        </IonText>

                        <IonButton shape="round" onClick={_startDownload}>
                            <IonIcon slot="start" icon={refreshOutline}></IonIcon>
                            Riprova il SYNC
                        </IonButton>
                    </>
                )
            case STATUS.DONE:
                return (
                    <>
                        <IonIcon color='success' icon={checkmarkCircle} class="big-icon"></IonIcon>
                        <IonText>
                            <h3><strong>SYNC COMPLETATO CON SUCCESSO</strong></h3>
                            <p className="ion-margin-top">Tutti i dati sono stati sincronizzati con successo</p>
                        </IonText>

                        <IonButton shape="round" onClick={_done}>
                            Fatto
                        </IonButton>
                    </>
                )
        }
    }
    return (
        <IonContent class="settings-offline">
            <IonGrid style={{ height: "100%" }}>
                <IonRow class="ion-justify-content-around ion-align-items-center" style={{ height: "100%" }}>
                    <IonCol class="ion-text-center">
                        {_renderByStatus()}
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonContent>
    )
}

export default Offline