import { CapacitorSQLite } from '@capacitor-community/sqlite';
import { Directory, Filesystem } from '@capacitor/filesystem';

interface DatabaseOptions {
    dbName: string;
    downloadable?: boolean;
    encryptedKey?: string;
}
interface QueryOptions {
    query: string;
    queryValues?: any[];
}

interface ExecuteOptions {
    query: string;
}

interface DownloadOptions {
    progressCallback: ({ current, total }: { current: number, total: number }) => void;
    salesmanId: string;
}

export interface DefinitionTable {
    name: string,
    columns: DefinitionColumn[]
}

interface DefinitionColumn {
    name: string;
    type: string;
    default?: string;
}

const { REACT_APP_GATEWAY_BASE_URL } = process.env;

class Database {
    private _dbName: string;
    private _downloadable: boolean;
    private _updating: boolean = false;
    private _encryptedKey?: string;
    private PATH_DBS = '../databases';
    private DB_FILE_NAME;

    constructor({
        dbName,
        downloadable = false,
        encryptedKey
    }: DatabaseOptions) {
        this._dbName = dbName;
        this._downloadable = downloadable;
        this.DB_FILE_NAME = `${dbName}SQLite.db`;
        this._encryptedKey = encryptedKey;
    }
    private async _init() {
        const isDbDownloaded = await this.isOk();
        if (!isDbDownloaded && this._downloadable) {
            throw Error('Must download the Offline Backup');
        }
        if (this._encryptedKey) {
            const { result: checkEncryptionSecret } = await CapacitorSQLite.checkEncryptionSecret({
                passphrase: this._encryptedKey
            })
            if (!checkEncryptionSecret) {
                await CapacitorSQLite.setEncryptionSecret({
                    passphrase: this._encryptedKey
                });
            }
        }
        try {
            await CapacitorSQLite.createConnection({
                database: this._dbName,
                encrypted: Boolean(this._encryptedKey)

            })
        } catch (error) {
            console.error(error);
        }
        const { result: isOpen } = await CapacitorSQLite.isDBOpen({
            database: this._dbName
        })
        if (!isOpen) {

            await CapacitorSQLite.open({
                database: this._dbName
            })
        }
    }

    async execute({ query }: ExecuteOptions) {
        await this._init();
        console.log('execute NEW', query)
        return await CapacitorSQLite.execute({
            database: this._dbName,
            statements: query,
        })
    }

    async query({ query, queryValues = [] }: QueryOptions) {
        await this._init();
        console.log('query NEW', query)
        const { values } = await CapacitorSQLite.query({
            database: this._dbName,
            statement: query,
            values: queryValues
        })
        return values ?? [];
    }

    async isOk() {
        const { result } = await CapacitorSQLite.isDatabase({
            database: this._dbName
        });
        return result ?? false;
    }

    async download({ progressCallback, salesmanId }: DownloadOptions) {
        if (!this._downloadable) {
            throw new Error('This db is not downloadable');
        }
        if (this._updating) {
            console.log('SKIP')
            throw new Error('You are already downloading last db');
        }
        if (!salesmanId) {
            throw new Error('Missing salesmanId');
        }
        this._updating = true;
        const newDbFilename = `${this.PATH_DBS}/${this.DB_FILE_NAME}.new`;

        Filesystem.addListener('progress', ({ contentLength, bytes }) => {
            progressCallback({ current: bytes, total: contentLength });
        });

        try {
            const url = `${REACT_APP_GATEWAY_BASE_URL}/api/m3-data/backup/${salesmanId}`; // TODO: add correct id
            await Filesystem.downloadFile({
                url,
                path: newDbFilename,
                directory: Directory.Data,
                progress: true,
                headers: {
                    'accept-encoding': 'gzip',
                    'ngrok-skip-browser-warning': 'test'
                }
            });
            await this._finalizeDownloadDbDump(); // rename delete files
        } catch (error) {
            console.error(error);
            try {
                await Filesystem.deleteFile({
                    path: newDbFilename,
                    directory: Directory.Data
                });
            } catch (error) {
                console.error(error);
            }
            throw error;
        } finally {
            // remove listener missing on Filesystem
            this._updating = false;
        }
    }

    async getLastUpdateDbDate() {
        const directory = Directory.Data;
        try {
            const { ctime } = await Filesystem.stat({
                path: `${this.PATH_DBS}/${this.DB_FILE_NAME}`,
                directory
            });
            return ctime ? new Date(ctime) : null;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    async _finalizeDownloadDbDump() {
        const pathDb = this.PATH_DBS;
        const directory = Directory.Data;

        try {
            console.log('Rename', this.DB_FILE_NAME);
            await Filesystem.rename({
                from: `${pathDb}/${this.DB_FILE_NAME}`,
                to: `${pathDb}/${this.DB_FILE_NAME}.backup`,
                directory
            });
            console.log('Done');
        } catch (error: any) {
            console.log(error.message)
        }

        console.log('Rename', `${this.DB_FILE_NAME}.new`);
        await Filesystem.rename({
            from: `${pathDb}/${`${this.DB_FILE_NAME}.new`}`,
            to: `${pathDb}/${this.DB_FILE_NAME}`,
            directory
        });
        try {
            await CapacitorSQLite.closeConnection({
                database: this._dbName
            })
        } catch (error) {
            console.error(error);
        }
        console.log('Done');
    }

    static getCreationSqlFromDefinition(definitionObj: DefinitionTable) {
        return `CREATE TABLE IF NOT EXISTS ${definitionObj.name} (${definitionObj.columns.map(c => `${c.name} ${c.type} ${c.default ?? ''}`)})`;
    }
}

export default Database;