import { FetchError, smartFetch, smartFetchBlob } from "../utils/fetch";
import { useHistory } from "react-router";
import { AlertButton, IonicSafeString, isPlatform, useIonAlert, useIonLoading } from "@ionic/react";
import { useNetwork } from "../Context/NetworkContext";
import { useAuth } from "../Context/AuthenticationContext";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { FileOpener } from "@capacitor-community/file-opener";
import { useCompany } from "../Context/CompanyContext";
import OfflineGateway from "../utils/offline/offlineGateway";

export interface ApiParams {
    url: string;
    authenticated?: boolean;
    method?: ApiMethod;
    body?: any;
    parseBody?: boolean;
    retryAlert?: boolean;
    forceOnline?: boolean;
    skipError?: boolean;
    hideLoading?: boolean;
}

interface DownloadParams {
    url: string,
    filename: string,
    authenticated?: boolean
}

export interface BackendApi<T = undefined> {
    status: boolean;
    data?: T;
    message?: string;
}

type ApiMethod = 'GET' | 'POST' | 'DELETE' | 'UPDATE' | 'PUT';

export const API_M3_PATH = 'api/m3';
export const BACKEND_PATH = 'api/pim'
export const BACKEND_PATH_RESOURCES = '/pim/resources'
export interface IUseApi {
    callApi: (params: ApiParams) => Promise<any>;
    downloadUrl: (params: DownloadParams) => Promise<void>;
    offlineGateway: OfflineGateway;
}

const offlineGateway = new OfflineGateway();

export const useApi = (): IUseApi => {
    const { isOnline } = useNetwork();
    const [presentLoading, dismissLoading] = useIonLoading();
    const [presentAlert] = useIonAlert();
    const { isLoggedIn, loginData, regenerateToken, logout } = useAuth();
    const { replace } = useHistory();
    const { company, removeCompany } = useCompany();

    const _backButtonFn =
        (ev: any) => {
            ev.detail.register(999, () => {
                return;
            });
        };

    const callRealApi = async ({
        url,
        body,
        method = 'GET',
        parseBody = true,
        authenticated = true
    }: ApiParams): Promise<any> => {
        // Add logic to get the authentication from the authentication hook
        console.log('callRealApi');

        if (authenticated && !isLoggedIn) {
            throw new Error('The user is not logged in!') // TODO: translate
        }
        let options: RequestInit = {
            method,
            headers: {
                'Content-Type': 'application/json',
                'ngrok-skip-browser-warning': 'test' // TODO: only in develop
            },
            mode: 'cors',
            body,
        };
        // Add logic refresh token to obtain new token
        if (authenticated) {
            if (!loginData?.token) {
                throw new Error('Missing token from loginData!') // TODO: translate
            }
            let finalToken = loginData.token;
            if (loginData.jwt.exp - (Date.now() / 1000) < 60) { // Check if token is expiring (60 seconds)
                console.log('Try Regenerating token');
                try {
                    finalToken = await regenerateToken();
                } catch (error) {
                    console.log('Force logout!');
                    await logout();
                    await removeCompany();
                    replace('/login');
                    return;
                }
            }
            options = {
                ...options,
                headers: {
                    ...options.headers,
                    'Authorization': `Bearer ${finalToken}`,
                },
                credentials: 'include' // TODO: check this if necessary
            };
        }
        return await smartFetch({ url, options, parseBody });
    };

    const callFakeApi = async (apiParams: ApiParams): Promise<any> => {
        // TODO: implement fake api caller to call internal db
        return await offlineGateway.selectRoute(apiParams);
    };

    const showAlertError = async ({ error, retry }: { error: any, retry: boolean }): Promise<void> => {
        return new Promise((res) => {
            let retryButton: AlertButton[] | undefined = undefined;
            if (retry) {
                retryButton = [{
                    text: 'Retry',  // TODO: add translation
                    handler: async () => {
                        res();
                    }
                }]
            }
            presentAlert({
                // backdropDismiss: false,
                header: 'Error', // TODO: add translation
                message: error?.message,
                buttons: retryButton
            });
        });
    };

    const callApi = async (
        {
            hideLoading = false,
            retryAlert = true,
            forceOnline = false,
            skipError = false,
            url,
            ...params
        }: ApiParams): Promise<any> => {
        if (!url) {
            return;
        }
        const urlObj = new URL(url);
        try {
            if (!hideLoading) {
                document.addEventListener('ionBackButton', _backButtonFn);
                await presentLoading('Loading...');
            }
            // add global filter company
            if (company) {
                urlObj.searchParams.append('company', company);
            }
            let result;
            if (isOnline || forceOnline) {
                result = await callRealApi({ url: urlObj.toString(), ...params });
            } else {
                result = await callFakeApi({ url: urlObj.toString(), ...params });
            }
            if (!hideLoading) {
                document.removeEventListener('ionBackButton', _backButtonFn, false);
                await dismissLoading();
            }
            return result;
        } catch (error: any) {
            console.error(error);
            if (!hideLoading) {
                document.removeEventListener('ionBackButton', _backButtonFn);
                await dismissLoading();
            }
            if (error instanceof FetchError) {
                const { status } = error;
                if (status === 401 && params?.authenticated) {
                    console.log('Force logout!');
                    await logout();
                    await removeCompany();
                    replace('/login');
                    return;
                }
            }
            // Skip errors
            if (skipError) {
                return;
            }
            // Show alert
            await showAlertError({ error, retry: retryAlert });
            // Retry the call
            if (retryAlert) {
                return await callApi({ url: urlObj.toString(), ...params });
            }
        }
    };

    const downloadUrl = async ({
        url,
        filename,
        authenticated = true
    }: {
        url: string,
        filename: string,
        authenticated?: boolean
    }) => {
        if (authenticated && !isLoggedIn) {
            throw new Error('The user is not logged in!') // TODO: translate
        }

        document.addEventListener('ionBackButton', _backButtonFn);
        await presentLoading('Loading...');

        let options: any = {
            method: "GET",
            headers: {
                'ngrok-skip-browser-warning': 'test' // TODO: only in develop
            },
        };
        // Add logic refresh token to obtain new token
        if (authenticated) {
            if (!loginData?.token) {
                throw new Error('Missing token from loginData!') // TODO: translate
            }
            let finalToken = loginData.token;
            if (loginData.jwt.exp - (Date.now() / 1000) < 60) { // Check if token is expiring (60 seconds)
                console.log('Regenerating token');
                finalToken = await regenerateToken();
            }
            options = {
                ...options,
                headers: {
                    ...options.headers,
                    'Authorization': `Bearer ${finalToken}`,
                },
            };
        }

        try {
            if (isPlatform("capacitor")) {
                let permission = await Filesystem.checkPermissions()
                if (permission.publicStorage !== 'granted') {
                    permission = await Filesystem.requestPermissions();
                    if (permission.publicStorage !== "granted") {
                        throw new Error("Must grant the permission");
                    }
                }
                try {
                    await Filesystem.readFile({
                        path: `Download/${filename}`,
                        directory: Directory.ExternalStorage,
                    });
                    document.removeEventListener('ionBackButton', _backButtonFn, false);
                    await dismissLoading();

                    presentAlert({
                        header: "Attenzione", // TODO: add translation
                        message: new IonicSafeString(`Il Materiale commerciale <b>${filename}</b> è gia <b>presente</b> nella cartella download!`),
                    });
                    return;
                } catch (error) {
                    const result = await Filesystem.downloadFile({
                        url,
                        path: `Download/${filename}`,
                        headers: options.header,
                        directory: Directory.ExternalStorage,
                    });

                    if (result.path) {
                        await FileOpener.open({
                            filePath: result.path
                        });
                        document.removeEventListener('ionBackButton', _backButtonFn, false);
                        await dismissLoading();
                        presentAlert({
                            // backdropDismiss: false,
                            header: "Attenzione", // TODO: add translation
                            message: new IonicSafeString(`Il Materiale commerciale <b>${filename}</b> lo puoi trovare nella cartella Download`),
                        });
                        return
                    }
                }
            } else {
                const blob = await smartFetchBlob({ url, options });
                document.removeEventListener('ionBackButton', _backButtonFn, false);
                await dismissLoading();
                var file = window.URL.createObjectURL(blob);
                var a = document.createElement("a");
                a.href = file;
                a.download = filename ?? "";
                a.click();
                a.remove();
            }

        } catch (error) {
            console.error(error);
            document.removeEventListener('ionBackButton', _backButtonFn, false);
            await dismissLoading();
        }
    }

    return {
        callApi,
        downloadUrl,
        offlineGateway,
    };
};