import { Route, Switch, useRouteMatch } from "react-router";

import Offline from "./Offline";

const AdminMain: React.FC = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact={true} path={`${path}/offline`} component={Offline} />
        </Switch>
    );
};

export default AdminMain;