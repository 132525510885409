import { IonButton, IonButtons, IonContent, IonHeader, IonModal, IonTitle, IonToolbar } from "@ionic/react";
import { IonDatetime, IonIcon, IonItem, IonLabel } from "@ionic/react";
import * as IonIcons from "ionicons/icons";
import React, { useMemo, useRef, useState } from "react";
import './DatePicker.css';
interface IDatePicker {
  onChange?: (val: string | null) => void;
  placeholder?: string;
  presentation?: React.ComponentProps<typeof IonDatetime>["presentation"];
};

const DatePicker: React.FC<IDatePicker> = ({ onChange, placeholder = 'Data', presentation }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const datetime = useRef<null | HTMLIonDatetimeElement>(null);
  // const popover = useRef<HTMLIonPopoverElement>(null);

  const datePickerId = useMemo(() => {
    return new Date().getTime().toString();
  }, []);

  const onOpenModal = (e: any) => {
    // popover.current!.event = e;
    setIsOpen(true);
  };

  const onDidDismiss = () => {
    setIsOpen(false);
  };

  const reset = () => {
    datetime.current?.reset();
    datetime.current?.cancel(true);
    onChangeEvent();
  };
  const confirm = () => {
    datetime.current?.confirm(true);
  };


  const onChangeEvent = (date?: string) => {
    if (Array.isArray(date)) {
      return;
    }
    // Slice to remove the time part
    let currentVal = date?.slice(0, 10) ?? null;
    if (onChange) {
      onChange(currentVal);
    }
    setSelectedDate(currentVal);
  };

  return (
    <IonItem button onClick={onOpenModal} lines="none" class="date-picker">
      <IonLabel color='medium'> {selectedDate ? (
        new Date(selectedDate).toLocaleDateString('it-IT')
      ) : (
        placeholder
      )}
      </IonLabel>
      <IonModal
        isOpen={isOpen}
        onDidDismiss={onDidDismiss}
        keepContentsMounted={true}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>
              {placeholder}
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonDatetime
            value={selectedDate}
            ref={datetime}
            locale="it-IT"
            size="cover"
            showDefaultButtons={true}
            presentation={presentation || "date"}
            id={datePickerId}
            onIonChange={({ target: { value } }) => onChangeEvent(value as string)}
            style={{ height: '100%' }}
          >
            <IonButtons slot="buttons">
              <IonButton color="primary" onClick={reset}>
                Resetta
              </IonButton>
              <IonButton color="primary" onClick={confirm}>
                Conferma
              </IonButton>
            </IonButtons>
          </IonDatetime>
        </IonContent>
      </IonModal>
      <IonIcon className="date-picker-icon" icon={IonIcons.calendarOutline} color="medium" slot="end" />
    </IonItem>
  );
};

export default DatePicker;