import { IonCol, IonContent, IonGrid, IonIcon, IonInput, IonItem, IonRow } from "@ionic/react";
import { ColumnDef, ColumnSort, VisibilityState, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { useEffect, useMemo, useRef, useState } from "react";
import { BasicProduct, useSalesman } from "../../../hooks/useSalesman";
import { ellipse, searchOutline } from "ionicons/icons";
import { convertBaseCurrency, convertNumberToLocale } from "../../../utils/newOrderHelpers";
import CustomTable from "../../../components/Table/CustomTable";
import GruppoMerceologicoSelect from "../../../components/GruppoMerceologicoSelect/GruppoMerceologicoSelect";

const ListinoBase: React.FC = () => {
  const { getBasicProductList } = useSalesman();
  const initData = useRef(false);
  const [allProducts, setAllProducts] = useState<BasicProduct[]>([]);

  const [gruppoMerceologicoSelected, setGruppoMerceologicoSelected] = useState<string>();

  const [sorting, setSorting] = useState<ColumnSort[]>([{ id: 'modificaId', desc: true }]);


  const [globalFilter, setGlobalFilter] = useState("");
  // Define columns for the table
  const columns = useMemo<ColumnDef<BasicProduct, any>[]>(
    () => [
      {
        accessorKey: 'itemId',
        header: 'Codice',
        enableGlobalFilter: false,
      },
      {
        accessorKey: 'articoloDes',
        header: 'Denominazione',
        meta: {
          mobileHeader: true,
          mobileHeaderPosition: 1
        }
      },
      {
        id: 'gruppoMerceologicoId',
        accessorKey: 'gruppoMerceologico',
        header: 'Gruppo Merceologico',
        enableGlobalFilter: false,
      },
      {
        accessorKey: 'listino',
        header: 'Listino',
        enableSorting: false,
        enableGlobalFilter: false,
      },
      {
        id: 'availabilityId',
        header: 'Disp.',
        accessorKey: 'disponibilita',
        cell: ({ getValue }) => {
          // Get value disponibilità
          const disponibilita = getValue();
          return (
            <IonIcon size='large' icon={ellipse} color={Boolean(disponibilita) ? 'success' : 'danger'}></IonIcon >
          )
        },
        enableSorting: false,
        enableGlobalFilter: false,
        meta: {
          mobileHeader: true,
          mobileHeaderPosition: 0
        }
      },
      {
        accessorFn: (row) => `${convertBaseCurrency(row.valuta)} ${convertNumberToLocale(row.price)}`,
        header: 'Prezzo',
        enableGlobalFilter: false,
      },
      {
        accessorKey: 'pzConf',
        header: 'Pz conf',
        enableSorting: false,
      }, {
        id: 'modificaId',
        accessorFn: (row) => {
          return new Date(row.timeStamp).toLocaleDateString('it-IT')
        },
        header: 'Modifica'
      }
    ],
    []
  );

  const table = useReactTable({
    data: allProducts, // Define data source
    columns, // Define all column props
    state: {
      globalFilter,
      sorting
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(), // Used to enable pagination
    getFilteredRowModel: getFilteredRowModel(), // Used to enable filtering
    getSortedRowModel: getSortedRowModel(), // Enable sorting
    onGlobalFilterChange: setGlobalFilter, // Used to enable global filter
    onSortingChange: setSorting,
    autoResetPageIndex: true,
    //  debugTable: true, // TODO: remove in production
  });

  useEffect(() => {
    if (initData.current) {
      return;
    }
    initData.current = true;
    (async () => {
      setAllProducts(await getBasicProductList());
    })()
  }, [])

  // filter the table by gruppoMerceologicoSelected
  useEffect(() => {
    const column = table.getColumn('gruppoMerceologicoId');
    column?.setFilterValue(gruppoMerceologicoSelected);
  }, [gruppoMerceologicoSelected])

  return (
    <IonContent>
      <IonGrid class='full-flex-column'>
        <IonRow className="ion-margin-bottom ion-align-items-center">
          <IonCol>
            <h4><strong>LISTINO BASE</strong></h4>
            {/* <IonText color="primary">
              <h1>SELEZIONA PRODOTTI</h1>
            </IonText> */}
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12" sizeMd='8'>
            <IonItem>
              <IonInput
                value={globalFilter ?? ''}
                onIonInput={({ detail: { value } }) => setGlobalFilter(value as string)}
                placeholder="RICERCA DENOMINAZIONE"
              />
              <IonIcon icon={searchOutline} slot='end'></IonIcon>
            </IonItem>
          </IonCol>

          <IonCol size="12" sizeMd='4'>
            <IonItem>
              <GruppoMerceologicoSelect products={allProducts} onGruppoMerceologicoSelected={setGruppoMerceologicoSelected}></GruppoMerceologicoSelect>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <CustomTable table={table}></CustomTable>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default ListinoBase;