import { Route, Switch, useRouteMatch } from "react-router";

import SchedeCliente from "./SchedeCliente/SchedeCliente";
import SchedeClienteDetail from "./SchedeCliente/SchedeClienteDetail";
import MaterialiCommerciali from "./MaterialiCommerciali/MaterialiCommerciali";

const AdminMain: React.FC = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact={true} path={`${path}/schede-cliente`} component={SchedeCliente} />
            <Route path={`${path}/schede-cliente/detail`} component={SchedeClienteDetail} />
            <Route path={`${path}/materiali-commerciali`} component={MaterialiCommerciali} />
        </Switch>
    );
};

export default AdminMain;