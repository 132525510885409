import { Network } from "@capacitor/network";
import type { PropsWithChildren } from "react";
import { createContext, useContext, useEffect, useState } from "react";

export interface NetworkContext {
    isOnline: boolean;
    currentStatus: NetworkStatus;
    forceNetworkStatus: (forceStatus: NetworkStatus) => void;
}

export enum NetworkStatus {
    'OFFLINE', 'REAL'
}

const NetworkContext = createContext<NetworkContext | undefined>(undefined);

export const useNetwork = (): NetworkContext => {
    const context = useContext(NetworkContext);
    if (!context) throw new Error('No NetworkProvider in context');
    return context;
};

export const NetworkProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [init, setInit] = useState(false);
    const [isOnline, setIsOnline] = useState(false);
    const [realStatus, setRealStatus] = useState(false);
    const [forcedStatus, setForcedStatus] = useState<NetworkStatus>(NetworkStatus.REAL);

    useEffect(() => {
        (async () => {
            const { connected } = await Network.getStatus();
            setRealStatus(connected);
            setInit(true);
        })();
    }, []);

    useEffect(() => {
        if (forcedStatus === NetworkStatus.OFFLINE) {
            setIsOnline(false);
        } else {
            setIsOnline(realStatus);
        }
    }, [realStatus, forcedStatus]);

    Network.addListener('networkStatusChange', ({ connected }) => {
        setRealStatus(connected);
    });

    const forceNetworkStatus = (forceStatus: NetworkStatus) => {
        setForcedStatus(forceStatus);
    };

    const context: NetworkContext = { isOnline, forceNetworkStatus, currentStatus: forcedStatus };

    // TODO: show loading screen?
    return (
        <NetworkContext.Provider value={context}>{init ? children : null}</NetworkContext.Provider>
    );
};