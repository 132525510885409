import mockKnex from './mock/index.js';

// Use real Knex.js in nodejs
let knex = mockKnex;
if (typeof window !== 'object') {
    console.log('REAL KNEX');
    const { default: realKnex } = await import('knex');
    knex = realKnex;
};

import Salesmen from './salesmen.js';
import Customers from './customers.js';
import Companies from './companies.js';
import Helper from './helper.js';

class M3 {
    constructor(knexConfig) {
        console.log(knex);
        this._knexInstance = knex(knexConfig);
        const onlyQuery = knexConfig.connection === null || knexConfig.connection === undefined;
        const config = {
            knex: this._knexInstance,
            onlyQuery,
        };
        this.salesmen = new Salesmen(config);
        this.customers = new Customers(config);
        this.companies = new Companies(config);
    }
}


export default M3;
export { Helper };