import { useEffect, useMemo, useRef, useState } from "react";
import CustomTable, { dateFilterFn } from "./CustomTable"
import { ColumnDef, ColumnSort, Row, VisibilityState, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";

import { convertIrcaDate } from "../../utils/helpers";
import { Invoice, InvoiceDetail, useCustomer } from "../../hooks/useCustomer";
import { convertBaseCurrency, convertNumberToLocale } from "../../utils/newOrderHelpers";
import { IonChip, useIonModal } from "@ionic/react";
import ModalDetailTable from "../../pages/Materiali/SchedeCliente/ModalDetailTable";

interface InvoicesTableI {
    customerId: string;
}

const InvoicesTable: React.FC<InvoicesTableI> = ({ customerId }) => {
    const initData = useRef(false);
    const [invoices, setInvoices] = useState<any[]>([]);
    const [sorting, setSorting] = useState<ColumnSort[]>([/* { id: 'favorite', desc: true } */]);
    const { getAllInvoices, getInvoiceDetail } = useCustomer();
    // state for the modal
    const [detailData, setDetailData] = useState<any>();
    const [detailTitle, setDetailTitle] = useState<string>();
    const columnsDetail = useMemo<ColumnDef<InvoiceDetail, any>[]>(
        () => [
            {
                header: 'Descrizione',
                accessorKey: 'desItem',
                meta: {
                    mobileHeader: true
                }
            },
            {
                header: 'Sconto',
                accessorKey: 'discount1'
            },
            {
                header: 'Data',
                accessorFn: (row) => {
                    return convertIrcaDate(row.invoiceDate);
                },
                cell: ({ getValue }) => {
                    const value: any = getValue();
                    return value ? value.toLocaleDateString('it-IT') : '---'; // TODO: add correct locale from app
                },
                filterFn: dateFilterFn
            },
            {
                header: 'N. Prodotto',
                accessorKey: 'itemId'
            },
            {
                header: 'Quantità',
                accessorKey: 'quantity'
            },
            {
                header: 'Uom',
                accessorKey: 'uom'
            },
            {
                header: 'Prezzo',
                accessorKey: 'valueRow',
                cell: ({ getValue }) => {
                    const value: any = getValue();
                    return `${convertBaseCurrency('EUR')} ${convertNumberToLocale(value as number)}`
                }
            },
        ],
        []
    );
    const [presentModal, dismissModal] = useIonModal(ModalDetailTable, {
        onDismiss: () => {
            setDetailTitle(undefined);
            setDetailData(undefined);
            dismissModal();
        },
        detailColumnDefinition: columnsDetail,
        detailData,
        title: detailTitle,
    });

    // Define columns for the table
    const columns = useMemo<ColumnDef<Invoice, any>[]>(
        () => [
            {
                accessorKey: 'invoiceNr',
                header: 'N. Fattura',
                meta: {
                    mobileHeader: true
                }
            },
            {
                accessorKey: 'invoiceDate',
                header: 'Data',
                accessorFn: (row) => {
                    return convertIrcaDate(row.invoiceDate);
                },
                cell: ({ getValue }) => {
                    const value = getValue();
                    return value ? value.toLocaleDateString('it-IT') : '---'; // TODO: add correct locale from app
                },
                filterFn: dateFilterFn
            },
            {
                accessorFn: (row) => {
                    return convertNumberToLocale(row.importo)
                },
                header: 'Importo',
            },
            {
                accessorKey: 'ftAperta',
                header: 'Stato',
                cell: ({ getValue }) => {
                    const value = getValue();
                    return (
                        <IonChip style={{
                            "--color": value ? "var(--ion-color-success)" : "var(--ion-color-danger)",
                            "--background": value ? "rgba(var(--ion-color-success-rgb), 0.08)" : "rgba(var(--ion-color-danger-rgb), 0.08)"
                        }} >{value ? 'Aperta' : 'Chiusa'}</IonChip>
                    )
                },
            },
            {
                accessorKey: 'company',
                header: 'Company',
                enableHiding: true
            },
        ],
        []
    );

    // Define column visibility
    const [columnVisibility] = useState<VisibilityState>({
        company: false,
    })

    const table = useReactTable({
        data: invoices, // Define data source
        columns, // Define all column props
        state: {
            columnVisibility,
            sorting,
        },
        onSortingChange: setSorting,
        // onGlobalFilterChange: setGlobalFilter, // Used to enable global filter
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(), // Used to enable pagination
        getFilteredRowModel: getFilteredRowModel(), // Used to enable filtering
        getSortedRowModel: getSortedRowModel(), // Enable sorting
        //  debugTable: true, // TODO: remove in production
    });

    /**
     * Use Effect 
     */
    useEffect(() => {
        if (initData.current) {
            return;
        }
        initData.current = true;
        (async () => {
            setInvoices(await getAllInvoices({ customerId }))
        })()
    }, [])

    const _onRowClicked = async (row: Row<Invoice>) => {
        const invoiceSelected = row.original;
        const result = await getInvoiceDetail({
            customerId,
            invoiceId: invoiceSelected.invoiceNr
        })
        // set data for the modal
        setDetailTitle(`Dettaglio fattura ${invoiceSelected.invoiceNr}`);
        setDetailData(result);

        presentModal({
            id: 'detail-customer-modal'
        });
    }

    return (
        <CustomTable table={table} clickRowCallback={_onRowClicked} totalLabel="Fatture totali: "></CustomTable>
    )
}

export default InvoicesTable;