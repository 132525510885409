import { IonButton } from "@ionic/react"
import { useDispatch, useSelector } from "react-redux";
import { convertToDraftOrder } from "../../../utils/newOrderHelpers"
import { OrderState, loadOrder } from "../../../redux/order/order"
import { useOrdersDraft } from "../../../hooks/useDraftOrders"
import { ReduxState } from "../../../redux/store";

interface SaveDraftI { }
const SaveDraft: React.FC<SaveDraftI> = ({ }) => {
    const orderState: OrderState = useSelector((state: ReduxState) => state.order);
    const dispatch = useDispatch();
    const { saveOrderDraft } = useOrdersDraft();

    const _saveDraft = async () => {
        console.log('orderState', orderState);
        const draft = convertToDraftOrder({ orderState });
        const result = await saveOrderDraft(draft);
        const id = result?.data?.id;
        console.log(`Saving Draft: ${id}`)
        if (!id) throw new Error(`Invalid new draft id "${id}"`);
        dispatch(loadOrder({
            ...orderState,
            id,
        }));
    }

    return (
        <IonButton fill='clear' onClick={_saveDraft}>Salva in bozze</IonButton>
    )
}

export default SaveDraft;