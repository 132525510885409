import { IonContent, IonGrid, IonIcon, IonText, IonRow, IonCol, IonButton } from '@ionic/react';
import * as IonIcons from "ionicons/icons";
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { clearOrder } from '../../../redux/order/order';
import { useDispatch } from 'react-redux';


const OrdineInviato: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(clearOrder());
    }, [])

    const _onClick = () => {
        // go to the homepage
        history.replace('/');
    }

    return (
        <IonContent>
            <IonGrid style={{ height: "calc(100% - 76px)" }}>
                <IonRow>
                    <IonText color="primary">
                        <h1>ORDINE INVIATO</h1>
                    </IonText>
                </IonRow>

                <IonRow class="ion-justify-content-around ion-align-items-center" style={{ height: "100%" }}>
                    <IonCol class="ion-text-center">
                        <IonIcon style={{ height: '60px', width: '60px' }} icon={IonIcons.checkmarkCircle} color="success" />
                        <IonText><h1>L'ORDINE È STATO INVIATO CORRETTAMENTE</h1></IonText>
                        <IonText>Il tuo ordine è spedito <b>correttamente</b></IonText>
                        <IonRow class='ion-justify-content-center ion-margin-top'>
                            <IonButton shape='round' fill='outline' onClick={_onClick}>Torna in Homepage</IonButton>
                        </IonRow>
                    </IonCol>
                </IonRow>
                <IonRow>
                </IonRow>

            </IonGrid>
        </IonContent>
    );
};

export default OrdineInviato;