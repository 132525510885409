import { useEffect, useMemo, useRef, useState } from "react";
import CustomTable, { useSkipper } from "./CustomTable"
import { ColumnDef, ColumnSort, Row, VisibilityState, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { Customer, CustomerWithFavorite, useNewOrder } from "../../hooks/useNewOrder";
import FavoriteButton from "../FavoriteButton/FavoriteButton";
import { useSalesman } from "../../hooks/useSalesman";
import { mergeCustomerFavorites } from "../../utils/helpers";

interface CustomersTableI {
    onRowClicked: (customer: Customer) => void,
    globalFilter: string;
    setGlobalFilter: React.Dispatch<React.SetStateAction<string>>;
    onlyEnableToOrder?: boolean;
}

const CustomersTable: React.FC<CustomersTableI> = ({ onRowClicked, globalFilter, setGlobalFilter, onlyEnableToOrder = false }) => {
    const initData = useRef(false);
    const [customers, setCustomers] = useState<CustomerWithFavorite[]>([]);
    const { getFavorites, saveFavorites } = useSalesman();
    const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();
    const [sorting, setSorting] = useState<ColumnSort[]>([{ id: 'favorite', desc: true }]);
    const { getSalesmanCustomers } = useNewOrder();

    // Define columns for the table
    const columns = useMemo<ColumnDef<CustomerWithFavorite, any>[]>(
        () => [
            {
                accessorKey: 'customerId',
                enableHiding: true, // enable hiding
            },
            {
                accessorKey: 'description',
                header: 'Nome Cliente',
                meta: {
                    mobileHeader: true
                }
            },
            {
                accessorKey: 'address',
                header: 'Indirizzo',
                enableSorting: false,
            },
            {
                accessorKey: 'city',
                header: 'Località',
            },
            {
                accessorKey: 'zipCode',
                header: 'CAP',
            },
            {
                accessorKey: 'vatNumber',
                header: 'Partita iva',
                enableSorting: false,
            },
            {
                id: 'phoneNumber',
                accessorFn: (row) => `${row.phone1} ${row.phone2}`, // compose data with both phone field
                enableHiding: true, // enable hiding
            },
            {
                header: 'Preferiti',
                accessorKey: "favorite",
                cell: ({ row, column: { id }, getValue, table }) => (
                    <FavoriteButton checked={getValue()} onClick={(newValue) => {
                        table.options.meta?.updateData(
                            row.index, id, newValue
                        )
                    }}></FavoriteButton>
                )
            },
            {
                id: 'shipToId',
                accessorFn: (row) => row.shipTo?.join(' '),
                enableHiding: true,
            }
        ],
        []
    );

    // Define column visibility
    const [columnVisibility] = useState<VisibilityState>({
        phoneNumber: false,
        customerId: false,
        shipToId: false,
    })

    const table = useReactTable({
        data: customers, // Define data source
        columns, // Define all column props
        state: {
            columnVisibility,
            sorting,
            globalFilter // Used to enable global filter
        },
        meta: {
            updateData: async (rowIndex, columnId, value) => {
                const isSorted = sorting.find(column => column.id === columnId);
                if (!isSorted) {
                    // Skip page index reset until after next rerender
                    skipAutoResetPageIndex();
                }
                // Update the data state
                const newData = customers.map((row, index) => {
                    if (index === rowIndex) {
                        return {
                            ...customers[rowIndex]!,
                            [columnId]: value,
                        };
                    }
                    return row;
                })
                // Get new fav array
                const newFav = newData.filter(customer => customer.favorite).map(customer => customer.customerId);
                // Call async api to update
                const newFavFromApi = await saveFavorites(newFav);
                if (newFav.toString() === newFavFromApi.toString()) {
                    setCustomers(newData);
                } else {
                    setCustomers(mergeCustomerFavorites(customers, newFavFromApi));
                }

            },
        },
        onSortingChange: setSorting,
        onGlobalFilterChange: setGlobalFilter, // Used to enable global filter
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(), // Used to enable pagination
        getFilteredRowModel: getFilteredRowModel(), // Used to enable filtering
        getSortedRowModel: getSortedRowModel(), // Enable sorting
        autoResetPageIndex,
        //  debugTable: true, // TODO: remove in production
    });

    /**
     * Use Effect 
     */
    useEffect(() => {
        if (initData.current) {
            return;
        }
        initData.current = true;
        (async () => {
            const favorites = await getFavorites();
            const customers = await getSalesmanCustomers(onlyEnableToOrder);

            setCustomers(mergeCustomerFavorites(customers, favorites));
        })()
    }, [])

    const _onRowClicked = (row: Row<Customer>) => {
        // Get the id of the customer
        const customerId: string = row.getValue('customerId');
        // Get the custom from the list
        const customer = customers.find((customer) => customer.customerId === customerId);
        if (!customer) {
            throw new Error('Missing customer');
        }
        onRowClicked(customer);
    }

    return (
        <CustomTable table={table} clickRowCallback={_onRowClicked} totalLabel="Clienti totali: "></CustomTable>
    )
}

export default CustomersTable;