import { Capacitor } from "@capacitor/core";
import { Customer } from "../hooks/useNewOrder";
import { App, AppInfo } from '@capacitor/app';

export const pause = async (milliseconds: number): Promise<void> => {
    return await new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export const convertIrcaDate = (ircaDate: string) => {
    if (!ircaDate) {
        return null;
    }
    if (ircaDate.length !== 8) {
        throw new Error('Invalid ircaDate param!');
    }
    const year = parseInt(ircaDate.substring(0, 4));
    const month = parseInt(ircaDate.substring(4, 6));
    const day = parseInt(ircaDate.substring(6, 8));
    return new Date(`${month}/${day}/${year}`);
};

export const convertIrcaDateTimeStamp = (ircaDateTimeStamp: number) => {
    if (!ircaDateTimeStamp || ircaDateTimeStamp < 1) {
        return null;
    }
    return new Date(ircaDateTimeStamp * 1000);
}

export const mergeCustomerFavorites = (customers: Customer[], favorites: string[]) => {
    return customers.map(customer => ({ ...customer, favorite: favorites.includes(customer.customerId) }))
}

export default function formatNumber(num: number, precision = 2) {
    const map = [
        { suffix: 'T', threshold: 1e12 },
        { suffix: 'B', threshold: 1e9 },
        { suffix: 'M', threshold: 1e6 },
        { suffix: 'K', threshold: 1e3 },
        { suffix: '', threshold: 1 },
    ];

    const found = map.find((x) => Math.abs(num) >= x.threshold);
    if (found) {
        const formatted = (num / found.threshold).toFixed(precision) + found.suffix;
        return formatted;
    }

    return num;
}

export const getAppInfo = async (): Promise<AppInfo> => {
    if (!Capacitor.isNativePlatform()) {
        return { build: '9999', version: 'Web', name: '', id: '' };
    }
    return await App.getInfo();
}

export const getLastAppVersion = async () => {
    try {
        const response = await fetch('https://hibosrl.gitlab.io/irca/irca-distribuzione/irca-distribuzione-app/last-version')
        const version: string = await response.text();
        return version.trim(); // remove newline at the end
    } catch (error) {
        return null;
    }
}