import { PropsWithChildren, useEffect, useRef } from 'react';
import React, { createContext, useContext, useState } from 'react';
import jwt_decode from "jwt-decode";
import { isPlatform, useIonLoading } from '@ionic/react';
import { FetchError, smartFetch } from '../utils/fetch';
import { useEnv } from './EnvContext';
import Cookie from 'universal-cookie';
import { mainDatabase } from '../App';
import Database, { DefinitionTable } from '../utils/database';

export interface AuthContext {
    login: (data: ILogin) => Promise<void>;
    logout: () => Promise<void>;
    isLoggedIn: boolean;
    loginData: LoginData | null | undefined;
    regenerateToken: () => Promise<string>;
}
export interface LoginData {
    token: string;
    jwt: IJwt;
    refreshToken: string;
    refreshTokenExpiration: number;
}

interface IJwt {
    exp: number;
    iat: number;
    roles: string[];
    username: string;
    erpId: string;
    managedSalesmen: string[];
    fullName: string;
    email: string;
    profilePicture?: string;
    companies: string[];
}

export interface ILogin {
    username: string;
    password: string;
}

const AuthContext = createContext<AuthContext | undefined>(undefined);

const tableAuth: DefinitionTable = {
    name: 'auth',
    columns: [
        {
            name: 'token',
            type: 'TEXT',
            default: 'NULL'
        },
        {
            name: 'jwt',
            type: 'TEXT',
            default: 'NULL'
        },
        {
            name: 'refreshToken',
            type: 'TEXT',
            default: 'NULL'
        },
        {
            name: 'refreshTokenExpiration',
            type: 'NUMERIC',
            default: 'NULL'
        }
    ],
}

export function useAuth(): AuthContext {
    const context = useContext(AuthContext);
    if (!context) throw new Error('No AuthProvider in context');
    return context;
}

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [init, setInit] = useState(false);
    const [isLoggedIn, setLoggedIn] = useState<boolean>(false);
    const [loginData, setLoginData] = useState<LoginData | null>();
    const [presentLoading, dismissLoading] = useIonLoading();
    const { getEnv } = useEnv();


    const _saveLoginData = async (loginData: LoginData) => {
        // Use sqlite db
        if (isPlatform('capacitor')) {
            await _saveLoginIntoDb(loginData);
        } else { // Use cookie
            const cookie = new Cookie();
            cookie.set('auth', JSON.stringify(loginData), {
                secure: true,
                sameSite: 'lax',
                path: '/'
            })
        }
        setLoginData(loginData);
    }

    const _getLoginData = async () => {
        let loginDataDb: LoginData | null = null;
        // Get saved data from sqlite
        if (isPlatform('capacitor')) {
            loginDataDb = await _recoverLoginFromDb();
        } else { // get From cookie
            const cookie = new Cookie();
            loginDataDb = cookie.get('auth');
        }
        if (!loginDataDb) {
            setLoginData(null);
            setLoggedIn(false);
            return;
        }
        setLoginData(loginDataDb);
        setLoggedIn(true);

    }

    const _saveLoginIntoDb = async (loginData: LoginData) => {
        await mainDatabase.execute({ query: `DELETE FROM ${tableAuth.name}` });
        await mainDatabase.query({
            query: `INSERT INTO ${tableAuth.name} (${tableAuth.columns.map(c => `${c.name}`).join(',')}) VALUES (${tableAuth.columns.map((c) => '?').join(',')})`,
            queryValues: [loginData.token, JSON.stringify(loginData.jwt), loginData.refreshToken, loginData.refreshTokenExpiration]
        });
    }

    const _recoverLoginFromDb = async () => {
        await mainDatabase.execute({ query: Database.getCreationSqlFromDefinition(tableAuth) });
        const [loginDataDb] = await mainDatabase.query({ query: `SELECT * FROM ${tableAuth.name}` }) ?? []
        if (!loginDataDb) {
            return;
        }
        return { ...loginDataDb, jwt: JSON.parse(loginDataDb.jwt) };
    }

    const _cleanTable = async () => {
        await mainDatabase.execute({ query: `DELETE FROM ${tableAuth.name}` });
    }

    useEffect(() => {
        (async () => {
            await _getLoginData();
            setInit(true);
        })();
    }, []);

    const login = async ({ username, password }: ILogin) => {
        const gatewayBase = getEnv('GATEWAY_BASE_URL');
        try {
            await presentLoading('Loading...');
            const {
                token,
                refresh_token: refreshToken,
                refresh_token_expiration: refreshTokenExpiration
            } = await smartFetch({
                url: `${gatewayBase}/auth/login_check`,
                options: {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        username,
                        password
                    })
                }
            });

            // Prepare loginData
            const jwtDecoded: IJwt = jwt_decode(token);
            const loginDataTmp = { token, refreshToken, refreshTokenExpiration, jwt: jwtDecoded };
            // Save loginData
            await _saveLoginData(loginDataTmp);
            await dismissLoading();
            setLoggedIn(true);
        } catch (error: any) {
            await dismissLoading();
            if (error instanceof FetchError) {
                if (error.status === 401) {
                    throw new Error('Wrong username password combination!');
                }
            }
            throw error;
        }
    };

    const regenerateToken = async () => {
        if (!loginData) {
            throw new Error('Missing loginData');
        }
        const gatewayBase = getEnv('GATEWAY_BASE_URL');
        const {
            token,
            refresh_token: refreshToken,
            refresh_token_expiration: refreshTokenExpiration
        } = await smartFetch({
            url: `${gatewayBase}/auth/token/refresh`,
            options: {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    'refresh_token': loginData.refreshToken
                })
            }
        });
        const jwtDecoded: IJwt = jwt_decode(token);
        const loginDataTmp: LoginData = { token, refreshToken, refreshTokenExpiration, jwt: jwtDecoded };
        await _saveLoginData(loginDataTmp);
        return loginDataTmp.token;
    }


    const logout = async () => {
        // Remove from sqlite
        if (isPlatform('capacitor')) {
            await _cleanTable();
        } else { // Remove from cookie
            const cookie = new Cookie();
            cookie.remove('auth', { path: '/' });
        }
        setLoginData(null);
        setLoggedIn(false);
    };

    const context: AuthContext = {
        login,
        logout,
        isLoggedIn,
        loginData,
        regenerateToken
    };

    // TODO: maybe rendere a Loading page with the logo?
    return (
        <AuthContext.Provider value={context}>{init ? children : null}</AuthContext.Provider>
    );
};
