import { IonContent, IonGrid, IonFooter, IonRow, IonCol, IonButton, IonDatetime, IonText, IonToolbar, IonButtons } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';

import NuovoOrdineTabs from "./NuovoOrdineTabs";
import { useDispatch, useSelector } from 'react-redux';
import { OrderState, addDeliveryDate, removeDeliveryAddress, removeDeliveryDate } from '../../../redux/order/order';
import './DataConsegna.css';
import { useHistory } from 'react-router';
import NavigationFooter from '../../../components/Navigation-Footer/NavigationFooter';
import SaveDraft from './SaveDraft';
import { useMobileMediaQuery } from '../../../hooks/useAppMediaQuery';
import { DraftOrderState } from '../../../redux/draftOrder/draftOrder';
import { useNewOrder } from '../../../hooks/useNewOrder';
import { ReduxState } from '../../../redux/store';
import { useCheckStepOrRedirect } from '.';

const DataConsegna: React.FC = () => {
  const init = useRef(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const orderState: OrderState = useSelector((state: ReduxState) => state.order);
  const { draftOrder }: DraftOrderState = useSelector((state: ReduxState) => state.draftOrder);
  const [date, setDate] = useState<string>();
  const { convertDeliveryDate } = useNewOrder();
  const isMobile = useMobileMediaQuery();

  useCheckStepOrRedirect()({ orderState });

  // Function to disable date previous today
  const _correctDate = (dateString: string) => {
    const date = new Date(dateString);

    const today = new Date(new Date().toDateString());
    return date >= today;
  }

  useEffect(() => {
    if (init.current) {
      return;
    }
    init.current = true;
    let selectedDate: string | null = null;
    if (orderState.deliveryDate) {
      selectedDate = orderState.deliveryDate;
    }
    if (draftOrder?.deliveryDate) {
      selectedDate = convertDeliveryDate(draftOrder.deliveryDate);
    }
    if (selectedDate) {
      setDate(selectedDate);
    }
  }, [])

  useEffect(() => {
    if (!date) {
      return
    }
    dispatch(addDeliveryDate(date))
  }, [date])

  const _dateSelected = (dateString: string) => {
    setDate(dateString);
  }

  const onClickIndietro = () => {
    // dispatch(removeDeliveryDate());
    history.replace('indirizzo-consegna');
  };

  const onClickContinua = () => {
    if (!date) {
      return;
    }
    history.replace('riepilogo-note');
  };

  return (
    <>
      <IonContent>
        <IonGrid>
          {isMobile && (
            <IonRow class='ion-justify-content-end'>
              <SaveDraft />
            </IonRow>
          )}
          <IonRow className="ion-align-items-center">
            <IonCol>
              <h4><strong>NUOVO ORDINE</strong></h4>
              <IonText color="primary">
                <h1>SELEZIONA DATA DI CONSEGNA</h1>
              </IonText>
            </IonCol>
            {!isMobile && (<SaveDraft />)}
          </IonRow>
          <div>
            <NuovoOrdineTabs />
          </div>
          <IonRow class="ion-justify-content-center">
            <IonCol>
              <IonDatetime locale="it-IT" value={date} presentation="date" isDateEnabled={_correctDate} onIonChange={({ detail: { value } }) => _dateSelected(value as string)}></IonDatetime>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>

      <NavigationFooter
        onBack={onClickIndietro}
        onContinue={onClickContinua}
        disableContinue={!date} />
    </>
  );
};

export default DataConsegna;