import { IonButton, IonInput, IonItem, IonList, IonRow, IonText, useIonAlert } from "@ionic/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { useAuth } from "../../Context/AuthenticationContext";
import './Login.css';
import { useMobileMediaQuery } from "../../hooks/useAppMediaQuery";
import VersionChecker from "../../components/VersionChecker/VersionChecker";

const Login: React.FC = () => {
    const { isLoggedIn, login } = useAuth();
    const { replace } = useHistory();
    const [username, setUsername] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [present] = useIonAlert();
    const isMobile = useMobileMediaQuery();

    useEffect(() => {
        isLoggedIn && replace('/');
    }, [isLoggedIn]);

    const loginAction = async () => {
        if (!username || !password) {
            return;
        }
        try {
            await login({ username, password });
        } catch (error: any) {
            present({
                header: 'Error',
                message: error.message
            });
        }
    };

    // TODO: aggiungere traduzioni?
    return (
        <IonRow class="main">
            <VersionChecker />
            <div className="login-container">
                <IonText>
                    {isMobile ? (
                        <h2>ACCEDI AL GESTIONALE DI IRCA</h2>
                    ) : (
                        <h1>ACCEDI AL GESTIONALE DI IRCA</h1>
                    )}
                </IonText>
                <IonText>
                    <p>USERNAME</p>
                </IonText>
                <IonItem>
                    <IonInput
                        placeholder="Inserisci il tuo username"
                        onIonInput={(e) => setUsername(e.detail.value as string)}
                    />
                </IonItem>
                <IonText>
                    <p>PASSWORD</p>
                </IonText>
                <IonItem>
                    <IonInput
                        type='password'
                        placeholder="Inserisci la tua password"
                        onIonInput={(e) => setPassword(e.detail.value as string)}
                    />
                </IonItem>


                <IonButton size="large" shape="round" disabled={!username || !password} onClick={loginAction}>Accedi</IonButton>
            </div>
        </IonRow>
    );
};

export default Login;