import { IonContent, IonGrid, IonItem, IonLabel, IonRow, IonText } from "@ionic/react"
import { useMobileMediaQuery } from "../../hooks/useAppMediaQuery";
import { useCompany } from "../../Context/CompanyContext";
import { useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router";
import "./Company.css"
import { useDispatch } from "react-redux";
import { disableMenu } from "../../redux/menu/menu";

const Company: React.FC = () => {
    const init = useRef(false);
    const isMobile = useMobileMediaQuery();
    const { state } = useLocation<{ modify: boolean }>();
    const { selectableCompanies, selectCompany, company } = useCompany();
    const { replace } = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(disableMenu(true));
        return () => {
            dispatch(disableMenu(false));
        }
    }, []);

    useEffect(() => {
        if (selectableCompanies.length === 0) {
            return;
        }
        if (init.current) {
            return;
        }
        init.current = true;
        if (selectableCompanies.length === 1 && !company) {
            selectCompany(selectableCompanies[0]);
            replace('/');
            return;
        }
    }, [selectableCompanies])

    useEffect(() => {
        if (company && !state?.modify) {
            replace('/');
            return;
        }
    }, [company]);

    const _onClick = (company: string) => {
        selectCompany(company);
        replace('/');
        return;
    }


    return (
        <IonContent>
            <IonRow class="main">
                <div className="company-container">
                    <IonText>
                        {isMobile ? (
                            <h2>{company ? 'MODIFICA' : 'SCEGLI UNA'} COMPANY</h2>
                        ) : (
                            <h1>{company ? 'MODIFICA' : 'SCEGLI UNA'} COMPANY</h1>
                        )}
                    </IonText>
                    {selectableCompanies.map((selectableCompany, index) => (
                        <IonItem key={index} button color='white' lines={company === selectableCompany ? 'full' : 'none'} onClick={() => _onClick(selectableCompany)}>
                            <IonLabel>
                                {company === selectableCompany ? (
                                    <h2>{selectableCompany}</h2>
                                ) : (
                                    selectableCompany
                                )}
                            </IonLabel>
                        </IonItem>
                    ))}
                </div>
            </IonRow>
        </IonContent>
    )
}
export default Company;