import { IonIcon, IonInput, IonRow } from "@ionic/react"
import { closeCircle } from "ionicons/icons";

import './InputTable.css'

interface InputTableI {
    max?: number;
    min?: number;
    value: number;
    disabled?: boolean;
    onChange: (value: number) => void;
    cancelValue?: number,
    decimals?: number,
}

const InputTable: React.FC<InputTableI> = ({ min, max, disabled = false, value, onChange, cancelValue = 0, decimals }) => {
    const _checkValue = (e: any) => {
        const { currentTarget } = e;
        if (currentTarget.value != "") {
            const min = parseInt(currentTarget.min);
            const max = parseInt(currentTarget.max);
            const value = parseInt(currentTarget.value);
            if (!isNaN(min) && value < min) {
                currentTarget.value = currentTarget.min;
            }
            if (!isNaN(max) && value > max) {
                currentTarget.value = currentTarget.max;
            }
            const valueString = currentTarget.value as string
            onChange((decimals && decimals > 0) ? parseFloat(valueString) : parseInt(valueString))
        }
    }
    const _onClick = () => {
        // Reset to default value
        onChange(cancelValue)
    }

    const _calculateStep = (): string => {
        if (!decimals || decimals === 0) {
            return "1"
        }
        return "any"
    }
    return (
        <IonRow class="input-table">
            <IonInput
                type="number"
                defaultValue={min}
                min={min}
                max={max}
                disabled={disabled} // disable input if availability is null or 0
                value={value}
                onIonChange={(event) => {
                    _checkValue(event)
                }}
                step={_calculateStep()}
            >
            </IonInput>
            <button onClick={_onClick} disabled={value < 1}>
                <IonIcon slot="icon-only" icon={closeCircle} color={(value > 0) ? "primary" : "light"}></IonIcon>
            </button>
        </IonRow >
    )
}

export default InputTable