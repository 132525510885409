import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonRow, IonText } from "@ionic/react";
import { useEffect, useMemo, useRef, useState } from "react"
import CustomTable from "../../../components/Table/CustomTable";
import { MaterialeCommerciale, useMaterialiCommerciali } from "../../../hooks/useMaterialiCommerciali";
import { ColumnDef, getCoreRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { BACKEND_PATH_RESOURCES, useApi } from "../../../hooks/useApi";
import { useEnv } from "../../../Context/EnvContext";
import { downloadSharp } from "ionicons/icons";
import { convertIrcaDateTimeStamp } from "../../../utils/helpers";
import { useMobileMediaQuery } from "../../../hooks/useAppMediaQuery";


const MaterialiCommerciali: React.FC = () => {
    const init = useRef(false);
    const { getEnv } = useEnv();
    const { getMaterialiCommerciali } = useMaterialiCommerciali();
    const [materials, setmaterials] = useState<MaterialeCommerciale[]>([]);
    const isMobile = useMobileMediaQuery();
    const { downloadUrl } = useApi();
    const GATEWAY_BASE = getEnv('GATEWAY_BASE_URL');

    useEffect(() => {
        if (init.current) {
            return;
        }
        init.current = true;
        (async () => {
            setmaterials(await getMaterialiCommerciali());
        })();
    }, [])

    const _downloadUrl = async (url: string, filename: string) => {
        const finalUrl = url.startsWith('/') ? url : `/${url}`;
        await downloadUrl({
            url: `${GATEWAY_BASE}${BACKEND_PATH_RESOURCES}${finalUrl}`,
            filename
        });
    }

    // Define columns for the table
    const columns = useMemo<ColumnDef<MaterialeCommerciale, any>[]>(
        () => [
            {
                header: "Titolo",
                accessorKey: "title",
                meta: {
                    mobileHeader: true
                }
            },
            {
                header: "Descrizione",
                accessorKey: "description"
            },
            {
                header: "Da",
                accessorFn: (row) => convertIrcaDateTimeStamp(row.validityFrom),
                cell: ({ getValue }) => getValue()?.toLocaleDateString('it-IT') || "---"
            },
            {
                header: "A",
                accessorFn: (row) => convertIrcaDateTimeStamp(row.validityTo),
                cell: ({ getValue }) => getValue()?.toLocaleDateString('it-IT') || "---"
            },
            {
                id: "Actions",
                cell: ({ row }) => (
                    <IonButton class={`${isMobile ? 'small-circle-icon' : 'medium-circle-icon'} ion-margin-end`} onClick={() => _downloadUrl(row.original.path, row.original.filename)}>
                        <IonIcon slot="icon-only" icon={downloadSharp}></IonIcon>
                    </IonButton>
                )
            }
        ],
        []
    );


    const table = useReactTable({
        data: materials, // Define data source
        columns, // Define all column props
        // state: {
        //     columnVisibility,
        //     sorting,
        //     globalFilter // Used to enable global filter
        // },
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(), // Used to enable pagination
        getSortedRowModel: getSortedRowModel(), // Enable sorting
        //  debugTable: true, // TODO: remove in production
    });


    return (
        <IonContent>
            <IonGrid class='full-flex-column'>
                <IonRow className="">
                    <IonCol>
                        <IonText color="primary">
                            <h1>MATERIALI COMMERCIALI</h1>
                        </IonText>
                    </IonCol>
                </IonRow>

                <IonRow class="flex-fill">
                    <CustomTable table={table} showTotalLabel={false}></CustomTable>
                </IonRow>
            </IonGrid>
        </IonContent>

    )
}

export default MaterialiCommerciali;