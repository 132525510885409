import { IonCol, IonContent, IonGrid, IonIcon, IonInput, IonItem, IonRow, IonText } from "@ionic/react";
import { useHistory, useLocation } from "react-router";
import { CustomerState } from "../../Materiali/SchedeCliente/SchedeCliente";
import { useEffect, useMemo, useRef, useState } from "react";
import { Product, useNewOrder } from "../../../hooks/useNewOrder";
import { ColumnDef, VisibilityState, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { calculateFinalPrice, convertBaseCurrency, convertNumberToLocale } from "../../../utils/newOrderHelpers";
import { ellipse, searchOutline } from "ionicons/icons";
import CustomTable from "../../../components/Table/CustomTable";
import GruppoMerceologicoSelect from "../../../components/GruppoMerceologicoSelect/GruppoMerceologicoSelect";

const ListiniPersonalizzatiDetail: React.FC = () => {
    const { state } = useLocation<CustomerState>();
    const history = useHistory();
    const { customer } = state ?? {};

    const initData = useRef(false);
    const [allProducts, setAllProducts] = useState<Product[]>([]);
    const [gruppoMerceologicoSelected, setGruppoMerceologicoSelected] = useState<string>();
    const [globalFilter, setGlobalFilter] = useState("");
    const [columnVisibility] = useState<VisibilityState>({
        statusId: false,
    })

    const { getCustomerProducts } = useNewOrder();

    // Define columns for the table
    const columns = useMemo<ColumnDef<Product, any>[]>(
        () => [
            {
                accessorKey: 'itemId',
                header: 'Codice',
                enableGlobalFilter: false,
            },
            {
                accessorKey: 'articoloDes',
                header: 'Denominazione',
                meta: {
                    mobileHeader: true,
                    mobileHeaderPosition: 1
                }
            },
            {
                id: 'gruppoMerceologicoId',
                accessorKey: 'gruppoMerceologico',
                header: 'Gruppo Merceologico',
                enableGlobalFilter: false,
            },
            {
                accessorKey: 'listino',
                header: 'Listino',
                enableSorting: false,
                enableGlobalFilter: false,
            },
            {
                id: 'modificaId',
                accessorFn: (row) => {
                    return new Date(row.timeStamp).toLocaleDateString('it-IT')
                },
                header: 'Modifica'
            },
            {
                id: 'availabilityId',
                header: 'Disp.',
                accessorKey: 'disponibilita',
                cell: ({ getValue }) => {
                    // Get value disponibilità
                    const disponibilita = getValue();
                    return (
                        <IonIcon size='large' icon={ellipse} color={Boolean(disponibilita) ? 'success' : 'danger'} ></IonIcon >
                    )
                },
                enableSorting: false,
                enableGlobalFilter: false,
                meta: {
                    mobileHeader: true,
                    mobileHeaderPosition: 0
                }
            },
            {
                accessorFn: (row) => {
                    const { basePrice, contractPrice, assortmentPrice, baseCurrency } = row;
                    // Apply logic from irca to show the correct price
                    return `${convertBaseCurrency(baseCurrency)} ${convertNumberToLocale(calculateFinalPrice({ basePrice, contractPrice, assortmentPrice }))}`;
                },
                header: 'Prezzo',
                enableGlobalFilter: false,
            },
            {
                header: "Sconto base",
                accessorFn: (row) => `${row.sconto} %`,
            },
            {
                accessorKey: 'pzConf',
                header: 'Pz conf',
                enableSorting: false,
            },
            {
                header: 'Storico',
                accessorKey: 'orderHistory'
            }, {
                id: 'statusId',
                accessorKey: 'status',
                enableHiding: true
            }
        ],
        []
    );

    const table = useReactTable({
        data: allProducts, // Define data source
        columns, // Define all column props
        state: {
            columnVisibility,
            globalFilter,
            // sorting
        },
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(), // Used to enable pagination
        getFilteredRowModel: getFilteredRowModel(), // Used to enable filtering
        getSortedRowModel: getSortedRowModel(), // Enable sorting
        onGlobalFilterChange: setGlobalFilter, // Used to enable global filter
        autoResetPageIndex: true,
        ////  debugTable: true, // TODO: remove in production
    });

    /**
     * Use Effect 
     */
    useEffect(() => {
        if (initData.current) {
            return;
        }
        initData.current = true;
        (async () => {
            if (!customer) {
                return;
            }
            setAllProducts(await getCustomerProducts(customer.customerId))
        })()
    }, [])

    // filter the table by gruppoMerceologicoSelected
    useEffect(() => {
        const column = table.getColumn('gruppoMerceologicoId');
        column?.setFilterValue(gruppoMerceologicoSelected);
    }, [gruppoMerceologicoSelected])


    // go back to selection customer if no 
    if (!customer) {
        history.replace('/materiali/schede-cliente');
        return null;
    }



    return (
        <IonContent>
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <IonText>
                            <h3>LISTINI PERSONALIZZATI</h3>
                        </IonText>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <IonText color="primary">
                            <h1>{customer.description}</h1>
                        </IonText>
                    </IonCol>
                </IonRow>


                <IonRow>
                    <IonCol size="12" sizeMd='8'>
                        <IonItem>
                            <IonInput
                                value={globalFilter ?? ''}
                                onIonInput={({ detail: { value } }) => setGlobalFilter(value as string)}
                                placeholder="RICERCA DENOMINAZIONE"
                            />
                            <IonIcon icon={searchOutline} slot='end'></IonIcon>
                        </IonItem>
                    </IonCol>

                    <IonCol size="12" sizeMd='4'>
                        <IonItem>
                            <GruppoMerceologicoSelect products={allProducts} onGruppoMerceologicoSelected={setGruppoMerceologicoSelected}></GruppoMerceologicoSelect>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <CustomTable table={table}></CustomTable>
                </IonRow>
            </IonGrid>
        </IonContent>
    )
}

export default ListiniPersonalizzatiDetail;