import { IonCol, IonGrid, IonItem, IonLabel, IonRow } from "@ionic/react";
import React from 'react';
import { useHistory, useLocation } from "react-router";
import "./StepTab.css";

type TabProps = {
  path: string;
  title: string;
  subTitle?: string;
  clickable?: boolean;
};

type IStepTab = {
  tabs: TabProps[];
};

const StepTab: React.FC<IStepTab> = ({ tabs }) => {
  const history = useHistory();

  const { pathname } = useLocation();

  const size = (12 / tabs.length).toString();

  return (
    <IonGrid class="step-tab">
      <IonRow>
        {tabs.map((tab, index) => {
          const isDone = index <= tabs.findIndex(element => element.path === pathname) ? true : false;
          return (
            <IonCol key={index} size={size} class={isDone ? "step-tab-selected" : ""}>

              <IonLabel color={isDone ? 'primary' : 'medium'}>{tab.title}</IonLabel>


              <div id="divisor" className={isDone ? "step-tab-selected" : ""} />


              <IonLabel color={isDone ? 'primary' : 'medium'} class="ion-hide-sm-down">
                {tab.subTitle?.toUpperCase()}
              </IonLabel>
            </IonCol>
          );
        })}
      </IonRow>
    </IonGrid>
  );
};

export default StepTab;