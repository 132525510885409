import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonRow, IonText } from "@ionic/react";
import { useAuth } from "../../Context/AuthenticationContext";
import HomeCard from "../../components/HomeCard/HomeCard";
import { pencil, pencilOutline, walletOutline } from "ionicons/icons";
import CustomTable from "../../components/Table/CustomTable";
import { convertBaseCurrency, convertNumberToLocale } from "../../utils/newOrderHelpers";
import { useHistory } from "react-router";
import { useMobileMediaQuery } from "../../hooks/useAppMediaQuery";
import LastDraftOrderTable from "../../components/Table/LastDraftOrderTable";
import CustomersOrdersSummaryTable from "../../components/Table/CustomersOrdersSummatyTable";
import VersionChecker from "../../components/VersionChecker/VersionChecker";





const HomePage: React.FC = () => {
    const { loginData } = useAuth();

    return (
        <IonContent>
            <VersionChecker />
            <IonGrid>
                <IonRow className="ion-align-items-center">
                    <IonCol>
                        <IonText color="primary">
                            <h1>Ciao {loginData?.jwt.fullName}</h1>
                        </IonText>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonText>
                            <h3>LA TUA DASHBOARD</h3>
                        </IonText>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <HomeCard
                        header={{
                            text: 'Ultimi ordini in draft',
                            color: 'tertiary',
                            icon: pencilOutline
                        }}>
                        <LastDraftOrderTable></LastDraftOrderTable>
                    </HomeCard>
                </IonRow>
                <IonRow style={{ 'marginTop': '30px' }}>
                    <HomeCard
                        header={{
                            text: 'Riepilogo ordini clienti',
                            color: 'medium',
                            icon: walletOutline
                        }}>
                        <CustomersOrdersSummaryTable></CustomersOrdersSummaryTable>
                    </HomeCard>
                </IonRow>
            </IonGrid>
        </IonContent>
    );
};

export default HomePage;