import { useEffect, useMemo, useRef, useState } from "react";
import CustomTable, { dateFilterFn } from "./CustomTable"
import { ColumnDef, ColumnSort, Row, VisibilityState, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { convertIrcaDate, } from "../../utils/helpers";
import { TransportDocument, TransportDocumentDetail, useCustomer } from "../../hooks/useCustomer";
import { useIonModal } from "@ionic/react";
import ModalDetailTable from "../../pages/Materiali/SchedeCliente/ModalDetailTable";
import { convertBaseCurrency, convertNumberToLocale } from "../../utils/newOrderHelpers";


interface TransportDocumentsTableI {
    customerId: string;
}

const TransportDocumentsTable: React.FC<TransportDocumentsTableI> = ({ customerId }) => {
    const initData = useRef(false);
    const [transportDocuments, setTransportDocuments] = useState<TransportDocument[]>([]);
    const [sorting, setSorting] = useState<ColumnSort[]>([/* { id: 'favorite', desc: true } */]);
    const { getTransportDocuments, getTransportDocumentDetail } = useCustomer();
    // state for the modal
    // const [detailColumnDefinition, setDetailColumnDefinition] = useState<ColumnDef<any>[]>()
    const [detailData, setDetailData] = useState<any>();
    const [detailTitle, setDetailTitle] = useState<string>();

    const columnsDetail = useMemo<ColumnDef<TransportDocumentDetail, any>[]>(
        () => [
            {
                header: 'N. Articolo',
                accessorKey: 'articolo'
            },
            {
                header: 'Descrizione',
                accessorKey: 'desArticolo',
                meta: {
                    mobileHeader: true
                }

            },
            {
                header: 'Importo',
                accessorFn: (row) => {
                    return row.importoriga ? `${convertBaseCurrency('EUR')} ${convertNumberToLocale(row.importoriga)}` : '---'
                },
            },
            {
                header: 'Q.tà',
                accessorFn: (row) => `${row.qta} ${row.unmis}`
            },
        ],
        []
    );

    const [presentModal, dismissModal] = useIonModal(ModalDetailTable, {
        onDismiss: () => {
            setDetailTitle(undefined);
            setDetailData(undefined);
            dismissModal();
        },
        detailColumnDefinition: columnsDetail,
        detailData,
        title: detailTitle,
    });

    // Define columns for the table
    const columns = useMemo<ColumnDef<TransportDocument, any>[]>(
        () => [
            {
                accessorKey: 'ddt',
                header: 'N. documento',
                meta: {
                    mobileHeader: true
                }
            },
            {
                header: 'Data',
                accessorFn: (row) => {
                    return convertIrcaDate(row.dataDdt);
                },
                cell: ({ getValue }) => {
                    const value = getValue();
                    return value ? value.toLocaleDateString('it-IT') : '---'; // TODO: add correct locale from app
                },
                filterFn: dateFilterFn
            },
            {
                accessorKey: 'description',
                header: 'Destinazione',
            },
            {
                accessorFn: (row) => {
                    return `${row.address} ${row.city} ${row.cap} (${row.provincia}) ${row.countryNewId}`
                },
                header: 'Indirizzo',
            },
        ],
        []
    );

    // Define column visibility
    const [columnVisibility] = useState<VisibilityState>({
        company: false,
    })

    const table = useReactTable({
        data: transportDocuments, // Define data source
        columns, // Define all column props
        state: {
            columnVisibility,
            sorting,
        },
        onSortingChange: setSorting,
        // onGlobalFilterChange: setGlobalFilter, // Used to enable global filter
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(), // Used to enable pagination
        getFilteredRowModel: getFilteredRowModel(), // Used to enable filtering
        getSortedRowModel: getSortedRowModel(), // Enable sorting
        //  debugTable: true, // TODO: remove in production
    });

    /**
     * Use Effect 
     */
    useEffect(() => {
        if (initData.current) {
            return;
        }
        initData.current = true;
        (async () => {
            setTransportDocuments(await getTransportDocuments({ customerId }))
        })()
    }, [])

    const _ddtSelected = async (row: Row<TransportDocument>) => {
        const ddtSelected = row.original;
        const result = await getTransportDocumentDetail({
            customerId,
            ddtId: ddtSelected.ddt
        })
        // set data for the modal
        setDetailTitle(`Dettaglio DDT ${ddtSelected.ddt}`);
        setDetailData(result);
        presentModal({
            id: 'detail-customer-modal'
        });
    }


    return (
        <CustomTable table={table} totalLabel="Documenti totali: " clickRowCallback={_ddtSelected}></CustomTable>
    )
}

export default TransportDocumentsTable;