import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonRow, IonText, IonTitle, IonToolbar, useIonModal } from "@ionic/react";
import { useMobileMediaQuery } from "../../hooks/useAppMediaQuery"
import { closeOutline, documentTextOutline } from "ionicons/icons";
import { MouseEvent } from "react";

interface NoteButtonI {
    note?: string
}

interface NoteModalI {
    note: string,
    onDismiss: () => void
}

const NoteModal: React.FC<NoteModalI> = ({ note, onDismiss }) => {
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Note</IonTitle>
                    <IonButtons slot="end">
                        <IonButton color="black" onClick={onDismiss}>
                            <IonIcon icon={closeOutline}></IonIcon>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonRow>
                    <IonText class="ion-margin ion-text-justify">
                        <p>{note}</p>
                    </IonText>
                </IonRow>
            </IonContent>
        </IonPage>
    )
}

const NoteButton: React.FC<NoteButtonI> = ({ note }) => {
    const isMobile = useMobileMediaQuery();
    const [showModal, dismissModal] = useIonModal(NoteModal, {
        note,
        onDismiss: () => {
            dismissModal();
        }
    });
    const _onClick = (event: MouseEvent<HTMLIonButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
        showModal();
    }
    return (
        <IonButton class={isMobile ? 'small-circle-icon' : 'medium-circle-icon'} size='small' disabled={!note} onClick={_onClick}>
            <IonIcon slot='icon-only' icon={documentTextOutline} />
        </IonButton>
    )
}

export default NoteButton;