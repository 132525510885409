import { useAuth } from "../Context/AuthenticationContext";
import { useEnv } from "../Context/EnvContext";
import { API_M3_PATH, useApi } from "./useApi";

interface useSentOrdersI {
    getAllSentOrders: () => Promise<any>
}

export interface SentOrder {
    numeroOrdine: string;
    address: string;
    city: string;
    customerId: string;
    dataOrdine: string;
    dataSpedizione: string;
    importo: number;
    note: string;
    ragioneSociale: string;
    salesman: string;
    salesmanId: string;
    items: number;
    alcool: boolean;
}

export const useSentOrders = (): useSentOrdersI => {
    const { callApi } = useApi();
    const { getEnv } = useEnv();
    const { loginData } = useAuth();

    const getAllSentOrders = async ({ fromDate, toDate }: { fromDate?: string, toDate?: string } = {}) => {
        if (!loginData) {
            throw new Error('Missing loginData!');
        }
        const { erpId, managedSalesmen } = loginData.jwt;
        // Get base gateway url
        const gatewayBase = getEnv('GATEWAY_BASE_URL');
        // Prepare url
        const url = new URL(`${gatewayBase}/${API_M3_PATH}/salesmen/${erpId}/orders`);
        if (managedSalesmen.length > 0) {
            url.searchParams.append('managedSalesmen', managedSalesmen.join(','));
        }
        if (fromDate) {
            url.searchParams.append('fromDate', fromDate);
        }
        if (toDate) {
            url.searchParams.append('toDate', toDate);
        }

        return await callApi({
            url: url.toString(),
            method: 'GET'
        });
    }
    return { getAllSentOrders }
}