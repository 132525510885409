import { mainDatabase } from "../../App";
import { ApiParams } from "../../hooks/useApi";
import { DraftOrder } from "../../hooks/useDraftOrders";
import Database, { DefinitionTable } from "../database";

export const BACKEND_API_PATHNAME = '/api/pim';

const draftTable: DefinitionTable = {
    name: 'app_draft_offline',
    columns: [
        {
            name: 'id',
            type: 'NUMERIC',
        },
        {
            name: 'json',
            type: 'TEXT',
        },
        {
            name: 'sync',
            type: 'BOOLEAN',
            default: 'false',
        },
        {
            name: 'new',
            type: 'BOOLEAN',
            default: 'false',
        },
    ],
}

// SAVE DRAFT
// {
//     "customerId": "C114667",
//     "businessName": "FRATELLI BOLLANI S.R.L.",
//     "address": "VIA PRIVATA TIRSO, 22",
//     "city": "MILANO",
//     "amount": 8.4,
//     "weight": 10,
//     "productRows": [
//         {
//             "amount": 8.4,
//             "weight": null,
//             "productId": "01010407",
//             "fullPrice": 17.11,
//             "unitOfMeasure": "KG",
//             "quantity": 2,
//             "discount": 0,
//             "warehouse": "D10"
//         }
//     ],
//     "shipToId": 1,
//     "deliveryDate": 1698396120
// }

// GET DRAFT
// {
//     "id": 112,
//     "creationDate": 1696408982,
//     "modificationDate": 1696408982,
//     "status": "draft",
//     "number": null,
//     "customerOrderToken": "WEBC1146671696408982",
//     "customerId": "C114667",
//     "businessName": "FRATELLI BOLLANI S.R.L.",
//     "address": "VIA PRIVATA TIRSO, 22",
//     "city": "MILANO",
//     "companyCode": null,
//     "companyFacility": null,
//     "companyDivision": null,
//     "weight": 10,
//     "amount": 8.4,
//     "deliveryDate": 1698396120,
//     "note": null,
//     "shipToId": 1,
//     "salesmanId": "SP0017",
//     "salesmanFullName": "Fabio Moro",
//     "productRows": [
//         {
//             "amount": 8.4,
//             "weight": null,
//             "productId": "01010407",
//             "fullPrice": 17.11,
//             "unitOfMeasure": "KG",
//             "rowNumber": 1,
//             "quantity": 2,
//             "discount": 0,
//             "warehouse": "D10"
//         }
//     ]
// }
class OfflineBackend {
    private _init;
    constructor() {
        this._init = false;
    }

    async initDb() {
        if (this._init) {
            return;
        }
        await mainDatabase.execute({
            query: Database.getCreationSqlFromDefinition(draftTable)
        });
        this._init = true;
    }

    async callFakeApi(apiParams: ApiParams) {
        await this.initDb();
        const { url, method, body } = apiParams;
        const urlObj = new URL(url);
        let { pathname, searchParams } = urlObj;

        const wrapResponse = (data: any) => ({
            status: true,
            data
        });

        // remove the gateway pathname
        pathname = pathname.substring(BACKEND_API_PATHNAME.length);
        if (pathname === '/orders/draft') {
            return wrapResponse(await this.getDraftOrders());
        } else if (pathname === '/orders/send') {
            throw new Error("Cannot send an order while offline");
        } else if (pathname === '/orders') {
            const id = `0_offline_${new Date().getTime()}`;
            const orderData = JSON.parse(body);
            orderData.id = id;
            this.addDraftOrder(orderData, true);
            return wrapResponse({ id });
        } else if (pathname.startsWith('/orders/')) {
            const [, , id] = pathname.split('/');
            const orderData = JSON.parse(body);
            orderData.id = id;
            this.updateDraftOrder(orderData);
            return wrapResponse({ id });
        }
        throw new Error(`TODO: backend offline api "${method} ${pathname}"`);
    }

    async saveDraftOrders(drafts: DraftOrder[]) {
        await this.initDb();
        await mainDatabase.execute({ query: `DELETE FROM ${draftTable.name}` });
        for (const draft of drafts) {
            this.addDraftOrder(draft, false);
        }
    }

    async addDraftOrder(draft: DraftOrder, created: boolean) {
        const isNew = created;
        const isInSync = !created;
        await mainDatabase.query({
            query: `INSERT INTO ${draftTable.name} (${draftTable.columns.map(c => `${c.name}`).join(',')}) VALUES (${draftTable.columns.map((c) => '?').join(',')})`,
            queryValues: [draft.id, JSON.stringify(draft), isInSync, isNew],
        });
    }

    async updateDraftOrder(draft: DraftOrder) {
        await mainDatabase.query({
            query: `UPDATE ${draftTable.name} SET json=?, sync=0 WHERE id=?`,
            queryValues: [JSON.stringify(draft), draft.id],
        });
    }

    async getOfflineDraftOrdersInfo() {
        return await mainDatabase.query({ query: `SELECT * FROM ${draftTable.name}` });
    }

    async getDraftOrders() {
        const rows = await this.getOfflineDraftOrdersInfo();
        return rows.map(row => JSON.parse(row.json));
    }

}

export default OfflineBackend;