import { IonAlert } from "@ionic/react";
import { useEffect, useRef, useState } from "react"
import { getAppInfo, getLastAppVersion } from "../../utils/helpers";
import { Capacitor } from "@capacitor/core";
import { useEnv } from "../../Context/EnvContext";

const VersionChecker: React.FC = () => {
    const init = useRef(false);
    const [showAlert, setShowAlert] = useState(false);
    const [lastVersion, setLastVersion] = useState<string>();
    const { getEnv } = useEnv();

    useEffect(() => {
        if (init.current) {
            return;
        }
        init.current = true;
        (async () => {
            // Skip check is using from web
            if (!Capacitor.isNativePlatform()) {
                return;
            }
            if (getEnv("ENV").toUpperCase() !== 'PROD') {
                return;
            }
            const lastVersion = await getLastAppVersion();
            // Skip check if something went wrong with getting last version
            if (lastVersion === null) {
                return;
            }
            setLastVersion(lastVersion);
            const { version, build } = await getAppInfo();
            const currentVersion = `${version}.${build}`;
            if (currentVersion !== lastVersion) {
                setShowAlert(true);
            }
        })()
    }, [])

    return (
        <IonAlert
            backdropDismiss={false}
            isOpen={showAlert}
            header="Attenzione"
            message={`Nuova versione disponibile <b>${lastVersion}</b>! </br>
            Puoi scaricarla cliccando questo <a href='https://hibosrl.gitlab.io/irca/irca-distribuzione/irca-distribuzione-app/'>link</a>.`}
            buttons={['Continua']}
        ></IonAlert>
    )
}

export default VersionChecker;