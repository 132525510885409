import { IonIcon, IonRow, IonText } from "@ionic/react";
import { PropsWithChildren } from "react";

import './HomeCard.css';
import { useMobileMediaQuery } from "../../hooks/useAppMediaQuery";

interface MenuCardI {
    onClick?: () => void;
    header: {
        text: string;
        color: string;
        icon?: string;
    }
}

const MenuCard: React.FC<PropsWithChildren<MenuCardI>> = ({ header, children }) => {
    return (
        <div className={`menu-card `}>
            <IonRow class="header ion-align-items-center" style={{
                backgroundColor: `var(--ion-color-${header.color})`,
                color: `var(--ion-color-${header.color}-contrast)`
            }}>
                {header.icon && (
                    <IonIcon icon={header.icon}></IonIcon>
                )}
                <IonText className="header-text">
                    <h6><strong>{header.text}</strong></h6>
                </IonText>
            </IonRow>
            {children}
        </div>
    )
}

export default MenuCard;