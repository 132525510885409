import { createAction, createReducer } from '@reduxjs/toolkit'
import { Address, Customer, Product } from '../../hooks/useNewOrder';

// Customer step
export const addCustomer = createAction<Customer>('order/addCustomer');
export const removeCustomer = createAction('order/removeCustomer')
// Products step
export const addProducts = createAction<Product[]>('order/addProducts');
export const removeProducts = createAction('order/removeProducts')
// Delivery Address step
export const addDeliveryAddress = createAction<Address>('order/addDeliveryAddress');
export const removeDeliveryAddress = createAction('order/removeDeliveryAddress')
// Delivery date step
export const addDeliveryDate = createAction<string>('order/addDeliveryDate');
export const removeDeliveryDate = createAction('order/removeDeliveryDate');
// Note step
export const addNote = createAction<string>('order/addNote');
export const removeNote = createAction('order/removeNote');
// Clear Order
export const clearOrder = createAction('order/clear');
// Load Order
export const loadOrder = createAction<OrderState>('order/loadOrder')

export interface OrderState {
    customer: Customer | null;
    products: Product[] | null;
    deliveryAddress: Address | null;
    deliveryDate: string | null;
    note: string | null;
    id: string | null;
}

const initState: OrderState = {
    id: null,
    customer: null,
    products: null,
    deliveryAddress: null,
    deliveryDate: null,
    note: null
};

const reducer = createReducer(initState, (builder) => {
    builder.addCase(addCustomer, (state, action) => {
        state.customer = action.payload; action
    });
    builder.addCase(removeCustomer, (state) => {
        state.customer = null;
    });
    builder.addCase(addProducts, (state, action) => {
        state.products = action.payload;
    })
    builder.addCase(removeProducts, (state) => {
        state.products = null;
    })
    builder.addCase(addDeliveryAddress, (state, action) => {
        state.deliveryAddress = action.payload;
    })
    builder.addCase(removeDeliveryAddress, (state) => {
        state.deliveryAddress = null;
    })
    builder.addCase(addDeliveryDate, (state, action) => {
        state.deliveryDate = action.payload;
    })
    builder.addCase(removeDeliveryDate, (state) => {
        state.deliveryDate = null;
    })
    builder.addCase(addNote, (state, action) => {
        state.note = action.payload;
    })
    builder.addCase(removeNote, (state) => {
        state.note = null;
    })
    builder.addCase(clearOrder, () => {
        return initState;
    })
    builder.addCase(loadOrder, (state, action) => {
        return action.payload;
    })
})

export default reducer;