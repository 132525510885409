import Join from "./join.js";

class QueryBuilder {
    _select;
    _from;
    _joins;
    _where;
    _group;
    _order;
    _limit;
    _offset;

    constructor() {
        this._clear();
    }
    _clear() {
        this._select = undefined;
        this._from = undefined;
        this._joins = [];
        this._where = undefined;
        this._group = undefined;
        this._order = undefined;
        this._limit = undefined;
        this._offset = undefined;
    }

    sum(column) {
        if (!this._select) {
            throw new Error('Need select');
        }
        const [columnName, asName] = column.split('AS');
        this._select = `${this._select}, sum(${columnName}) ${asName ? 'AS ' + asName : ''}`;
        return this;
    }

    select(arg) {
        if (typeof arg === 'string') {
            this._select = 'SELECT ' + arg;
        } else if (Array.isArray(arg)) {
            this._select = 'SELECT ' + arg.join(',');
        }
        return this;
    }

    from(arg) {
        if (typeof arg !== 'string') {
            throw new Error('From statement must be a string');
        }
        this._from = 'FROM ' + arg;
        return this;
    }

    fromRaw(raw) {
        return this.from(raw);
    }

    innerJoin(table, first) {
        const join = new Join(table, 'inner');

        if (typeof first !== 'function') {
            throw new Error('Must be a function');
        }
        first.call(join);
        this._joins.push(join.toString());
        return this;
    }

    leftJoin(table, first) {
        const join = new Join(table, 'left');

        if (typeof first !== 'function') {
            throw new Error('Must be a function');
        }
        first.call(join);
        this._joins.push(join.toString());
        return this;
    }

    where(...args) {
        const [column, expression, value] = args;
        if (!column) {
            throw new Error('Missing column');
        }
        if (!expression) {
            throw new Error('Missing expression');
        }
        if (!value) {
            throw new Error('Missing value');
        }
        const valueRaw = value?._knexRaw ?? `'${value}'`;
        this._where = `WHERE ${column} ${expression} ${valueRaw}`;
        return this;
    }

    whereIn(expression, values) {
        if (!expression) {
            throw new Error('Missing expression');
        }
        if (!Array.isArray(values)) {
            throw new Error('values need to be an array');
        }
        const sqlString = `${expression} IN (${values.map(v => `'${v}'`).join(',')})`;
        if (this._where) {
            this._where += ` AND ${sqlString}`;
        } else {
            this._where = `WHERE ${sqlString}`;
        }
        return this;
    }

    andWhere(...args) {
        if (!this._where) {
            return this._where(...args);
        }
        const [column, expression, value] = args;
        if (!column) {
            throw new Error('Missing column');
        }
        if (!expression) {
            throw new Error('Missing expression');
        }
        if (!value) {
            throw new Error('Missing value');
        }
        const valueRaw = value?._knexRaw ?? `'${value}'`;
        this._where = `${this._where} AND ${column} ${expression} ${valueRaw}`;

        return this;
    }

    whereBetween(column, betweenArg) {
        if (!column) {
            throw new Error('Missing column');
        }
        if (!Array.isArray(betweenArg)) {
            throw new Error('beetweenArg need to be an array');
        }
        const [min, max] = betweenArg;
        const sqlString = `${column} BETWEEN '${min}' AND '${max}'`;
        if (this._where) {
            this._where += ` AND ${sqlString}`;
        } else {
            this._where = `WHERE ${sqlString}`;
        }
        return this;
    }

    orWhere(...args) {
        if (!this._where) {
            return this._where(...args);
        }
        const [column, expression, value] = args;
        if (!column) {
            throw new Error('Missing column');
        }
        if (!expression) {
            throw new Error('Missing expression');
        }
        if (!value) {
            throw new Error('Missing value');
        }
        const valueRaw = value?._knexRaw ?? `'${value}'`;
        this._where = `${this._where} OR ${column} ${expression} ${valueRaw}`;
        return this;
    }

    orderBy(column, direction, nullPosition) {
        if (Array.isArray(column)) {
            throw new Error('Not supported');
        }
        if (nullPosition && (nullPosition.toUpperCase() !== 'FIRST' || nullPosition.toUpperCase() !== 'LAST')) {
            throw new Error(`Wrong NULL position`);
        }
        this._order = `ORDER BY ${column} ${direction ?? ''} ${nullPosition ?? ''} `;
        return this;
    }

    groupBy(column) {
        if (Array.isArray(column)) {
            throw new Error('Not supported');
        }
        this._group = `GROUP BY ${column} `;
        return this;
    }

    offset(offsetNumber) {
        if (typeof offsetNumber !== 'number') {
            throw new Error('Only support number');
        }
        this._offset = `offset ${offsetNumber} `;
        return this;
    }

    limit(limitNumber) {
        if (typeof limitNumber !== 'number') {
            throw new Error('Only support number');
        }
        this._limit = `limit ${limitNumber} `;
        return this;
    }

    count(field, opts) {
        if (!this._select) throw new Error('Empty _select');
        this._select += `, COUNT(${field}) AS ${opts?.as ?? 'count'}`;
        return this;
    }

    raw(raw) {
        return { _knexRaw: raw };
    }

    toString() {
        if (!this._select) {
            throw new Error('Missing select');
        }
        if (!this._from) {
            throw new Error('Missing from');
        }
        const string = `${this._select} ${this._from} ${this._joins.join(' ')} ${this._where ?? ''} ${this._group ?? ''} ${this._order ?? ''} ${this._limit ?? ''} ${this._offset ?? ''}`;
        this._clear();
        return string;
    }
}

export default QueryBuilder;