import { IonButton, IonButtons, IonFooter, IonRow, IonToolbar } from "@ionic/react";
import { PropsWithChildren, FC } from "react";
import './NavigationFooter.css';

interface NavigationFooterI extends PropsWithChildren {
    disableContinue?: boolean
    onContinue: () => void;
    onBack: () => void;
    continueLabel?: string;
}
const NavigationFooter: FC<NavigationFooterI> = ({ disableContinue = false, onBack, onContinue, children, continueLabel = 'Continua' }) => {
    return (
        <IonFooter class="navigation-footer">
            <IonToolbar>
                <IonRow class="ion-padding-start ion-padding-end">
                    <div className="children-slot">
                        {children}
                    </div>

                    <IonButtons>
                        <IonButton onClick={onBack} color="primary" fill="outline" shape="round" size="small">
                            Indietro
                        </IonButton>

                        <IonButton disabled={disableContinue} onClick={onContinue} color="primary" fill="solid" shape="round" size="small">
                            {continueLabel}
                        </IonButton>
                    </IonButtons>
                </IonRow>
            </IonToolbar>
        </IonFooter>

    )
}

export default NavigationFooter;
