import { IonIcon, IonItem, IonLabel, IonMenuToggle } from "@ionic/react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { Location } from "history";

interface MenuItemProps {
  index: number;
  url: string;
  title: string;
  icon?: string;
  enableLines?: boolean;
  disabled?: boolean;
  onClick?: ({ dispatch, location }: {
    dispatch: Dispatch<AnyAction>,
    location: Location<any>
  }) => void;
}

const MenuItem: React.FC<MenuItemProps> = ({
  index,
  url,
  title,
  icon,
  enableLines = false,
  disabled = false,
  onClick,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  return (
    <IonMenuToggle key={index} autoHide={false}>
      <IonItem
        disabled={disabled}
        className={url && location.pathname.startsWith(url) ? 'selected' : ''}
        routerLink={url}
        routerDirection="root"
        lines={enableLines ? "full" : "none"}
        detail={false}
        onClick={() => {
          onClick?.({ location, dispatch })
        }}
      >
        {icon && (
          <IonIcon slot="start" icon={icon} />
        )}
        <IonLabel>{title}</IonLabel>
      </IonItem>
    </IonMenuToggle>
  );
};

export default MenuItem;