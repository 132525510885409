import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import orderReducer, { OrderState } from './order/order';
import menuReducer, { MenuState } from './menu/menu';
import draftOrderReducer, { DraftOrderState } from './draftOrder/draftOrder';

export type ReduxState = {
    order: OrderState,
    menu: MenuState,
    draftOrder: DraftOrderState,
}
export const store = configureStore({
    reducer: {
        order: orderReducer,
        menu: menuReducer,
        draftOrder: draftOrderReducer,
    },
    devTools: true // TODO: enable only in dev
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
