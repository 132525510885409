import { offlineDatabase } from "../../App";
import { ApiParams } from "../../hooks/useApi";

// @ts-ignore
import M3, { Helper } from 'irca-distribuzione-m3-data-manager/src/index.js';

export const M3_API_PATHNAME = '/api/m3';

class OfflineM3Api {
    _m3: M3;
    constructor() {
        console.log(M3);
        this._m3 = new M3({
            client: 'sqlite3',
        })
    }

    async callFakeApi(apiParams: ApiParams): Promise<any[]> {
        const { url } = apiParams;
        const urlObj = new URL(url);
        let { pathname, searchParams } = urlObj;
        let methodString;
        let queryBuilderFn;
        let returnOnlyFirstElement = false;
        // remove the gateway pathname
        pathname = pathname.substring(M3_API_PATHNAME.length);
        // Prepare url params
        const objParams: { [key: string]: string } = {};
        for (const [key, value] of Array.from(searchParams.entries())) {
            if (!value) {
                continue;
            }
            objParams[key] = value;
        }
        if (pathname.startsWith('/salesmen')) {
            const [, , salesmanId, method] = pathname.split('/');
            objParams['salesmanId'] = salesmanId;
            methodString = method;
            if (method === 'basic-products-list') {
                queryBuilderFn = this._m3.salesmen.getBasicProductList.bind(this._m3.salesmen);
            } else if (method === 'customers') {
                queryBuilderFn = this._m3.salesmen.getCustomers.bind(this._m3.salesmen);
            } else if (method === 'orders') {
                queryBuilderFn = this._m3.salesmen.getOrders.bind(this._m3.salesmen);
            } else if (method === 'customers-orders-summary') {
                queryBuilderFn = this._m3.salesmen.getCustomersOrdersSummary.bind(this._m3.salesmen);
            }
        } else if (pathname.startsWith('/customers')) {
            const [, , customerId, method] = pathname.split('/');
            objParams['customerId'] = customerId;
            methodString = method;
            if (method === 'products') {
                queryBuilderFn = this._m3.customers.getProducts.bind(this._m3.customers);
            } else if (method === 'invoices') {
                queryBuilderFn = this._m3.customers.getInvoices.bind(this._m3.customers);
            } else if (method === 'transport-documents') {
                queryBuilderFn = this._m3.customers.getTransportDocuments.bind(this._m3.customers);
            } else if (method === 'orders') {
                queryBuilderFn = this._m3.customers.getOrders.bind(this._m3.customers);
            } else if (method === 'partite') {
                queryBuilderFn = this._m3.customers.getPartite.bind(this._m3.customers);
            } else if (method === 'ship-to') {
                queryBuilderFn = this._m3.customers.getShipTo.bind(this._m3.customers);
            }
        } else if (pathname.startsWith('/companies')) {
            const [, , companyCode, method] = pathname.split('/');
            objParams['companyCode'] = companyCode;
            methodString = method;
            if (typeof method === 'undefined') {
                queryBuilderFn = this._m3.companies.getCompany.bind(this._m3.customers);
                returnOnlyFirstElement = true;
            }
        }

        if (!queryBuilderFn) {
            throw new Error(`Offline api path "${pathname}" not handled correctly`);
        }

        const sqlQuery = await queryBuilderFn?.(objParams);
        const result = await offlineDatabase.query({
            query: sqlQuery
        });
        const cleadResult = Helper.clean(result);
        if (returnOnlyFirstElement) {
            return cleadResult[0];
        }
        return cleadResult;
    }
}

export default OfflineM3Api;