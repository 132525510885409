import { useEnv } from "../Context/EnvContext";
import { API_M3_PATH, useApi } from "./useApi";

export interface UseCustomerI {
    getAllInvoices: ({ customerId }: { customerId: string }) => Promise<Invoice[]>
    getInvoiceDetail: ({ customerId, invoiceId }: { customerId: string, invoiceId: string }) => Promise<InvoiceDetail[]>
    getTransportDocuments: ({ customerId }: { customerId: string }) => Promise<TransportDocument[]>
    getTransportDocumentDetail: ({ customerId, ddtId }: { customerId: string, ddtId: string }) => Promise<TransportDocumentDetail>
    getOrders: ({ customerId }: { customerId: string }) => Promise<CustomerOrder[]>;
    getOrderDetail: ({ customerId, orderId }: { customerId: string, orderId: string }) => Promise<CustomerOrderDetail>;
    getPartite: ({ customerId }: { customerId: string }) => Promise<Partita[]>;
}

export interface Invoice {
    company: string;
    invoiceDate: string;
    invoiceNr: string;
    importo: number;
    customerId: string;
    ftAperta: boolean;
}

export interface InvoiceDetail {
    company: string;
    customerId: string;
    desItem: string;
    discount1: number;
    invoiceDate: string;
    invoiceNr: string;
    invoiceRow: string;
    itemId: string;
    quantity: number;
    uom: string;
    valueRow: number;
}

export interface TransportDocument {
    company: string;
    codiceCliente: string;
    dataDdt: string;
    ddt: string;
    description: string;
    address: string;
    city: string;
    countryNewId: string;
    cap: string;
    provincia: string;
}

export interface TransportDocumentDetail {
    company: string;
    codicecliente: string;
    ddt: string;
    articolo: string;
    qta: number;
    unmis: string;
    importoriga: number;
    desArticolo: string;
}

export interface Partita {
    customerId: string;
    importo: number;
    scadenza: string;
    dataFattura: string;
    payment: string;
    nrFattura: string;
}

export interface CustomerOrder {
    company: string;
    numeroordine: string;
    importo: number;
    qtaKg: number;
    customerId: string;
    dataordine: string;
    ragioneSociale: string;
    address: string;
    city: string;
    salesmanId: string;
    salesman: string;
    shipto: string;
    note: string;
    dataSpedizione: string;
}

export interface CustomerOrderDetail {
    company: string;
    numeroordine: string;
    importo: number;
    qtaKg: number;
    articolo: string;
    importoListino: number;
    unmis: string;
    numeroriga: string;
    qta: number;
    sconto1: number;
    customerId: string;
    articoloDes: string;
}

export const useCustomer = (): UseCustomerI => {
    const { callApi } = useApi();
    const { getEnv } = useEnv();

    const getAllInvoices = ({ customerId }: { customerId: string }) => {
        const gatewayBase = getEnv('GATEWAY_BASE_URL');
        const url = `${gatewayBase}/${API_M3_PATH}/customers/${customerId}/invoices`;
        return callApi({
            url: url,
            method: 'GET'
        })
    }

    const getInvoiceDetail = ({ customerId, invoiceId }: { customerId: string, invoiceId: string }) => {
        const gatewayBase = getEnv('GATEWAY_BASE_URL');
        const url = `${gatewayBase}/${API_M3_PATH}/customers/${customerId}/invoices/${invoiceId}`;

        return callApi({
            url: url.toString(),
            method: 'GET'
        })
    }

    const getTransportDocuments = ({ customerId }: { customerId: string }) => {
        const gatewayBase = getEnv('GATEWAY_BASE_URL');
        const url = `${gatewayBase}/${API_M3_PATH}/customers/${customerId}/transport-documents`;
        return callApi({
            url: url.toString(),
            method: 'GET'
        })
    }

    const getTransportDocumentDetail = ({ customerId, ddtId }: { customerId: string, ddtId: string }) => {
        const gatewayBase = getEnv('GATEWAY_BASE_URL');
        const url = `${gatewayBase}/${API_M3_PATH}/customers/${customerId}/transport-documents/${ddtId}`;

        return callApi({
            url: url.toString(),
            method: 'GET'
        })
    }

    const getOrders = ({ customerId }: { customerId: string }) => {
        const gatewayBase = getEnv('GATEWAY_BASE_URL');
        const url = `${gatewayBase}/${API_M3_PATH}/customers/${customerId}/orders`;

        return callApi({
            url: url.toString(),
            method: 'GET'
        })
    }

    const getOrderDetail = ({ customerId, orderId }: { customerId: string, orderId: string }) => {
        const gatewayBase = getEnv('GATEWAY_BASE_URL');
        const url = `${gatewayBase}/${API_M3_PATH}/customers/${customerId}/orders/${orderId}`;

        return callApi({
            url: url.toString(),
            method: 'GET'
        })
    }

    const getPartite = ({ customerId }: { customerId: string }) => {
        const gatewayBase = getEnv('GATEWAY_BASE_URL');
        const url = `${gatewayBase}/${API_M3_PATH}/customers/${customerId}/partite`;

        return callApi({
            url: url.toString(),
            method: 'GET'
        })
    }

    return {
        getAllInvoices,
        getInvoiceDetail,
        getTransportDocuments,
        getTransportDocumentDetail,
        getOrders,
        getOrderDetail,
        getPartite,
    }
}