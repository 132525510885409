import { ApiParams } from "../../hooks/useApi";
import OfflineM3Api, { M3_API_PATHNAME } from "./offlineM3Api";
import OfflineBackend, { BACKEND_API_PATHNAME } from "./offlineBackend";

const pathNamesNotAllowed = [
    "/auth",
    "/pim/resources",
    "/api/m3-data"
]

class OfflineGateway {
    public offlineM3Api;
    public offlineBackend;

    constructor() {
        this.offlineM3Api = new OfflineM3Api();
        this.offlineBackend = new OfflineBackend();
    }

    public async selectRoute(apiParams: ApiParams) {
        const { url } = apiParams;
        const urlObj = new URL(url);
        let { pathname } = urlObj;

        console.log("selectRoute", urlObj, pathname);
        // Check pathname
        if (this._checkPathNamesNotAllowed(pathname)) {
            console.error('Non disponibile in modalità offline');
            return;
        }
        // Call to m3-api services
        if (pathname.startsWith(M3_API_PATHNAME)) {
            return await this.offlineM3Api.callFakeApi(apiParams);
        }
        // Call to save draft
        if (pathname.startsWith(BACKEND_API_PATHNAME)) {
            console.log('SONO QUI', pathname);
            // TODO: add function to emulate draft save
            // TODO: add function to emulate favorites save load?
            return await this.offlineBackend.callFakeApi(apiParams);

        }
    }

    private _checkPathNamesNotAllowed(pathname: string) {
        for (const pathnameCheck of pathNamesNotAllowed) {
            if (pathname.startsWith(pathnameCheck)) {
                return true;
            }
        }
        return false;
    }
}

export default OfflineGateway