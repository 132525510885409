import { useEffect, useMemo, useRef, useState } from "react";
import CustomTable, { dateFilterFn } from "./CustomTable";
import { ColumnDef, SortingState, getCoreRowModel, getFilteredRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { convertBaseCurrency, convertNumberToLocale } from "../../utils/newOrderHelpers";
import { IonChip, IonText } from "@ionic/react";

import { CustomerOrderSummary, useSalesman } from "../../hooks/useSalesman";
import { convertIrcaDate } from "../../utils/helpers";

const CustomersOrdersSummaryTable: React.FC = () => {
    const firstInit = useRef(true);

    const { getCustomersOrdersSummary } = useSalesman();
    const [ordersSummary, setOrdersSummary] = useState<CustomerOrderSummary[]>([]);

    // Define columns for the table
    const draftColumns = useMemo<ColumnDef<CustomerOrderSummary, any>[]>(
        () => [
            {
                header: 'Data',
                accessorFn: (row) => {
                    return convertIrcaDate(row.date);
                },
                cell: ({ getValue }) => {
                    const value = getValue();
                    return value ? value.toLocaleDateString('it-IT') : '---'; // TODO: add correct locale from app
                },
                filterFn: dateFilterFn,
                meta: {
                    mobileHeader: true
                }
            },
            {
                accessorKey: 'amount',
                header: 'Importo',
                cell: ({ getValue }) => {
                    return `${convertBaseCurrency()} ${convertNumberToLocale(getValue())}`
                }
            },
        ],
        []
    );

    const draftTable = useReactTable({
        data: ordersSummary, // Define data source
        columns: draftColumns, // Define all column props
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        //  debugTable: true, // TODO: remove in production
    });

    useEffect(() => {
        if (!firstInit.current) {
            return;
        }
        firstInit.current = false;
        (async () => {
            await _getData();
        })()
    }, []);

    const _getData = async () => {
        setOrdersSummary(await getCustomersOrdersSummary({ limit: 10, withDetail: false }) ?? []);
    }


    return (
        <CustomTable showTotalLabel={false} showPageButtons={false} table={draftTable} hideHeader={false}></CustomTable>
    )
}

export default CustomersOrdersSummaryTable