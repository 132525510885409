import { useEffect, useMemo, useRef, useState } from "react";
import CustomTable, { dateFilterFn } from "./CustomTable"
import { ColumnDef, ColumnSort, Row, VisibilityState, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { convertIrcaDate, } from "../../utils/helpers";
import { InvoiceDetail, Partita, useCustomer } from "../../hooks/useCustomer";
import { convertBaseCurrency, convertNumberToLocale } from "../../utils/newOrderHelpers";
import { useIonModal } from "@ionic/react";
import ModalDetailTable from "../../pages/Materiali/SchedeCliente/ModalDetailTable";


interface PartiteTableI {
    customerId: string;
}

const PartiteTable: React.FC<PartiteTableI> = ({ customerId }) => {
    const initData = useRef(false);
    const [partite, setPartite] = useState<Partita[]>([]);
    const [sorting, setSorting] = useState<ColumnSort[]>([/* { id: 'favorite', desc: true } */]);
    const { getPartite } = useCustomer();
    const { getInvoiceDetail } = useCustomer();
    // state for the modal
    const [detailData, setDetailData] = useState<any>();
    const [detailTitle, setDetailTitle] = useState<string>();
    const columnsDetail = useMemo<ColumnDef<InvoiceDetail, any>[]>(
        () => [
            {
                header: 'Descrizione',
                accessorKey: 'desItem',
                meta: {
                    mobileHeader: true
                }
            },
            {
                header: 'Sconto',
                accessorKey: 'discount1'
            },
            {
                header: 'Data',
                accessorFn: (row) => {
                    return convertIrcaDate(row.invoiceDate);
                },
                cell: ({ getValue }) => {
                    const value: any = getValue();
                    return value ? value.toLocaleDateString('it-IT') : '---'; // TODO: add correct locale from app
                },
                filterFn: dateFilterFn
            },
            {
                header: 'N. Prodotto',
                accessorKey: 'itemId'
            },
            {
                header: 'Quantità',
                accessorKey: 'quantity'
            },
            {
                header: 'Uom',
                accessorKey: 'uom'
            },
            {
                header: 'Prezzo',
                accessorKey: 'valueRow',
                cell: ({ getValue }) => {
                    const value: any = getValue();
                    return `${convertBaseCurrency('EUR')} ${convertNumberToLocale(value as number)}`
                }
            },
        ],
        []
    );
    const [presentModal, dismissModal] = useIonModal(ModalDetailTable, {
        onDismiss: () => {
            setDetailTitle(undefined);
            setDetailData(undefined);
            dismissModal();
        },
        detailColumnDefinition: columnsDetail,
        detailData,
        title: detailTitle,
    });

    // Define columns for the table
    const columns = useMemo<ColumnDef<Partita, any>[]>(
        () => [
            {
                accessorKey: 'nrFattura',
                header: 'N. Fattura',
                meta: {
                    mobileHeader: true
                }
            },
            {
                header: 'Data',
                accessorFn: (row) => {
                    return convertIrcaDate(row.dataFattura);
                },
                cell: ({ getValue }) => {
                    const value = getValue();
                    return value ? value.toLocaleDateString('it-IT') : '---'; // TODO: add correct locale from app
                },
                filterFn: dateFilterFn
            },
            {
                header: 'Scadenza',
                accessorFn: (row) => {
                    return convertIrcaDate(row.scadenza);
                },
                cell: ({ getValue }) => {
                    const value = getValue();
                    return value ? value.toLocaleDateString('it-IT') : '---'; // TODO: add correct locale from app
                },
                filterFn: dateFilterFn
            },
            {
                accessorKey: 'importo',
                header: 'Importo',
                cell: ({ getValue }) => {
                    return `${convertBaseCurrency('EUR')} ${convertNumberToLocale(getValue())}`
                }
            },
        ],
        []
    );

    // Define column visibility
    const [columnVisibility] = useState<VisibilityState>({
        company: false,
    })

    const _onRowClicked = async (row: Row<Partita>) => {
        const partitaSelected = row.original;

        const result = await getInvoiceDetail({
            customerId,
            invoiceId: partitaSelected.nrFattura
        })
        // set data for the modal
        setDetailTitle(`Dettaglio fattura ${partitaSelected.nrFattura}`);
        setDetailData(result);

        presentModal({
            id: 'detail-customer-modal'
        });
    }

    const table = useReactTable({
        data: partite, // Define data source
        columns, // Define all column props
        state: {
            columnVisibility,
            sorting,
        },
        onSortingChange: setSorting,
        // onGlobalFilterChange: setGlobalFilter, // Used to enable global filter
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(), // Used to enable pagination
        getFilteredRowModel: getFilteredRowModel(), // Used to enable filtering
        getSortedRowModel: getSortedRowModel(), // Enable sorting
        //  debugTable: true, // TODO: remove in production
    });

    /**
     * Use Effect 
     */
    useEffect(() => {
        if (initData.current) {
            return;
        }
        initData.current = true;
        (async () => {
            setPartite(await getPartite({ customerId }))
        })()
    }, [])

    return (
        <CustomTable table={table} clickRowCallback={_onRowClicked} totalLabel="Documenti totali: "></CustomTable>
    )
}

export default PartiteTable;