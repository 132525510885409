import { IonAvatar, IonBadge, IonBreadcrumb, IonBreadcrumbs, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCheckbox, IonCol, IonContent, IonDatetime, IonDatetimeButton, IonFooter, IonGrid, IonHeader, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonLabel, IonList, IonModal, IonPage, IonPopover, IonRow, IonSearchbar, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonText, IonTextarea, IonTitle, IonToolbar } from "@ionic/react";
import * as IonIcons from "ionicons/icons";
import { useState } from "react";
import DatePicker from "../../components/DatePicker";
import IonTable from "./IonTable";

const Header = () => {
  return (
    <IonHeader collapse="fade" class="ion-no-border">
      <IonToolbar>
        <IonGrid>
          <IonRow style={{ alignItems: "center" }}>
            <IonCol>
              <IonText color="primary">
                <IonTitle>{`IRCA HEADER `}</IonTitle>
              </IonText>
              <IonText color="danger">
                <IonIcon icon={IonIcons.informationCircle} size="large" />
                Si puo fare un componente univoco per questo
              </IonText>
            </IonCol>

            <IonCol size="auto">
              <IonGrid>
                <IonRow style={{ alignItems: "center" }}>
                  <IonCol>
                    <IonText color="secondary" style={{ whiteSpace: "nowrap" }}>
                      Benvenuto, MARIO ROSSI
                    </IonText>
                  </IonCol>

                  <IonCol style={{ borderRight: "1px solid lightgray" }}>
                    <IonAvatar style={{ height: "36px", width: "36px" }}>
                      <img alt="Silhouette of a person's head" src="https://ionicframework.com/docs/img/demos/avatar.svg" />
                    </IonAvatar>
                  </IonCol>

                  <IonCol>
                    <IonIcon size="small" icon={IonIcons.mail} color="secondary" />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonToolbar>
    </IonHeader>
  )
}

const Colors = () => {
  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>
          <IonLabel>COLORS PREDEFINED</IonLabel>
        </IonCardTitle>
      </IonCardHeader>

      <IonCardContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel>PRIMARY</IonLabel>
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonBadge style={{ backgroundColor: "#9B212D" }}>
                #9B212D
              </IonBadge>
            </IonCol>

            <IonCol>
              <IonBadge style={{ backgroundColor: "#000000" }}>
                #000000
              </IonBadge>
            </IonCol>

            <IonCol>
              <IonBadge color="dark" style={{ backgroundColor: "#FFFFFF" }}>
                #FFFFFF
              </IonBadge>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel>
                  SECONDARY
                </IonLabel>
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonBadge style={{ backgroundColor: "#D09641" }}>
                #D09641
              </IonBadge>
            </IonCol>

            <IonCol>
              <IonBadge style={{ backgroundColor: "#C93B6A" }}>
                #C93B6A
              </IonBadge>
            </IonCol>

            <IonCol>
              <IonBadge style={{ backgroundColor: "#0F9F98" }}>
                #0F9F98
              </IonBadge>
            </IonCol>

            <IonCol>
              <IonBadge style={{ backgroundColor: "#707070" }}>
                #707070
              </IonBadge>
            </IonCol>

            <IonCol>
              <IonBadge color="dark" style={{ backgroundColor: "#E9E7E4" }}>
                #E9E7E4
              </IonBadge>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonList>
                <IonItem>
                  <IonLabel>STATUS ERROR</IonLabel>
                </IonItem>

                <IonItem>
                  <IonBadge style={{ backgroundColor: "#FF3B30" }}>
                    #FF3B30
                  </IonBadge>
                </IonItem>
              </IonList>
            </IonCol>

            <IonCol>
              <IonList>
                <IonItem>
                  <IonLabel>STATUS SUCESS</IonLabel>
                </IonItem>

                <IonItem>
                  <IonBadge style={{ backgroundColor: "#34C759" }}>
                    #34C759
                  </IonBadge>
                </IonItem>
              </IonList>
            </IonCol>

            <IonCol>
              <IonList>
                <IonItem>
                  <IonLabel>BKG</IonLabel>
                </IonItem>

                <IonItem>
                  <IonBadge color="dark" style={{ backgroundColor: "#F4F3EF" }}>
                    #F4F3EF
                  </IonBadge>
                </IonItem>
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonGrid>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel>
                  THEME COLORS
                </IonLabel>
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonButton color="danger" size="large">DANGER</IonButton>
            </IonCol>

            <IonCol>
              <IonButton color="dark" size="large">DARK</IonButton>
            </IonCol>

            <IonCol>
              <IonButton color="light" size="large">LIGHT</IonButton>
            </IonCol>

            <IonCol>
              <IonButton color="medium" size="large">MEDIUM</IonButton>
            </IonCol>

            <IonCol>
              <IonButton color="primary" size="large">PRIMARY</IonButton>
            </IonCol>

            <IonCol>
              <IonButton color="secondary" size="large">SECONDARY</IonButton>
            </IonCol>

            <IonCol>
              <IonButton color="success" size="large">SUCCESS</IonButton>
            </IonCol>

            <IonCol>
              <IonButton color="tertiary" size="large">TERTIARY</IonButton>
            </IonCol>

            <IonCol>
              <IonButton color="warning" size="large">WARNING</IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </IonCard>
  )
};

const Typography = () => {
  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>
          {`TYPOGRAPHY `}
          <IonText color="danger">
            <IonIcon icon={IonIcons.informationCircle} size="large" />
            Servono dei componenti che facciano wrap le ultime parti per typography... Opure si puo fare un unico componente in qualle possiamo includere tutti i stili gia presetati ed usiamo quello
          </IonText>
        </IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonText color="primary">
          <h1>Header 1</h1>
        </IonText>

        <IonText>
          <h2>Header 4</h2>
        </IonText>

        <IonText>
          <h3>Header 6</h3>
        </IonText>

        <IonText>
          <h4>Subtitle 2</h4>
        </IonText>

        <IonText>
          <p>Body 2</p>
        </IonText>

        <IonItem>
          <IonButton size="default">
            BUTTON TEXT
          </IonButton>

          <IonButton size="small">
            SMALL BUTTON TEXT
          </IonButton>
        </IonItem>

        <IonItem>
          <IonLabel>
            Label Small
          </IonLabel>

          <IonLabel>
            <b>Label Small</b>
          </IonLabel>
        </IonItem>
      </IonCardContent>
    </IonCard>
  )
};

const Icons = () => {
  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>
          {`ICONS `}
          <IonText color="danger">
            <IonIcon icon={IonIcons.informationCircle} size="large" />
            Si potrebe fare un componente wrapper per facilitare il uso per le icone cosi ce un punto singolo di uso
          </IonText>
        </IonCardTitle>
      </IonCardHeader>

      <IonCardContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonIcon icon={IonIcons.trashBin} size="large" color="primary" />
            </IonCol>

            <IonCol>
              <IonIcon icon={IonIcons.search} size="small" color="secondary" />
            </IonCol>

            <IonCol>
              <IonIcon icon={IonIcons.send} color="danger" />
            </IonCol>

            <IonCol>
              <IonIcon icon={IonIcons.person} color="warning" />
            </IonCol>

            <IonCol>
              <IonIcon icon={IonIcons.home} color="dark" size="large" />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </IonCard>
  );
}

const ButtonIcons = () => {
  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>
          {`BT Icons

          TODO: MODIFY ION-FAB OR MODIFY BUTTON `}
          <IonText color="danger">
            <IonIcon icon={IonIcons.informationCircle} size="large" />
            {` Serve componente `}
          </IonText>
        </IonCardTitle>
      </IonCardHeader>

      <IonCardContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonButton color="primary" fill="outline" class="button-circle">
                <IonIcon icon={IonIcons.download} />
              </IonButton>
            </IonCol>

            <IonCol>
              <IonButton color="primary" fill="solid" class="button-circle">
                <IonIcon icon={IonIcons.pencil} />
              </IonButton>
            </IonCol>

            <IonCol>
              <IonButton color="medium" fill="solid" class="button-circle">
                <IonIcon icon={IonIcons.close} />
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </IonCard>
  )
}

const Breadcrumbs = () => {
  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>
          BREADCRUMBS
        </IonCardTitle>
      </IonCardHeader>

      <IonCardContent>
        <IonBreadcrumbs>
          <IonBreadcrumb href="/homepage">
            Home
          </IonBreadcrumb>
          <IonBreadcrumb href="/design-system">
            Design system
          </IonBreadcrumb>
        </IonBreadcrumbs>
      </IonCardContent>
    </IonCard>
  )
}

const Buttons = () => {
  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>
          BUTTONS
        </IonCardTitle>
      </IonCardHeader>

      <IonCardContent>
        <IonItem>
          <IonButtons>
            <IonButton color="primary" fill="solid" shape="round" size="default">
              Salva Nuova Password
            </IonButton>
          </IonButtons>
        </IonItem>

        <IonItem>
          <IonButtons>
            <IonButton color="primary" fill="solid" shape="round" size="large">
              <IonIcon icon={IonIcons.refresh} />
              Disabilita CMS
            </IonButton>

            <IonButton color="warning" fill="solid" shape="round" size="default">
              <IonIcon icon={IonIcons.warning} />
              Disabilita CMS
            </IonButton>

            <IonButton color="medium" fill="solid" shape="round" size="small">
              <IonIcon icon={IonIcons.trash} />
              Elimina
            </IonButton>
          </IonButtons>
        </IonItem>

        <IonItem>
          <IonButton color="primary" fill="outline" shape="round" size="small">
            <IonIcon icon={IonIcons.add} />
            Inserisci
          </IonButton>
        </IonItem>
      </IonCardContent>
    </IonCard>
  );
}

const ActionBars = () => {
  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>
          ACTION BARS
        </IonCardTitle>
      </IonCardHeader>

      <IonCardContent>
        <IonToolbar color="primary" style={{ marginBottom: "20px" }}>
          <IonTitle>
            <IonIcon icon={IonIcons.logoEuro} /> INSOLUTI
          </IonTitle>

          <IonButtons slot="primary">
            <IonButton class="small-button" fill="outline" shape="round" color="light">
              <IonIcon icon={IonIcons.add} />
              Inserisci
            </IonButton>
          </IonButtons>
        </IonToolbar>

        <IonToolbar color="primary">
          <IonTitle>
            <IonIcon icon={IonIcons.book} style={{ marginRight: "10px" }} />
            Materiali di comunicazione
          </IonTitle>
        </IonToolbar>
      </IonCardContent>
    </IonCard>
  );
}

const PageHeaders = () => {
  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>
          Page Headers

          <IonText color="danger">
            <IonIcon icon={IonIcons.informationCircle} size="large" />
            Probabilmente serve un componente per questo
          </IonText>
        </IonCardTitle>
      </IonCardHeader>

      <IonCardContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonBreadcrumbs>
                <IonBreadcrumb href="/homepage">
                  Homepage
                </IonBreadcrumb>
                <IonBreadcrumb href="/design-system">
                  Design system
                </IonBreadcrumb>
              </IonBreadcrumbs>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size="auto">
              <IonIcon icon={IonIcons.cloudCircleOutline} color="primary" size="large" />
            </IonCol>

            <IonCol>
              <IonTitle color="primary">PANNELLO ORDINI</IonTitle>
            </IonCol>

            <IonCol size="auto">
              <IonButton color="primary" shape="round" fill="solid" style={{ marginLeft: "auto" }}>
                MODIFICA
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonTitle>
        </IonTitle>
      </IonCardContent>
    </IonCard>
  )
}

const Inputs = () => {
  const [selected, setSelected] = useState<null | string>(null);

  const options = [
    {
      label: "Apples",
      value: 1,
    },
    {
      label: "Oranges",
      value: 2,
    },
    {
      label: "Bananas",
      value: 3,
    }
  ];

  const customDate = new Date();
  customDate.setDate(13);
  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>
          FILTERS / INPUTS
        </IonCardTitle>
      </IonCardHeader>

      <IonCardContent>
        <IonGrid>
          <IonRow>
            <IonCol size="auto">
              <IonItem>
                <IonSelect placeholder="Seleziona cliente" value={selected} interface="popover" mode="ios">
                  {options.map(element => (
                    <IonSelectOption value={element.value}>{element.label}</IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>

              <IonSelect placeholder="Seleziona cliente" value={selected} interface="popover" mode="ios">
                {options.map(element => (
                  <IonSelectOption value={element.value}>{element.label}</IonSelectOption>
                ))}
              </IonSelect>
            </IonCol>

            <IonCol size="auto">
              <IonItem>
                <IonCheckbox slot="start" color="medium" />
                <IonLabel>Checkbox</IonLabel>
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow class="ion-align-items-center">
            <IonCol size="auto">
              Searchbar
            </IonCol>

            <IonCol>
              <IonSearchbar value="test" showClearButton="never" />
            </IonCol>
          </IonRow>

          <IonRow class="ion-align-items-center">
            <IonCol size="auto">
              DatePickers
            </IonCol>

            <IonCol>
              <IonDatetimeButton datetime="datetimetest1" />

              <IonPopover keepContentsMounted={true}>
                <IonDatetime id="datetimetest1" value={customDate.toISOString()} />
              </IonPopover>
            </IonCol>

            <IonCol>
              <IonDatetimeButton datetime="datetimetest2" />

              <IonPopover keepContentsMounted={true}>
                <IonDatetime id="datetimetest2" value={customDate.toISOString()} presentation="date" />
              </IonPopover>
            </IonCol>

            <IonCol>
              <IonDatetimeButton datetime="datetimetest3" />

              <IonPopover keepContentsMounted={true}>
                <IonDatetime id="datetimetest3" value={customDate.toISOString()} presentation="time" />
              </IonPopover>
            </IonCol>
          </IonRow>

          <IonRow class="ion-align-items-center">
            <IonCol size="auto">
              Segments
            </IonCol>

            <IonCol>
              <IonSegment value="INVIATI" mode="ios" color="primary">
                <IonSegmentButton value="INVIATI">INVIATI</IonSegmentButton>
                <IonSegmentButton value="NON INVIATI">NON INVIATI</IonSegmentButton>
                <IonSegmentButton value="SOSPESI">SOSPESI</IonSegmentButton>
              </IonSegment>
            </IonCol>

            <IonCol>
              <IonSegment value="1" mode="ios" color="primary">
                <IonSegmentButton value="1">
                  <IonIcon icon={IonIcons.logoEuro} />
                </IonSegmentButton>
                <IonSegmentButton value="2">
                  <IonIcon icon={IonIcons.text} />
                </IonSegmentButton>
                <IonSegmentButton value="3">
                  <IonIcon icon={IonIcons.filterCircle} />
                </IonSegmentButton>
              </IonSegment>
            </IonCol>
          </IonRow>

          <IonRow class="ion-align-items-center">
            <IonCol size="auto">
              Text input
            </IonCol>

            <IonCol>
              <IonItem>
                <IonInput placeholder="SELEZIONA CLIENTE" />
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </IonCard>
  )
}

const Modals = () => {
  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>
          Inline Modals
        </IonCardTitle>
      </IonCardHeader>

      <IonCardContent>
        <IonButton id="open-this-modal">
          Open
        </IonButton>

        <IonModal trigger="open-this-modal">
          <IonHeader>
            <IonToolbar>
              <IonTitle>
                Modifiche Materiale di Comunicazione
              </IonTitle>

              <IonButtons slot="end">
                <IonButton shape="round" fill="clear">
                  <IonIcon icon={IonIcons.close} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent>
            <IonGrid>
              <IonRow class="ion-align-items-center">
                <IonCol size="2">
                  <IonLabel>
                    Titolo
                  </IonLabel>
                </IonCol>

                <IonCol>
                  <IonItem fill="outline">
                    <IonInput placeholder="PROMO AUTUNNO INVERNO 2022" />
                    <IonIcon icon={IonIcons.person} />
                  </IonItem>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol size="2" style={{ marginTop: "10px" }}>
                  Descrizione
                </IonCol>

                <IonCol>
                  <IonItem fill="outline">
                    <IonTextarea placeholder="Promo autunno inverno 2022" rows={5} />
                  </IonItem>
                </IonCol>
              </IonRow>

              <IonRow class="ion-align-items-center">
                <IonCol size="2">
                  <IonLabel>Valido dal</IonLabel>
                </IonCol>

                <IonCol>
                  <IonItem fill="outline">
                    <IonInput type="date" placeholder="DD/MM/YYYY" value={new Date().toISOString()} />
                  </IonItem>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>

          <IonFooter>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton color="primary" shape="round" fill="outline">
                  CLOSE
                </IonButton>

                <IonButton color="primary" shape="round" fill="solid">
                  SALVA
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonFooter>
        </IonModal>
      </IonCardContent>
    </IonCard>
  )
}

const DesignSystem = () => {
  const [dateValFirst, setDateValFirst] = useState<string | null>(null);
  const [dateValSecond, setDateValSecond] = useState<string | null>(null);

  return (
    <IonContent>
      <IonTitle>
        Design SYSTEM
      </IonTitle>

      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            Date Picker
          </IonCardTitle>
        </IonCardHeader>

        <IonCardContent>
          <DatePicker placeholder="" onChange={setDateValFirst} presentation="date" />

          <IonGrid>
            <IonRow>
              <IonCol size="auto">
                <DatePicker placeholder="" onChange={setDateValSecond} presentation="date-time" />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardContent>
      </IonCard>

      <Header />

      <Colors />

      <Typography />

      <Icons />

      <ButtonIcons />

      <Breadcrumbs />

      <Buttons />

      <ActionBars />

      <PageHeaders />

      <Inputs />

      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            TABLE
          </IonCardTitle>
        </IonCardHeader>

        <IonCardContent>
          <IonTable />
        </IonCardContent>
      </IonCard>

      <Modals />
    </IonContent>
  )
}

export default DesignSystem;