import { IonCol, IonContent, IonGrid, IonRow } from "@ionic/react";
import React from 'react'

type Props = {}

const IonTable = (props: Props) => {

  const data = [
    {
      column1: "test1",
      column2: "test2",
      column3: "test3",
      column4: "test4",
      column5: "test5",
      column6: "test6",
      column7: "test7",
      column8: "test8",
      column9: "test9",
      column10: "test10",
      column11: "test11",
      column12: "test12",
      column13: "test13",
      column14: "test14",
    }
  ]

  return (
    <div style={{ width: "100%", overflow: "auto" }}>
      <IonGrid fixed={true}>
        <IonRow style={{ flexWrap: "nowrap" }}>
          <IonCol>
            column1
          </IonCol>
          
          <IonCol>
            column2
          </IonCol>
          
          <IonCol>
            column3
          </IonCol>
          
          <IonCol>
            column4
          </IonCol>
          
          <IonCol>
            column5
          </IonCol>
          
          <IonCol>
            column6
          </IonCol>
          
          <IonCol>
            column7
          </IonCol>
          
          <IonCol>
            column8
          </IonCol>
          
          <IonCol>
            column9
          </IonCol>
          
          <IonCol>
            column10
          </IonCol>
          
          <IonCol>
            column11
          </IonCol>
          
          <IonCol>
            column12
          </IonCol>
          
          <IonCol>
            column13
          </IonCol>
          
          <IonCol>
            column14
          </IonCol>
        </IonRow>

        {data.map(element => (
          <IonRow style={{ flexWrap: "nowrap" }}>
            {Object.entries(element).map(col => (
              <IonCol>
                {col[1]}
              </IonCol>
            ))}
          </IonRow>
        ))}
      </IonGrid>
    </div>
  )
}

export default IonTable;