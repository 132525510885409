import * as IonIcons from "ionicons/icons";
import { clearOrder } from "../../redux/order/order";
import { Location } from "history";
import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { removeDraftOrder } from "../../redux/draftOrder/draftOrder";

export type AppPage = {
  url: string;
  icon?: string;
  title: string;
  disabled?: boolean;
  disableOffline?: boolean;
  onlyMobile?: boolean;
  onClick?: ({ dispatch, location }: {
    dispatch: Dispatch<AnyAction>,
    location: Location<any>
  }) => void;
};

export type AppPageWithChildren = {
  icon: string;
  title: string;
  children: AppPage[];
  disabled?: boolean;
  onlyMobile?: boolean;
  onClick?: ({ dispatch, location }: {
    dispatch: Dispatch<AnyAction>,
    location: Location<any>
  }) => void;
};

export const isAppPageWithChildren = (appPage: AppPage | AppPageWithChildren): appPage is AppPageWithChildren => {
  return (appPage as AppPageWithChildren).children !== undefined;
};

const APP_PAGES: (AppPage | AppPageWithChildren)[] = [
  // {
  //   title: 'test REMOVE',
  //   url: '/test',
  //   icon: IonIcons.telescopeOutline,
  //   disabled: true,
  // },
  {
    title: 'HOMEPAGE',
    url: '/homepage',
    icon: IonIcons.homeOutline,
  },
  // {
  //   title: "Design system",
  //   url: "/design-system",
  //   icon: IonIcons.settingsOutline,
  //   disabled: true,
  // },
  {
    title: "ORDINI",
    icon: IonIcons.cartOutline,
    children: [
      {
        title: "Nuovo Ordine",
        url: "/ordini/nuovo-ordine",
        onClick: ({ dispatch, location }) => {
          // Skip the logic
          if (location.pathname.startsWith('/ordini/nuovo-ordine/')) {
            return;
          }
          // Clean redux order state
          dispatch(clearOrder());
          dispatch(removeDraftOrder());
        }
      },
      {
        title: "Ordini Draft",
        url: "/ordini/ordini-draft",
      },
      {
        title: "Ordini Inviati",
        url: "/ordini/ordini-inviati",
      },
      {
        title: "Listino Base",
        url: "/ordini/listino-base",
      },
      {
        title: "Listini Personalizzati",
        url: "/ordini/listini-personalizzati",
      }
    ]
  },
  {
    title: 'MATERIALI',
    url: '/materiali',
    icon: IonIcons.documentTextOutline,
    children: [
      {
        title: 'Materiali commerciali',
        url: '/materiali/materiali-commerciali',
        disableOffline: true,
      },
      {
        title: 'Schede Cliente',
        url: '/materiali/schede-cliente'
      }
    ]
  },
  {
    title: 'IMPOSTAZIONI',
    url: '/settings',
    onlyMobile: true,
    icon: IonIcons.settingsOutline,
    children: [
      {
        title: 'Offline',
        url: '/settings/offline'
      }
    ]
  }
];

export default APP_PAGES;