export default class Join {
    _joinType;
    _table;
    _on;

    constructor(table, joinType) {
        this._table = table;
        this._joinType = joinType;
        this._on = undefined;
    }

    on(...args) {
        const [column, expression, value] = args;
        if (!column) {
            throw new Error('Missing column');
        }
        if (!expression) {
            throw new Error('Missing expression');
        }
        if (!value) {
            throw new Error('Missing value');
        }
        if (this._on) {
            this._on = this._on + ` AND ${column} ${expression} ${value}`;
        } else {
            this._on = `ON ${column} ${expression} ${value}`;
        }
    }

    onIn(column, values) {
        const inString = `${column} IN (${values.map(v => `'${v}'`).join(',')})`;
        if (this._on) {
            this._on = this._on + ` AND ${inString}`;
        } else {
            this._on = `ON ${inString}`;
        }
    }

    onBetween(column, minMax) {
        if (!Array.isArray(minMax)) {
            throw new Error('Min max must ben and array');
        }
        const [min, max] = minMax;
        const betweenString = `${column} BETWEEN '${min}' AND '${max}'`;
        if (this._on) {
            this._on = this._on + ` AND ${betweenString}`;
        } else {
            this._on = `ON ${betweenString}`;
        }
    }

    toString() {
        return `${this._joinType.toUpperCase()} JOIN ${this._table} ${this._on}`;
    }
} 