import { IonChip, IonCol, IonContent, IonGrid, IonIcon, IonInput, IonItem, IonRow, IonText } from "@ionic/react";
import { useEffect, useMemo, useRef, useState } from "react";
import { SentOrder, useSentOrders } from "../../../hooks/useSentOrders";
import CustomTable, { dateFilterFn } from "../../../components/Table/CustomTable";
import { ColumnDef, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { convertBaseCurrency, convertNumberToLocale } from "../../../utils/newOrderHelpers";
import { searchOutline } from "ionicons/icons";
import { VisibilityState } from "@tanstack/react-table";
import DatePicker from "../../../components/DatePicker";
import { convertIrcaDate } from "../../../utils/helpers";
import { useMobileMediaQuery } from "../../../hooks/useAppMediaQuery";
import NoteButton from "../../../components/NoteButton/NoteButton";

const OrdiniInviati: React.FC = () => {
  const firstInit = useRef(true);
  const { getAllSentOrders } = useSentOrders();
  const [sentOrders, setSentOrders] = useState<any[]>([]);
  const [productSearch, setProductSearch] = useState('');
  // Define column visibility
  const [columnVisibility] = useState<VisibilityState>({
    products: false,
  })
  const [fromDate, setFromDate] = useState<string | null>(null);
  const [toDate, setToDate] = useState<string | null>(null);
  const isMobile = useMobileMediaQuery();

  // Define columns for the table
  const columns = useMemo<ColumnDef<SentOrder, any>[]>(
    () => [
      {
        accessorKey: 'numeroOrdine',
        header: 'N. Ordine',
        meta: {
          mobileHeader: true,
        }
      },
      {
        header: 'Inviato il',
        accessorFn: (row) => {
          return convertIrcaDate(row.dataOrdine);
        },
        cell: ({ getValue }) => {
          const value = getValue();
          return value ? value.toLocaleDateString('it-IT') : '---'; // TODO: add correct locale from app
        }
      },
      {
        id: 'dataSpedizioneId',
        header: 'Spedito il',
        accessorFn: (row) => {
          return convertIrcaDate(row.dataSpedizione);
        },
        cell: ({ getValue }) => {
          const value = getValue();
          return value ? value.toLocaleDateString('it-IT') : '---'; // TODO: add correct locale from app
        },
        filterFn: dateFilterFn
      },
      {
        header: 'Agente',
        accessorKey: 'salesman'
      },
      {
        header: 'Codice',
        accessorKey: 'customerId'
      },
      {
        header: 'Destinatario',
        accessorFn: (row) => {
          return {
            bold: row.ragioneSociale,
            normal: `${row.city} ${row.address}`
          }
        },
        cell: ({ getValue }) => {
          const value = getValue();
          return (
            <IonText>
              <p className="ion-no-margin">
                <strong>{value.bold}</strong>
              </p>
              <p className="ion-no-margin">
                {value.normal}
              </p>
            </IonText>
          );
        },
        enableSorting: false,
      },
      {
        header: 'Pezzi',
        accessorKey: 'items'
      }, {
        header: 'Importo',
        accessorKey: 'importo',
        cell: ({ getValue }) => {
          return `${convertBaseCurrency('EUR')} ${convertNumberToLocale(getValue())}`
        }
      }, {
        header: 'Blocco',
        accessorKey: 'alcool',
        cell: ({ getValue }) => {
          return getValue() ? (
            <IonChip color='primary'>Alcool</IonChip>
          ) : null
        }
      },
      {
        header: 'Note',
        accessorKey: 'note',
        cell: ({ getValue }) => {
          const note = getValue();
          return <NoteButton note={note}></NoteButton>
        },
        enableSorting: false,
      }
    ],
    []
  );

  const table = useReactTable({
    data: sentOrders, // Define data source
    columns, // Define all column props
    state: {
      columnVisibility
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(), // Used to enable pagination
    getFilteredRowModel: getFilteredRowModel(), // Used to enable filtering
    getSortedRowModel: getSortedRowModel(), // Enable sorting
    //  debugTable: true, // TODO: remove in production
  })

  useEffect(() => {
    if (!firstInit.current) {
      return;
    }
    firstInit.current = false;
    (async () => {
      setSentOrders(await getAllSentOrders());
    })();
  }, [])

  useEffect(() => {
    const columnProducts = table.getColumn('products');
    columnProducts?.setFilterValue(productSearch);
  }, [productSearch])

  useEffect(() => {
    const dateColumn = table.getColumn('dataSpedizioneId');
    dateColumn?.setFilterValue({
      fromDate: fromDate ? new Date(fromDate) : null,
      toDate: toDate ? new Date(toDate) : null
    });
  }, [fromDate, toDate]);


  const _showNote = (note: string) => {
    // TODO:
  }

  const _download = (order: SentOrder) => {
    // TODO:
  }


  return (
    <IonContent>
      <IonGrid class='full-flex-column'>
        <IonRow className="ion-margin-bottom">
          <IonCol>
            <IonText color="primary">
              <h1>ORDINI INVIATI</h1>
            </IonText>
          </IonCol>
        </IonRow>
        <IonRow className="ion-margin-bottom">
          <IonCol size="12" sizeMd="8">
            <IonItem>
              <IonInput
                value={productSearch ?? ''}
                onIonInput={({ detail: { value } }) => setProductSearch(value as string)}
                placeholder="RICERCA PRODOTTI"
              />
              <IonIcon icon={searchOutline} slot="end"></IonIcon>
            </IonItem>
          </IonCol>
          <IonCol size='12' sizeMd="2">
            <DatePicker placeholder="Da Data" onChange={setFromDate}></DatePicker>
          </IonCol>
          <IonCol size='12' sizeMd="2">
            <DatePicker placeholder="A Data" onChange={setToDate}></DatePicker>
          </IonCol>
        </IonRow>
        <IonRow class="flex-fill">
          <CustomTable table={table} totalLabel="Ordini totali: "></CustomTable>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default OrdiniInviati;