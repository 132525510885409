import { useHistory, useLocation } from "react-router";
import { CustomerState } from "./SchedeCliente";
import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonRow, IonText } from "@ionic/react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { arrowRedoOutline, clipboardOutline, fileTrayStackedOutline, walletOutline } from "ionicons/icons";

import './SchedeClienteDetail.css';
import InvoicesTable from "../../../components/Table/InvoicesTable";
import TransportDocumentsTable from "../../../components/Table/TransportDocumentsTable";
import CustomerOrdersTable from "../../../components/Table/CustomerOrdersTable";
import PartiteTable from "../../../components/Table/PartiteTable";

const SchedeClienteDetail: React.FC = () => {
    const { state } = useLocation<CustomerState>();
    const history = useHistory();
    const { customer } = state ?? {};

    // go back to selection customer if no 
    if (!customer) {
        history.replace('/admin/schede-cliente');
        return null;
    }

    return (
        <IonContent>
            <IonGrid class="full-flex-column">
                <IonRow className="">
                    <IonCol>
                        <IonText>
                            <h3>ELENCO CLIENTI</h3>
                        </IonText>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonText color="primary">
                            <h1>Scheda cliente</h1>
                        </IonText>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonText>
                            <h1>
                                {customer.description}
                            </h1>
                        </IonText>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonText>
                            <p>{customer.address}, {customer.city} {`${customer.zipCode ? '(' + customer.zipCode + ')' : ''}`}</p>
                        </IonText>
                    </IonCol>
                </IonRow>
                <IonRow class="">
                    <IonCol size="auto">
                        <IonText><b>P.IVA:</b></IonText>
                    </IonCol>
                    <IonCol size="auto">
                        <IonText color="medium">{customer.vatNumber || '---'}</IonText>
                    </IonCol>
                    <IonCol size="auto">
                        <IonText><b>C.F:</b></IonText>
                    </IonCol>
                    <IonCol size="auto">
                        <IonText color="medium">{customer.taxIdCode || '---'}</IonText>
                    </IonCol>
                    <IonCol size="auto">
                        <IonText><b>ABI:</b></IonText>
                    </IonCol>
                    <IonCol size="auto">
                        <IonText color="medium">{customer.abi || '---'}</IonText>
                    </IonCol>
                    <IonCol size="auto">
                        <IonText><b>CAB:</b></IonText>
                    </IonCol>
                    <IonCol size="auto">
                        <IonText color="medium">{customer.cab || '---'}</IonText>
                    </IonCol>
                </IonRow>
                <IonRow class="ion-margin-bottom">
                    <IonCol size="auto">
                        <IonText><b>Modalità di pagamento:</b></IonText>
                    </IonCol>
                    <IonCol size="auto">
                        <IonText color="medium">{customer.paymentType || '---'}</IonText>
                    </IonCol>
                </IonRow>

                <Tabs className='tabs-tables flex-fill'>
                    <TabList>
                        <Tab>
                            <IonButton fill="clear" size="large" >
                                <IonIcon slot="start" icon={walletOutline} size="large"></IonIcon>
                                Fatture / NC
                            </IonButton>
                        </Tab>
                        <Tab>
                            <IonButton fill="clear" size="large">
                                <IonIcon slot="start" icon={clipboardOutline} size="large"></IonIcon>
                                Bolle
                            </IonButton>
                        </Tab>
                        <Tab>
                            <IonButton fill="clear" size="large">
                                <IonIcon slot="start" icon={fileTrayStackedOutline} size="large"></IonIcon>
                                Ordini
                            </IonButton>

                        </Tab>
                        <Tab>
                            <IonButton fill="clear" size="large">
                                <IonIcon slot="start" icon={arrowRedoOutline} size="large"></IonIcon>
                                Partite
                            </IonButton>

                        </Tab>
                    </TabList>

                    <TabPanel>
                        <InvoicesTable customerId={customer.customerId}></InvoicesTable>
                    </TabPanel>
                    <TabPanel>
                        <TransportDocumentsTable customerId={customer.customerId}></TransportDocumentsTable>
                    </TabPanel>
                    <TabPanel>
                        <CustomerOrdersTable customerId={customer.customerId}></CustomerOrdersTable>
                    </TabPanel>
                    <TabPanel>
                        <PartiteTable customerId={customer.customerId}></PartiteTable>
                    </TabPanel>
                </Tabs>

            </IonGrid>
        </IonContent>
    )
}

export default SchedeClienteDetail