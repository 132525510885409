import { Redirect, Route, Switch, useRouteMatch } from "react-router";

import ListinoBase from "./ListinoBase/ListinoBase";
import NuovoOrdine from "./NuovoOrdine";
import OrdiniInviati from "./OrdiniInviati/OrdiniInviati";
import OrdiniDraft from "./OridiniDraft/DraftOrders";
import ListiniPersonalizzati from "./ListiniPersonalizzati/ListiniPersonalizzati";
import ListiniPersonalizzatiDetail from "./ListiniPersonalizzati/ListiniPersonalizzatiDetail";

const OrdiniMain: React.FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/nuovo-ordine`} component={NuovoOrdine} />
      <Route path={`${path}/ordini-draft`} component={OrdiniDraft} />
      <Route path={`${path}/ordini-inviati`} component={OrdiniInviati} />
      <Route path={`${path}/listino-base`} component={ListinoBase} />
      <Route exact={true} path={`${path}/listini-personalizzati`} component={ListiniPersonalizzati} />
      <Route path={`${path}/listini-personalizzati/detail`} component={ListiniPersonalizzatiDetail} />
      <Redirect exact={true} path={path} to={`${path}/nuovo-ordine`} />
    </Switch>
  );
};

export default OrdiniMain;