import camelcaseKeys from 'camelcase-keys';

class Helper {
    static clean(results) {
        return results.map(row => camelcaseKeys(row));
    }

    static getNowDate() {
        const nowDate = new Date();
        return Helper.convertDate(nowDate);
    }

    static convertDate(date) {
        if (!date) {
            return null;
        }
        if (!(date instanceof Date)) {
            throw new Error('Need to pass a Date object.');
        }
        const pad = function (num) {
            return (num < 10 ? '0' : '') + num;
        };
        return `${date.getFullYear()}${pad(date.getMonth() + 1)}${pad(date.getDate())}`;
    }
}

export default Helper;