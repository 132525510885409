import { useEffect, useMemo, useRef, useState } from "react";
import CustomTable from "./CustomTable";
import { ColumnDef, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { DraftOrder, useOrdersDraft } from "../../hooks/useDraftOrders";
import { convertBaseCurrency, convertNumberToLocale } from "../../utils/newOrderHelpers";
import { IonButton, IonIcon } from "@ionic/react";
import { pencil } from "ionicons/icons";
import { useHistory } from "react-router";
import { useMobileMediaQuery } from "../../hooks/useAppMediaQuery";

const LastDraftOrderTable: React.FC = () => {
    const firstInit = useRef(true);
    const { replace } = useHistory();

    const { getOrdersDraft } = useOrdersDraft();
    const [ordersDraft, setOrdersDraft] = useState<DraftOrder[]>([]);
    const isMobile = useMobileMediaQuery();
    // Define columns for the table
    const draftColumns = useMemo<ColumnDef<DraftOrder, any>[]>(
        () => [
            {
                accessorKey: 'id',
                header: 'N. Ordine',
                enableGlobalFilter: false,
                meta: {
                    mobileHeader: true
                }
            },
            {
                id: 'modificationDateId',
                header: 'Modificato Il',
                enableGlobalFilter: false,
                accessorFn: (row) => {
                    return row.modificationDate ? new Date(row.modificationDate * 1000) : null;
                },
                cell: ({ getValue }) => {
                    const value = getValue();
                    const date = value ? value.toLocaleDateString('it-IT') : '---'; // TODO: add correct locale from app
                    return date;

                }
            },
            {
                accessorKey: 'customerId',
                header: 'Cliente',
                enableGlobalFilter: false,
            },
            {
                header: 'Destinatario',
                accessorFn: (row) => {
                    return `${row.businessName} ${row.city} ${row.address}`
                },
                enableSorting: false,
            },
            {
                header: 'Pezzi',
                accessorFn: (row) => {
                    return row.productRows?.length || '---';
                },
                enableGlobalFilter: false,
            },
            {
                header: 'Imponibile',
                accessorFn: (row) => {
                    return row.amount ? `${convertBaseCurrency('EUR')} ${convertNumberToLocale(row.amount)}` : '---'
                },
                enableGlobalFilter: false,
            },
            {
                id: 'actionId',
                cell: () => {
                    return (
                        <IonButton class={isMobile ? 'small-circle-icon' : 'medium-circle-icon'} onClick={() => replace('/ordini/ordini-draft')}>
                            <IonIcon slot='icon-only' icon={pencil}></IonIcon>
                        </IonButton >
                    )
                }
            }
        ],
        []
    );

    const draftTable = useReactTable({
        data: ordersDraft, // Define data source
        columns: draftColumns, // Define all column props
        getCoreRowModel: getCoreRowModel(),
        //  debugTable: true, // TODO: remove in production
    });

    useEffect(() => {
        if (!firstInit.current) {
            return;
        }
        firstInit.current = false;
        (async () => {
            await _getData();
        })()
    }, []);

    const _getData = async () => {
        const { data, status } = await getOrdersDraft(4) ?? {}; // Get last 4 order draft
        if (!data) {
            return;
        }
        if (!status) {
            // TODO: show generic alert error
            console.error(status, data);
            return;
        }
        setOrdersDraft(data ?? []);
    }

    return (
        <CustomTable showTotalLabel={false} showPageButtons={false} table={draftTable} hideHeader={true}></CustomTable>
    )
}

export default LastDraftOrderTable