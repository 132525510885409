import {
  IonAvatar,
  IonCol,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonMenuButton,
  IonPopover,
  IonRow,
  IonText,
  IonToolbar
} from "@ionic/react";
import * as IonIcons from "ionicons/icons";

import { useAuth } from "../../Context/AuthenticationContext";
import logo from "../../assets/logo_irca_red.svg";
import avatar from "../../assets/avatar.svg";
import "./Header.css";
import { useMobileMediaQuery } from "../../hooks/useAppMediaQuery";
import { useHistory } from "react-router";
import { useCompany } from "../../Context/CompanyContext";
import { useNetwork } from "../../Context/NetworkContext";

const Header: React.FC = () => {
  const { isLoggedIn, loginData, logout } = useAuth();
  const { removeCompany } = useCompany();
  const { isOnline } = useNetwork();
  const history = useHistory();

  const isMobile = useMobileMediaQuery(); // TODO : fix this

  const welcomeText = `Benvenuto, ${loginData?.jwt.fullName}`; // TODO: add translation

  const _logout = async () => {
    await logout();
    await removeCompany();
    history.replace('/');
    // window.location.reload();
  }

  const _profile = () => {
    history.push('/profile');
  }

  const _company = () => {
    history.push('/company', { modify: true });
  }

  return (
    <IonHeader collapse="fade">
      <IonToolbar>
        <IonGrid>
          <IonRow class='ion-align-items-center'>
            <IonMenuButton color='primary'></IonMenuButton>
            <IonCol>
              <IonText color="primary">
                <img alt="Company Logo" src={logo} />
              </IonText>
            </IonCol>
            <IonCol>
              <IonText color="primary">{isOnline ? 'ONLINE' : 'OFFLINE'}</IonText>
            </IonCol>
            {isLoggedIn && (
              <IonCol size="auto">
                <IonGrid class="ion-no-padding">
                  <IonRow class='ion-align-items-center'>
                    {!isMobile && (
                      <IonCol>
                        <IonText class='ion-margin-end ion-text-nowrap'>
                          {welcomeText}
                        </IonText>
                      </IonCol>
                    )}

                    <IonCol>
                      <IonAvatar id="click-trigger" style={{ height: "36px", width: "36px" }}>
                        <img alt="Silhouette of a person's head" src={avatar} />
                      </IonAvatar>
                      <IonPopover backdropDismiss={true} dismissOnSelect={true} trigger="click-trigger" triggerAction="click" mode="ios">
                        {isMobile && (
                          <IonItem>{welcomeText}</IonItem>
                        )}
                        <IonItem lines="none" onClick={_company}>
                          <IonIcon slot='start' icon={IonIcons.briefcaseOutline} ></IonIcon>
                          Cambia company
                        </IonItem>
                        <IonItem lines="none" onClick={_profile}>
                          <IonIcon slot='start' icon={IonIcons.personOutline}></IonIcon>
                          Profilo
                        </IonItem>
                        <IonItem onClick={_logout} lines="none">
                          <IonIcon slot='start' icon={IonIcons.exitOutline}></IonIcon>
                          Logout
                        </IonItem>
                      </IonPopover>
                    </IonCol>

                  </IonRow>
                </IonGrid>
              </IonCol>
            )}
          </IonRow>
        </IonGrid>
      </IonToolbar>
    </IonHeader >

  );
};

export default Header;