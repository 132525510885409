import Helper from "./helper.js";
class Salesmen {
    constructor({
        knex,
        onlyQuery = false,
    }) {
        this._knex = knex;
        this._onlyQuery = onlyQuery;
    };

    async _handleQueryBuilder(queryBuilder) {
        if (this._onlyQuery) {
            return queryBuilder.toString();
        }
        return Helper.clean(await queryBuilder);
    }

    async getCustomers({
        salesmanId,
        managedSalesmen = [],
        onlyEnableToOrder = true, // TODO: check this default
    }) {
        if (!salesmanId) {
            throw new Error('Missing salesmanId param!');
        }
        const queryBuilder = this._knex.select(['vwdc.*', 'vwds.ADDRESS AS SHIP_TO_ADDRESS', 'vwds.CITY AS SHIP_TO_CITY']);
        queryBuilder
            .from('v_web_DED_CUSTOMERS as vwdc')
            .leftJoin('v_web_DED_SHIPTO as vwds', function () {
                this.on('vwdc.CUSTOMER_ID', '=', 'vwds.CUSTOMER_ID');
            })
            .whereIn('vwdc.SALESMAN', [salesmanId, ...managedSalesmen ?? []])
            .orderBy('vwdc.CUSTOMER_ID', 'asc');

        if (onlyEnableToOrder) {
            queryBuilder.andWhere('vwdc.STATO', '=', 20);
        }
        return await this._handleQueryBuilder(queryBuilder);
    }

    async getCustomersOrdersSummary({
        salesmanId,
        managedSalesmen = [],
        limit,
        company,
    }) {
        // TODO: if want detail 
        // const allDates = aggregatedByDate.map((row) => row.DATE);
        // const deatilQuery = `
        //         SELECT vcos.DATAORDINE AS DATE, vcos.CUSTOMER_ID, SUM(vcos.IMPORTORIGA) AS AMOUNT
        //         FROM v_CUSTOMERS_ORDERS_SUMMARY vcos 
        //         WHERE vcos.SALESMAN IN (${inConditionParams}) AND vcos.DATAORDINE IN (${allDates.join(',')}) ${company ? 'AND vcos.COMPANY = @company' : ''}
        //         GROUP BY vcos.DATAORDINE, vcos.CUSTOMER_ID
        //         ORDER BY vcos.DATAORDINE DESC
        //     `;
        if (!salesmanId) {
            throw new Error('Missing salesmanId param!');
        }
        const queryBuilder = this._knex.select(['vcos.DATAORDINE AS DATE']);
        queryBuilder
            .sum('vcos.IMPORTORIGA AS AMOUNT')
            .from('v_CUSTOMERS_ORDERS_SUMMARY AS vcos')
            .whereIn('vcos.SALESMAN', [salesmanId, ...managedSalesmen ?? []])
            .groupBy('vcos.DATAORDINE')
            .orderBy('vcos.DATAORDINE', 'desc');
        if (company) {
            queryBuilder.andWhere('vcos.COMPANY', '=', company);
        }
        if (limit) {
            queryBuilder.offset(0);
            queryBuilder.limit(parseInt(limit));
        }
        return this._handleQueryBuilder(queryBuilder);
    }

    async getOrders({
        salesmanId,
        managedSalesmen,
        fromDate,
        toDate,
        company,
    }) {
        if (!salesmanId) {
            throw new Error('Missing salesmanId param!');
        }
        const arraySalesmen = [salesmanId, ...managedSalesmen ?? []];

        const knexQueryRowNumber = this._knex.select('vwdoh.NUMEROORDINE')
            .count('*', { as: 'ITEMS' })
            .from('v_web_DED_ORDINI_HEAD AS vwdoh')
            .innerJoin('v_web_DED_ORDINI_ROW AS vwdor', function () {
                this.on('vwdoh.NUMEROORDINE', '=', 'vwdor.NUMEROORDINE');
            })
            .whereIn(' vwdoh.SALESMAN_ID', arraySalesmen)
            .groupBy('vwdoh.NUMEROORDINE');
        if (company) {
            knexQueryRowNumber.andWhere('vwdoh.COMPANY', '=', company);
        }
        const queryRowNumber = knexQueryRowNumber.toString();

        const knexQueryRowAlcool = this._knex.select('vwdoh.NUMEROORDINE')
            .count('vwdp.FLAG_ALCOL', { as: 'ALCOOL' })
            .from('v_web_DED_ORDINI_HEAD AS vwdoh')
            .innerJoin('v_web_DED_ORDINI_ROW AS vwdor', function () {
                this.on('vwdoh.NUMEROORDINE', '=', 'vwdor.NUMEROORDINE');
            })
            .innerJoin('v_web_DED_PRODUCTS AS vwdp', function () {
                this.on('vwdor.ARTICOLO', '=', 'vwdp.ITEM_ID');
                if (company) {
                    this.onIn('vwdp.COMPANY', [company]);
                }
            })
            .whereIn(' vwdoh.SALESMAN_ID', arraySalesmen)
            .groupBy('vwdoh.NUMEROORDINE');
        if (company) {
            knexQueryRowAlcool.andWhere('vwdoh.COMPANY', '=', company);
        }
        const queryRowAlcool = knexQueryRowAlcool.toString();

        const ret = this._knex.select([
            'vwdoh.NUMEROORDINE AS NUMERO_ORDINE',
            'vwdoh.SALESMAN_ID',
            'vwdoh.SALESMAN',
            'vwdoh.CUSTOMER_ID',
            'vwdoh.RAGIONE_SOCIALE',
            'vwdoh.ADDRESS',
            'vwdoh.CITY',
            'vwdoh.DATAORDINE AS DATA_ORDINE',
            'vwdoh.DATA_SPEDIZIONE',
            'vwdoh.IMPORTO',
            'vwdoh.NOTE',
            'rows_numbers.ITEMS',
            'flag_alcol.ALCOOL',
        ])
            .fromRaw(
                `v_web_DED_ORDINI_HEAD vwdoh
                left join (${queryRowNumber}) AS rows_numbers ON [vwdoh].[NUMEROORDINE] = [rows_numbers].[NUMEROORDINE] 
                left join (${queryRowAlcool}) AS flag_alcol ON [vwdoh].[NUMEROORDINE] = [flag_alcol].[NUMEROORDINE]`)
            .whereIn('vwdoh.SALESMAN_ID', arraySalesmen);
        if (fromDate && toDate) {
            ret.whereBetween('vwdoh.DATAORDINE', [Helper.convertDate(fromDate), Helper.convertDate(toDate)]);
        }
        return this._handleQueryBuilder(ret);
    }

    async getBasicProductList({
        company,
    }) {
        const queryBuilder = this._knex.select(['wlb.*', 'wlb.SALES_PRICE AS PRICE']);

        queryBuilder.from('v_LISTINO_BASE AS wlb');
        if (company) {
            queryBuilder.where('wlb.COMPANY', '=', company);
            queryBuilder.orWhere('wlb.COMPANY ', '=', 'IRCA');
        }
        return this._handleQueryBuilder(queryBuilder);
    }
}


export default Salesmen;