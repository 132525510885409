import {
    IonAccordion,
    IonAccordionGroup,
    IonButton,
    IonCol,
    IonGrid,
    IonIcon,
    IonItem,
    IonLabel,
    IonRow,
    IonText
} from "@ionic/react";
import {
    FilterFn,
    flexRender, Row, RowData, Table
} from "@tanstack/react-table";
import { rankItem } from '@tanstack/match-sorter-utils'
import '@tanstack/react-table'

import start from "../../assets/table/table_start.svg";
import prev from "../../assets/table/table_prev.svg";
import next from "../../assets/table/table_next.svg";
import end from "../../assets/table/table_end.svg";
import sort_neutral from "../../assets/table/table_sort_neutral.svg";
import sort_asc from "../../assets/table/table_sort_asc.svg";
import sort_desc from "../../assets/table/table_sort_desc.svg";

import './CustomTable.css';
import { useCallback, useEffect, useRef } from "react";
import { useMobileMediaQuery, useTabletMediaQuery } from "../../hooks/useAppMediaQuery";

declare module '@tanstack/table-core' {
    interface ColumnMeta<TData extends RowData, TValue> {
        mobileHeader?: boolean,
        mobileHeaderPosition?: number
        minWidth?: string
        width?: string
        maxWidth?: string
    }
    interface CellContext<TData extends RowData, TValue> {
        fromMobileHeader: boolean;
    }
}

export interface TableProps {
    table: Table<any>;
    showPageButtons?: boolean;
    showTotalLabel?: boolean;
    totalLabel?: string;
    pageButtons?: {
        desktopNumber: number;
    },
    clickRowCallback?: (row: Row<any>) => void;
    hideHeader?: boolean;
}

export function useSkipper() {
    const shouldSkipRef = useRef(true);
    const shouldSkip = shouldSkipRef.current;

    // Wrap a function with this to skip a pagination reset temporarily
    const skip = useCallback(() => {
        shouldSkipRef.current = false;
    }, []);

    useEffect(() => {
        shouldSkipRef.current = true;
    });

    return [shouldSkip, skip] as const;
}


export const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value)

    // Store the itemRank info
    addMeta({
        itemRank,
    })

    // Return if the item should be filtered in/out
    return itemRank.passed
}

export const dateFilterFn: FilterFn<any> = (row, columnId, filterValue: { fromDate: Date | null, toDate: Date | null }) => {
    const rowDateValue: Date = row.getValue(columnId);
    const { fromDate, toDate } = filterValue;
    let filterRetValue = true;
    if (fromDate) {
        filterRetValue = rowDateValue >= fromDate;
    }
    if (toDate) {
        filterRetValue = filterRetValue && rowDateValue <= toDate;
    }
    return filterRetValue;
}

const CustomTable: React.FC<TableProps> = ({
    table,
    showPageButtons = true,
    totalLabel = 'Numero delle row',
    pageButtons = { desktopNumber: 7 },
    clickRowCallback,
    showTotalLabel = true,
    hideHeader = false,
}) => { // TODO: add translation
    const isMobile = useMobileMediaQuery();
    const isTablet = useTabletMediaQuery();


    const _renderPageButtons = (desktopNumberButton?: number) => {
        // TODO: many rendere :( enable logs
        // TODO: mobile style
        // TODO: Mobile slider with all page button
        // Desktop page buttons plus button prev next etc..

        // total pages    
        const totalPages = table.getPageCount()
        // Page selected
        const selected = table.getState().pagination.pageIndex + 1;
        let numberButton;
        let startPage;
        if (desktopNumberButton !== undefined && desktopNumberButton !== null) {
            // Number of button to render
            numberButton = desktopNumberButton;
            if (totalPages < desktopNumberButton) {
                numberButton = totalPages;
            }
            startPage = selected <= numberButton ? 1 : (selected - numberButton) + 1;
        } else {
            numberButton = totalPages;
            startPage = 1;
        }

        let buttons = [];
        for (let i = startPage; i < startPage + numberButton; i++) {
            buttons.push((
                <IonButton
                    key={i}
                    size="small"
                    fill="solid"
                    class='small-circle-icon'
                    color={i === selected ? 'white' : 'light'}
                    onClick={() => table.setPageIndex(i - 1)}
                >
                    {i}
                </IonButton>
            ))
        }

        return buttons;
    }

    const _clickRow = (e: any, row: Row<any>) => {
        e.preventDefault()
        e.stopPropagation()

        if (!(e.target instanceof HTMLInputElement)) {
            let activeElement = document.activeElement as HTMLElement;
            activeElement && activeElement.blur && activeElement.blur();
        }
        if (!clickRowCallback) {
            return;
        }
        clickRowCallback(row);
    }

    const _renderTable = () => {
        return isMobile ? _renderAccordionTable() : _renderFullTable()
    }

    const _renderAccordionTable = () => {
        return (
            <div className="irca-table-mobile">
                <IonAccordionGroup >
                    {
                        table.getRowModel().rows.map((row, index) => {
                            const visibleCells = row.getVisibleCells();
                            // get the columns set for the accordion label
                            const mobileHeaders = visibleCells
                                .filter(cell => cell.column.columnDef.meta?.mobileHeader)
                                .sort((cellA, cellB) => (cellA.column.columnDef.meta?.mobileHeaderPosition ?? 0) - (cellB.column.columnDef.meta?.mobileHeaderPosition ?? 0))

                            return (
                                <IonAccordion key={row.id} value={row.id}>
                                    <IonItem slot="header" color={index % 2 ? 'light' : 'white'}>
                                        <IonLabel>
                                            <IonRow class="ion-align-items-center ion-text-wrap">
                                                {
                                                    mobileHeaders.map(cell => {
                                                        return (
                                                            <div key={cell.id} className="ion-margin-end">
                                                                {flexRender(
                                                                    cell.column.columnDef.cell,
                                                                    { ...cell.getContext(), fromMobileHeader: true }
                                                                )}
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </IonRow>
                                        </IonLabel>
                                    </IonItem>
                                    <IonGrid className="ion-padding accordion-content" slot="content" onClick={(e) => { _clickRow(e, row) }}>
                                        {visibleCells.map(cell => {
                                            const cellColumnHeader = cell.column.columnDef.header

                                            return (
                                                <IonRow key={cell.id} class=" ion-align-items-top ion-padding-top ion-padding-bottom">
                                                    {cellColumnHeader && typeof cellColumnHeader === 'string' && (
                                                        <IonCol>{cellColumnHeader}</IonCol>
                                                    )}
                                                    <IonCol>
                                                        {flexRender(
                                                            cell.column.columnDef.cell,
                                                            cell.getContext()
                                                        )}
                                                    </IonCol>
                                                </IonRow>
                                            )
                                        })}
                                    </IonGrid>
                                </IonAccordion>
                            )
                        })
                    }
                </IonAccordionGroup>
                {showTotalLabel && (
                    <IonRow class="ion-justify-content-center ion-margin-top">
                        <p>{totalLabel} {table.getPrePaginationRowModel().rows.length}</p>
                    </IonRow>
                )}
                {showPageButtons && table.getPageCount() > 1 && (
                    <div className="page-buttons scroll-buttons">{
                        _renderPageButtons()
                    }</div>
                )}
            </div>
        )
    }

    const _renderFullTable = () => (
        <div className={`irca-table${isTablet ? ' condensed' : ''}`}>
            <table>
                {!hideHeader && (
                    <thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => {
                                    return (
                                        <th
                                            key={header.id}
                                            colSpan={header.colSpan}
                                            style={{
                                                minWidth: header.column.columnDef.meta?.minWidth,
                                                width: header.column.columnDef.meta?.width,
                                                maxWidth: header.column.columnDef.meta?.maxWidth
                                            }}
                                        >
                                            {header.isPlaceholder ? null : (
                                                <IonRow onClick={header.column.getToggleSortingHandler()} class="ion-align-items-center">
                                                    {flexRender(
                                                        header.column.columnDef.header,
                                                        { ...header.getContext(), fromMobileHeader: true }
                                                    )}
                                                    {header.column.getCanSort() && (
                                                        <>
                                                            {{
                                                                asc: (<IonIcon src={sort_asc}></IonIcon>),
                                                                desc: (<IonIcon src={sort_desc}></IonIcon>),
                                                            }[header.column.getIsSorted() as string] ?? (<IonIcon src={sort_neutral}></IonIcon>)}
                                                        </>
                                                        // 
                                                    )}
                                                </IonRow>
                                            )
                                            }
                                        </th>
                                    )
                                })}
                            </tr>
                        ))}
                    </thead>
                )}
                <tbody>
                    {table.getRowModel().rows.map(row => {
                        return (
                            <tr key={row.id} onClick={(e) => { _clickRow(e, row) }} className={`${clickRowCallback ? 'clickable' : ''}`}>
                                {row.getVisibleCells().map(cell => {
                                    return (
                                        <td key={cell.id}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            {showPageButtons && table.getPageCount() > 1 && (
                <IonGrid class="page-buttons">
                    <IonRow class="ion-align-items-center">
                        <IonCol></IonCol> {/* NOT REMOVE */}
                        <IonCol size="auto">
                            <IonRow class="ion-align-items-center">
                                <IonButton
                                    color='light'
                                    fill="outline"
                                    class='medium-circle-icon ion-margin-end'
                                    onClick={() => table.setPageIndex(0)}
                                    disabled={!table.getCanPreviousPage()}
                                >
                                    <IonIcon
                                        slot="icon-only"
                                        src={start}
                                    />
                                </IonButton>
                                <IonButton
                                    color='light'
                                    fill="outline"
                                    class='medium-circle-icon ion-margin-end'
                                    onClick={() => table.previousPage()}
                                    disabled={!table.getCanPreviousPage()}
                                >
                                    <IonIcon
                                        slot="icon-only"
                                        src={prev}
                                    />
                                </IonButton>
                                <IonRow class="page-buttons">
                                    {_renderPageButtons(pageButtons.desktopNumber)}
                                </IonRow>

                                <IonButton
                                    color='light'
                                    fill="outline"
                                    class='medium-circle-icon ion-margin-start'
                                    onClick={() => table.nextPage()}
                                    disabled={!table.getCanNextPage()}
                                >
                                    <IonIcon
                                        slot="icon-only"
                                        src={next}
                                    />
                                </IonButton>
                                <IonButton
                                    color='light'
                                    fill="outline"
                                    class='medium-circle-icon ion-margin-start'
                                    onClick={() =>
                                        table.setPageIndex(table.getPageCount() - 1)
                                    }
                                    disabled={!table.getCanNextPage()}
                                >
                                    <IonIcon
                                        slot="icon-only"
                                        src={end}
                                    />
                                </IonButton>
                            </IonRow>
                        </IonCol>
                        <IonCol hidden={!showTotalLabel}>
                            <IonRow class="ion-justify-content-end">
                                <p>{totalLabel} {table.getPrePaginationRowModel().rows.length}</p>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            )}
        </div>
    )

    return (
        <>
            {
                table.getRowModel().rows.length <= 0 ? (
                    <IonRow class="ion-irca-table-nodata ion-justify-content-center ion-align-items-center">
                        <IonText class="ion-align-items-center">
                            <h2>Nessun Dato</h2>
                        </IonText>
                    </IonRow>
                ) : _renderTable()
            }
        </>
    );
}

export default CustomTable;