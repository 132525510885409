export class FetchError extends Error {
    status: number;
    constructor(status: number, message: string) {
        super(message);
        this.status = status;
    }
}

export const smartFetch = async (
    {
        url,
        options,
        parseBody = true
    }: {
        url: string,
        options: RequestInit,
        parseBody?: boolean
    }
) => {
    const response = await fetch(url, options);
    if (!response.ok) {
        // TODO: remove
        debugger;
        let errorMessage;
        if (!errorMessage) {
            try {
                const { message } = await response.json();
                errorMessage = message;
            } catch (err) {
                console.error(err);
            }
        }
        errorMessage = errorMessage ?? response.statusText
        throw new FetchError(response.status, errorMessage);
    }
    if (parseBody) {
        return await response.json();
    }
    return await response.text();
}

export const smartFetchBlob = async (
    {
        url,
        options,
    }: {
        url: string,
        options: RequestInit,
        parseBody?: boolean
    }) => {

    const response = await fetch(url, options);
    if (!response.ok) {
        // TODO: remove
        debugger;
        let errorMessage;
        if (!errorMessage) {
            try {
                const { message } = await response.json();
                errorMessage = message;
            } catch (err) {
                console.error(err);
            }
        }
        errorMessage = errorMessage ?? response.statusText
        throw new FetchError(response.status, errorMessage);
    }
    return response.blob();
}