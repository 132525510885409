import type { PropsWithChildren } from "react";
import { createContext, useContext, useEffect, useState } from "react";

export interface EnvContext {
    getEnv: (envName: string) => string
}

const EnvContext = createContext<EnvContext | undefined>(undefined);

export const useEnv = (): EnvContext => {
    const context = useContext(EnvContext);
    if (!context) throw new Error('No EnvProvider in context');
    return context;
};

export const EnvProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [envs, setEnvs] = useState<{ [key: string]: any }>();

    useEffect(() => {
        setEnvs(
            Object.fromEntries(
                Object.entries(process.env)
                    .filter(([key, value]) => key.includes('REACT_APP_'))
                    .map(([key, value]) => [key.replace('REACT_APP_', ''), value])
            )
        );
    }, []);

    const getEnv = (envName: string) => {
        if (!envs) {
            return;
        }
        return envs[envName];
    }

    const context: EnvContext = { getEnv };

    return (
        <EnvContext.Provider value={context}>{children}</EnvContext.Provider>
    );
};