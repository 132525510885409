import type { PropsWithChildren } from "react";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useAuth } from "./AuthenticationContext";
import { isPlatform } from "@ionic/react";
import Cookie from 'universal-cookie';
import { mainDatabase } from "../App";
import Database, { DefinitionTable } from "../utils/database";

export interface CompanyContextI {
    selectCompany: (company: string) => void;
    removeCompany: () => Promise<void>;
    selectableCompanies: string[];
    company: string | null;
}

const tableCompany: DefinitionTable = {
    name: 'company',
    columns: [
        {
            name: 'selected',
            type: 'TEXT',
            default: 'NULL'
        },
    ],
}

const CompanyContext = createContext<CompanyContextI | undefined>(undefined);

export const useCompany = (): CompanyContextI => {
    const context = useContext(CompanyContext);
    if (!context) throw new Error('No CompanyProvider in context');
    return context;
};

export const CompanyProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { loginData } = useAuth();
    const [init, setInit] = useState(false);
    const [company, setCompany] = useState<string | null>(null);
    const [selectableCompanies, setSelectableCompanies] = useState<string[]>([]);

    useEffect(() => {
        if (loginData === undefined) {
            return;
        }
        if (loginData === null) {
            setInit(true);
            return;
        }
        setSelectableCompanies(loginData.jwt.companies);
    }, [loginData])

    useEffect(() => {
        (async () => {
            if (selectableCompanies.length > 0) {
                let companySaved: string | null = null;
                // Get saved data from sqlite
                if (isPlatform('capacitor')) {
                    console.log('GET COMPANY FROM DB')
                    companySaved = await _recoverCompanyFromDb();
                } else { // get From cookie
                    const cookie = new Cookie();
                    companySaved = cookie.get('company') ?? null;
                }
                if (companySaved) {
                    setCompany(companySaved);
                }
                setInit(true);
            }
        })();
    }, [selectableCompanies]);

    const _saveCompanyIntoDb = async (company: string) => {
        await mainDatabase.execute({ query: Database.getCreationSqlFromDefinition(tableCompany) });
        await mainDatabase.execute({ query: `DELETE FROM ${tableCompany.name}` });
        await mainDatabase.query({
            query: `INSERT INTO ${tableCompany.name} (${tableCompany.columns.map(c => `${c.name}`).join(',')}) VALUES (${tableCompany.columns.map((c) => '?').join(',')})`,
            queryValues: [company]
        });
    }

    const _recoverCompanyFromDb = async (): Promise<string | null> => {
        await mainDatabase.execute({ query: Database.getCreationSqlFromDefinition(tableCompany) });
        const [recoverCompany] = await mainDatabase.query({ query: `SELECT * FROM ${tableCompany.name}` }) ?? []
        return recoverCompany?.selected ?? null;
    }


    const selectCompany = async (newCompany: string) => {
        if (!loginData) {
            throw new Error('Qualcosa è andato storto!');
        }
        if (company === newCompany) {
            return;
        }
        const ok = selectableCompanies.includes(newCompany);
        if (!ok) {
            throw new Error(`La company selezionata (${newCompany}) non è supportata!`);
        }

        // Use sqlite db
        if (isPlatform('capacitor')) {
            await _saveCompanyIntoDb(newCompany);
        } else { // Use cookie
            const cookie = new Cookie();
            cookie.set('company', newCompany, {
                secure: true,
                sameSite: 'lax',
                path: '/'
            })
        }
        setCompany(newCompany);
    }

    // TOD: make refresh the router
    const removeCompany = async () => {
        // Remove from sqlite
        if (isPlatform('capacitor')) {
            await _cleanTable();
        } else { // Remove from cookie
            const cookie = new Cookie();
            cookie.remove('company', { path: '/' });
        }
        setCompany(null);
    };

    const _cleanTable = async () => {
        await mainDatabase.execute({ query: `DELETE FROM ${tableCompany.name}` });
    }

    const context = useMemo(() => ({ selectCompany, selectableCompanies, company, removeCompany }), [selectableCompanies, company]);

    return (
        <CompanyContext.Provider value={context}>{init ? children : null}</CompanyContext.Provider>
    );
};