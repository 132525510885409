import { IonCol, IonContent, IonGrid, IonRow, IonText } from "@ionic/react"
import { useAuth } from "../../Context/AuthenticationContext"

const AgentProfile: React.FC = () => {
    const { loginData } = useAuth();

    if (!loginData) {
        return null;
    }

    return (
        <IonContent>
            <IonGrid>
                <IonRow className="ion-align-items-center">
                    <IonCol>
                        <IonText color="primary">
                            <h1>IL TUO PROFILO</h1>
                        </IonText>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonText color="black">
                            <h1>{loginData.jwt.fullName}</h1>
                        </IonText>
                    </IonCol>
                </IonRow>
                <IonRow class="ion-mergin-top">
                    <IonCol size="auto">
                        <IonText><b>Username:</b></IonText>
                    </IonCol>
                    <IonCol size="auto">
                        <IonText color="medium">{loginData.jwt.username || '---'}</IonText>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="auto">
                        <IonText><b>Email:</b></IonText>
                    </IonCol>
                    <IonCol size="auto">
                        <IonText color="medium">{loginData.jwt.email || '---'}</IonText>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="auto">
                        <IonText><b>Erp Id:</b></IonText>
                    </IonCol>
                    <IonCol size="auto">
                        <IonText color="medium">{loginData.jwt.erpId}</IonText>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="auto">
                        <IonText><b>Roles:</b></IonText>
                    </IonCol>
                    <IonCol size="auto">
                        <IonText color="medium">{loginData.jwt.roles.length ? loginData.jwt.roles.join(', ') : '---'}</IonText>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol size="auto">
                        <IonText><b>Salesmen Associati:</b></IonText>
                    </IonCol>
                    <IonCol size="auto">
                        <IonText color="medium">{loginData.jwt.managedSalesmen.length ? loginData.jwt.managedSalesmen.join(', ') : '---'}</IonText>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonContent>
    )
}

export default AgentProfile