// import { useAuth } from "../Context/AuthenticationContext";
import { useAuth } from "../Context/AuthenticationContext";
import { useEnv } from "../Context/EnvContext";
import { useNetwork } from "../Context/NetworkContext";
import { useApi, BACKEND_PATH, API_M3_PATH, BackendApi } from "./useApi";
import { Partita } from "./useCustomer";

export interface Salesman {
    companies: string[];
    email: string;
    erpId: string;
    favorites: string[];
    fullName: string;
    id: number;
    profilePicture: string | null;
    roles: string[];
}

export interface UnpaidRow {
    customerId: string;
    importo: number;
    scadenza: string;
    customerDescription: string;
    customerAddress: string;
    customerCity: string;
}

export interface BasicProduct {
    itemId: string;
    articoloDes: string;
    gruppoMerceologico: string;
    listino: string;
    pzConf: number;
    um: string;
    timeStamp: Date;
    flagAlcol: boolean;
    alias: string;
    status: string;
    disponibilita: number;
    supplierPref: string;
    supplierDes: string;
    company: string;
    convRate: number;
    qtaMax: number;
    valuta: string;
    price: number;
}

export interface CustomerOrderSummary {
    date: string,
    amount: number,
}

export interface CustomerOrderSummaryDetailed {
    date: string,
    amount: number,
    customerId: string
}

interface useSalesmanI {
    getAllInfo: () => Promise<BackendApi<Salesman>>;
    getFavorites: () => Promise<string[]>;
    saveFavorites: (favorites: string[]) => Promise<string[]>
    getBasicProductList: () => Promise<BasicProduct[]>
    getCustomersOrdersSummary: ({ limit, withDetail }: { limit: number, withDetail: boolean }) => Promise<CustomerOrderSummary[] | CustomerOrderSummaryDetailed[]>
}

export const useSalesman = (): useSalesmanI => {
    const { callApi } = useApi();
    const { getEnv } = useEnv();
    const { loginData } = useAuth();
    const { isOnline } = useNetwork();

    const getAllInfo = async (): Promise<BackendApi<Salesman>> => {
        // Get base gateway url
        const gatewayBase = getEnv('GATEWAY_BASE_URL');
        const url = new URL(`${gatewayBase}/${BACKEND_PATH}/salesmen`);
        return await callApi({
            url: url.toString(),
            method: 'GET',
        })
    }

    const getFavorites = async (): Promise<string[]> => {
        // disable favorites offline
        if (!isOnline) return [];
        const gatewayBase = getEnv('GATEWAY_BASE_URL');
        const url = new URL(`${gatewayBase}/${BACKEND_PATH}/salesmen`);
        const response = await callApi({
            url: url.toString(),
            method: 'GET',
            hideLoading: true,
        });
        if (!response?.data) {
            throw new Error('missing salesman data');
        }
        return response.data.favorites;
    }
    // Return the new favorites
    const saveFavorites = async (favorites: string[]): Promise<string[]> => {
        const gatewayBase = getEnv('GATEWAY_BASE_URL');
        const url = new URL(`${gatewayBase}/${BACKEND_PATH}/salesmen`);
        const { data }: BackendApi<Salesman> = await callApi({
            url: url.toString(),
            method: 'PUT',
            body: JSON.stringify({
                favorites,
            }),
            hideLoading: true,
        })
        return data?.favorites ?? [];
    }

    const getBasicProductList = () => {
        if (!loginData) {
            throw new Error('Missing loginData!');
        }
        const gatewayBase = getEnv('GATEWAY_BASE_URL');
        const url = new URL(`${gatewayBase}/${API_M3_PATH}/salesmen/${loginData.jwt.erpId}/basic-products-list`);
        return callApi({
            url: url.toString(),
            method: 'GET'
        })
    }

    const getCustomersOrdersSummary = ({ limit, withDetail }: { limit: number, withDetail: boolean }) => {
        if (!loginData) {
            throw new Error('Missing loginData!');
        }
        const gatewayBase = getEnv('GATEWAY_BASE_URL');
        const url = new URL(`${gatewayBase}/${API_M3_PATH}/salesmen/${loginData.jwt.erpId}/customers-orders-summary`);
        url.searchParams.append('managedSalesmen', loginData.jwt.managedSalesmen.join(','));
        if (limit) {
            url.searchParams.append("limit", limit.toString());
        }
        if (withDetail) {
            url.searchParams.append("withDetail", withDetail.toString());
        }
        return callApi({
            url: url.toString(),
            method: 'GET'
        })
    }

    return { getAllInfo, getFavorites, saveFavorites, getBasicProductList, getCustomersOrdersSummary }
}

