import { IonContent, IonGrid, IonFooter, IonRow, IonCol, IonButton, IonText, IonToolbar, IonButtons } from '@ionic/react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router';

import NuovoOrdineTabs from "./NuovoOrdineTabs";
import { useDispatch, useSelector } from 'react-redux';
import { Address, useNewOrder } from '../../../hooks/useNewOrder';
import { OrderState, addDeliveryAddress, removeDeliveryAddress, removeProducts } from '../../../redux/order/order';
import { ColumnDef, getCoreRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import CustomTable from '../../../components/Table/CustomTable';
import { getAddressRowSelection } from '../../../utils/newOrderHelpers';
import NavigationFooter from '../../../components/Navigation-Footer/NavigationFooter';
import SaveDraft from './SaveDraft';
import { useMobileMediaQuery } from '../../../hooks/useAppMediaQuery';
import { DraftOrderState } from '../../../redux/draftOrder/draftOrder';
import { ReduxState } from '../../../redux/store';
import { useCheckStepOrRedirect } from '.';

const IndirizzoConsegna: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const initData = useRef(false);
  const { getCustomerShippingAddresses } = useNewOrder();
  const [addresses, setAddresses] = useState<Address[]>([]);
  const orderState: OrderState = useSelector((state: ReduxState) => state.order);
  const { draftOrder }: DraftOrderState = useSelector((state: ReduxState) => state.draftOrder);

  const { customer, deliveryAddress } = orderState;
  const [rowSelection, setRowSelection] = React.useState({});
  const isMobile = useMobileMediaQuery();

  useCheckStepOrRedirect()({ orderState });

  // Define columns for the table
  const columns = useMemo<ColumnDef<Address, any>[]>(
    () => [
      {
        header: "Ragione sociale",
        accessorKey: 'description',
        meta: {
          mobileHeader: true
        }
      },
      {
        accessorKey: 'address',
        header: 'Indirizzo',
        enableSorting: false,
      },
      {
        accessorKey: 'cap',
        header: 'Cap',
      },
      {
        accessorKey: 'countryNewId',
        header: 'Stato',
      },
      {
        accessorKey: 'city',
        header: 'Città',
      },
      {
        id: 'selectionId',
        cell: (prop) => {
          const { row } = prop;
          const selected = row.getIsSelected();

          const _onclick = () => {
            // select the current row
            row.toggleSelected();
          }
          return (
            <IonButton shape="round" onClick={_onclick} color={selected ? 'primary' : 'medium'}>Seleziona</IonButton>
          )
        }
      }
    ],
    []
  );

  const table = useReactTable({
    data: addresses, // Define data source
    columns, // Define all column props
    state: {
      rowSelection
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(), // Used to enable pagination
    getSortedRowModel: getSortedRowModel(), // Enable sorting
    onRowSelectionChange: setRowSelection,
    enableMultiRowSelection: false,
    //  debugTable: true, // TODO: remove in production
  })



  useEffect(() => {
    if (initData.current) {
      return;
    }
    initData.current = true;
    (async () => {
      if (!customer) {
        return;
      }
      const allAddresses = await getCustomerShippingAddresses(customer.customerId);
      setAddresses(allAddresses);

      let selectedShiptoId: number | null = null;
      if (deliveryAddress) {
        selectedShiptoId = deliveryAddress.shiptoId;
      }
      if (draftOrder?.shipToId) {
        selectedShiptoId = draftOrder?.shipToId ?? null;
      }
      if (selectedShiptoId !== null) {
        setRowSelection(
          getAddressRowSelection({
            allAddresses,
            shiptoId: selectedShiptoId
          }));
      }
    })()
  }, []);

  useEffect(() => {
    const { rows } = table.getSelectedRowModel();
    dispatch(addDeliveryAddress(rows?.[0]?.original ?? null))
  }, [rowSelection])


  const onClickIndietro = () => {
    // dispatch(removeDeliveryAddress());
    history.replace("seleziona-prodotti");
  };

  const onClickContinua = () => {
    history.replace("data-consegna");
  };


  return (
    <>
      <IonContent>
        <IonGrid class='full-flex-column'>
          {isMobile && (
            <IonRow class='ion-justify-content-end'>
              <SaveDraft />
            </IonRow>
          )}
          <IonRow className="ion-align-items-center">
            <IonCol>
              <h4><strong>NUOVO ORDINE</strong></h4>
              <IonText color="primary">
                <h1>SELEZIONA INDIRIZZO DI CONSEGNA</h1>
              </IonText>
            </IonCol>
            {!isMobile && (<SaveDraft />)}
          </IonRow>

          <div>
            <NuovoOrdineTabs />
          </div>

          <IonRow class='flex-fill'>
            <CustomTable table={table} showTotalLabel={false}></CustomTable>
          </IonRow>

        </IonGrid>
      </IonContent>

      <NavigationFooter
        onBack={onClickIndietro}
        onContinue={onClickContinua}
        disableContinue={Object.keys(rowSelection).length === 0}
      />
    </>
  );
};

export default IndirizzoConsegna;