import { IonText, IonIcon, IonGrid, IonRow, IonCol, IonInput, IonItem, IonLabel, IonContent } from "@ionic/react";
import * as IonIcons from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";

import { useDispatch, useSelector } from "react-redux";

import NuovoOrdineTabs from "./NuovoOrdineTabs";
import { Customer, useNewOrder } from "../../../hooks/useNewOrder";
import { OrderState, addCustomer, removeCustomer } from "../../../redux/order/order";
import NavigationFooter from "../../../components/Navigation-Footer/NavigationFooter";
import CustomersTable from "../../../components/Table/CustomersTable";
import { DraftOrderState } from "../../../redux/draftOrder/draftOrder";
import { ReduxState } from "../../../redux/store";

const SelezionaCliente: React.FC = () => {
    const init = useRef(false);
    const history = useHistory();
    const { convertDraftCustomer } = useNewOrder();
    const [globalFilter, setGlobalFilter] = useState("");
    const { customer }: OrderState = useSelector((state: ReduxState) => state.order);
    const { draftOrder }: DraftOrderState = useSelector((state: ReduxState) => state.draftOrder);
    const dispatch = useDispatch();

    useEffect(() => {
        if (init.current) {
            return;
        }
        init.current = true;
        // if customerId coverto to customer
        if (draftOrder?.customerId) {
            (async () => {
                dispatch(addCustomer(await convertDraftCustomer(draftOrder?.customerId)));
            })()
        }
    }, [])


    /**
     * Function
     */
    const _tableRowClicked = (customer: Customer) => {
        // Set the state
        dispatch(addCustomer(customer));
    }

    const _onClickIndietro = () => {
        dispatch(removeCustomer());
    }

    const _onClickContinua = () => {
        history.replace('seleziona-prodotti')
    };

    return (
        <>
            <IonContent>
                <IonGrid class='full-flex-column'>
                    {!customer && (
                        <>
                            <IonRow className="ion-align-items-center">
                                <IonCol>
                                    <h4><strong>NUOVO ORDINE</strong></h4>
                                    <IonText color="primary">
                                        <h1>SELEZIONA CLIENTE</h1>
                                    </IonText>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <NuovoOrdineTabs />
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <IonText>
                                        <h3>RICERCA PER DESTINAZIONE MERCE</h3>
                                    </IonText>
                                </IonCol>
                            </IonRow>

                            <IonRow className="ion-margin-bottom">
                                <IonCol sizeMd="8" sizeXs="12">
                                    <IonItem>
                                        <IonInput
                                            value={globalFilter ?? ''}
                                            onIonInput={({ detail: { value } }) => setGlobalFilter(value as string)}
                                            placeholder="CERCA PER P.IVA, DENOMINAZIONE, INDIRIZZO, TELEFONO, CODICE CLIENTE"
                                        />
                                        <IonIcon icon={IonIcons.searchOutline} slot="end"></IonIcon>
                                    </IonItem>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <IonText >
                                        <h3 className="ion-margin-top">TUTTI I CLIENTI</h3>
                                    </IonText>
                                </IonCol>
                            </IonRow>

                            <IonRow class="flex-fill">
                                <CustomersTable
                                    globalFilter={globalFilter}
                                    setGlobalFilter={setGlobalFilter}
                                    onRowClicked={_tableRowClicked}
                                    onlyEnableToOrder={true}
                                ></CustomersTable>
                            </IonRow>
                        </>
                    )}

                    {/* View for selected client */}
                    {customer && (
                        <>
                            <IonRow>
                                <IonCol>
                                    <h4><strong>NUOVO ORDINE</strong></h4>
                                    <IonText color="primary">
                                        <h1>{customer.description}</h1>
                                    </IonText>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <NuovoOrdineTabs />
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <IonText>
                                        <h3>
                                            Destinazione Ordine
                                        </h3>
                                    </IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol sizeMd="4" sizeSm="6" sizeXs="12">
                                    <IonItem>
                                        <IonLabel slot="start">P.IVA</IonLabel>
                                        <IonInput readonly={true} value={customer.vatNumber}></IonInput>
                                    </IonItem>
                                </IonCol>
                                <IonCol sizeMd="4" sizeSm="6" sizeXs="12">
                                    <IonItem>
                                        <IonLabel slot="start">COD.FISCALE</IonLabel>
                                        <IonInput readonly={true}>{customer.taxIdCode}</IonInput>
                                        <IonIcon slot="end" icon={IonIcons.copyOutline}></IonIcon>
                                    </IonItem>
                                </IonCol>

                                <IonCol sizeMd="4" sizeSm="auto" sizeXs="12" className="ion-no-padding"></IonCol>

                                <IonCol sizeMd="4" sizeSm="6" sizeXs="12">
                                    <IonItem>
                                        <IonLabel slot="start">DEPOSITO</IonLabel>
                                        <IonInput readonly={true}></IonInput>
                                    </IonItem>
                                </IonCol>
                                <IonCol sizeMd="4" sizeSm="6" sizeXs="12">
                                    <IonItem>
                                        <IonLabel slot="start">INDIRIZZO</IonLabel>
                                        <IonInput readonly={true} value={customer.address}></IonInput>
                                    </IonItem>
                                </IonCol>

                                <IonCol sizeMd="4" sizeSm="auto" sizeXs="12" className="ion-no-padding"></IonCol>

                                <IonCol sizeMd="4" sizeSm="6" sizeXs="12">
                                    <IonItem>
                                        <IonLabel slot="start">LOCALITA</IonLabel>
                                        <IonInput readonly={true} value={customer.city}></IonInput>
                                    </IonItem>
                                </IonCol>
                                <IonCol sizeMd="4" sizeSm="6" sizeXs="12">
                                    <IonItem>
                                        <IonLabel slot="start">CAP</IonLabel>
                                        <IonInput readonly={true} value={customer.zipCode}></IonInput>
                                    </IonItem>
                                </IonCol>
                                <IonCol sizeMd="4" sizeSm="6" sizeXs="12">
                                    <IonItem>
                                        <IonLabel slot="start">PROVINCIA</IonLabel>
                                        <IonInput readonly={true} value={customer.provincia}></IonInput>
                                    </IonItem>
                                </IonCol>
                                <IonCol sizeMd="4" sizeSm="6" sizeXs="12">
                                    <IonItem>
                                        <IonLabel slot="start">TELEFONO</IonLabel>
                                        <IonInput readonly={true} value={customer.phone1}></IonInput>
                                    </IonItem>
                                </IonCol>
                                <IonCol sizeMd="4" sizeSm="6" sizeXs="12">
                                    <IonItem>
                                        <IonLabel slot="start">FAX</IonLabel>
                                        <IonInput readonly={true} value={customer.phone2}></IonInput>
                                    </IonItem>
                                </IonCol>
                                <IonCol sizeMd="4" sizeSm="6" sizeXs="12">
                                    <IonItem>
                                        <IonLabel slot="start">EMAIL</IonLabel>
                                        <IonInput readonly={true} value={customer.email}></IonInput>
                                    </IonItem>
                                </IonCol>

                                <IonCol sizeMd="4" sizeSm="6" sizeXs="12">
                                    <IonItem>
                                        <IonLabel slot="start">ABI</IonLabel>
                                        <IonInput readonly={true} value={customer.abi}></IonInput>
                                    </IonItem>
                                </IonCol>
                                <IonCol sizeMd="4" sizeSm="6" sizeXs="12">
                                    <IonItem>
                                        <IonLabel slot="start">CAB</IonLabel>
                                        <IonInput readonly={true} value={customer.cab}></IonInput>
                                    </IonItem>
                                </IonCol>
                                <IonCol sizeMd="4" sizeSm="6" sizeXs="12">
                                    <IonItem>
                                        <IonLabel slot="start">MOD PAG.</IonLabel>
                                        <IonInput readonly={true} value={customer.desPayment}></IonInput>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                        </>
                    )}
                </IonGrid>
            </IonContent>

            {customer && (
                <NavigationFooter
                    onBack={_onClickIndietro}
                    onContinue={_onClickContinua}
                />
            )}
        </>
    );
};

export default SelezionaCliente;